// Polyfills for IE support
import 'promise-polyfill/src/polyfill';
import 'url-search-params-polyfill';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { store } from './helpers';

import Routes from './routes';

const rootElement = document.getElementById('root');

function renderApp() {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    ReactDOM.render(
        <Provider store={store}>
            <AppContainer>
                <>
                    <BrowserRouter basename={baseUrl}>
                        <Routes />
                    </BrowserRouter>
                </>
            </AppContainer>
        </Provider>,
        rootElement
    );
}

renderApp();

// Allow Hot Module Replacement
/* eslint-disable no-undef */
if (module.hot) {
    module.hot.accept('./routes', () => {
        routes = require('./routes').routes;
        renderApp();
    });
}
/* eslint-enable no-undef */

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();