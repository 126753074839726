export const navConstants = {
    GET_MENU: 'NAV_GET_MENU',
    INCREMENT_SPINNER: 'NAV_INCREMENT_SPINNER',
    DECREMENT_SPINNER: 'NAV_DECREMENT_SPINNER',
    SHOW_ERROR: 'NAV_SHOW_ERROR',
    DISMISS_ERROR: 'NAV_DISMISS_ERROR',
    SHOW_PROMPT: 'NAV_SHOW_PROMPT',
    DISMISS_PROMPT: 'NAV_DISMISS_PROMPT',
    SHOW_MESSAGE: 'NAV_SHOW_MESSAGE',
    DISMISS_MESSAGE: 'NAV_DISMISS_MESSAGE'
};