import React, { Component } from 'react';
import { Row, Col, Button, Alert, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import { FormTextInput, FormButtonOk, FormButtonCancel, InfrastructureWrapper, FormSelect, FormCopyToClipboard, FormTextArea, FormSearchInput, FormSwitchCheckbox, FormDateInput, FormField } from '../../components';
import { userService, jobCodeService, domainService, sapUserRoleService, organizationService, countryService, employeeTypeService, shirtSizeService } from '../../services';
import { toSelectOptions, toSelectOptionsStringArray } from '../../helpers';
import ResetEmailModal from './ResetEmailModal';
import UserAddEditTabContainer from './UserAddEditTabContainer';
import UserRoleListComponent from './UserRoleListComponent';
import UserCertComponent from './UserCertComponent';
import UserCertProgress from './UserCertProgress';



import { PagedList, FilterSelect } from '../../components/list';

class UserAddEditPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {
                active: true
            },
            techIdExists: false,
            emailExists: false,
            sapRoles: [],
            tamZones: [],
            countries: [],
            shirtSizes: [],
            employeeTypes: [],
            b2cMatch: null,
            showResetEmail: false,
            isEligible: false,
            permissionsBBG: []
        };

        this.debouncedDoesTechIdExist = debounce(this.doesTechIdExist, 500);
        this.debouncedDoesEmailExist = debounce(this.doesEmailExist, 500);
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (match.params.id && match.params.id !== prevProps.match.params.id) {
            this.loadModel();
        }
    }

    componentDidMount() {
        const { incrementSpinner, decrementSpinner } = this.props;

        this.loadModel();
        this.loadModel2();
        this.loadModel3();
        incrementSpinner();
        const promises = [
            this.getResource(sapUserRoleService.getAll, 'sapRoles', 'Error loading user roles'),
            this.getResource(organizationService.getTAMZones, 'tamZones', 'Error loading TAM Zones'),
            this.getResource(countryService.getAll, 'countries', 'Error loading countries'),
            this.getResource(employeeTypeService.getAll, 'employeeTypes', 'Error loading employee types'),
            this.getResource(shirtSizeService.getAll, 'shirtSizes', 'Error loading shirt sizes')
        ];

        Promise.all(promises).finally(() => {
            decrementSpinner();
        });
    }

    loadModel = () => {
        const { match, incrementSpinner, decrementSpinner, showError } = this.props;
        const { params } = match;

        if (params.id) {
            incrementSpinner();
            userService.getById(params.id)
                .then(
                    response => {
                        const model = response.data;
                        const { supervisorId } = model;
                        if (!supervisorId) {
                            model.hasNoSupervisor = true;
                        }
                        this.setState({ model, emailExists: false });
                    },
                    () => {
                        showError('Error loading model');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }

        if (this.isAddMode()) {
            userService.getNew()
                .then(
                    response => {
                        const model = response.data;
                        this.setState({ model });
                    },
                    () => {
                        showError('Error loading model');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }


    }

    loadModel2 = () => {
        const { match, incrementSpinner, decrementSpinner, showError } = this.props;
        const { params } = match;
        if (!this.isAddMode()) {
            incrementSpinner();
            userService.getBBGPermissions(params.id)
                .then(
                    response => {
                        const permissionsBBG = response.data;
                        this.setState({ permissionsBBG });
                    },
                    error => {
                        console.error("Error loading model:", error);
                        showError('Error loading model');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }
    }



    loadModel3 = () => {
        const { match, incrementSpinner, decrementSpinner, showError } = this.props;
        const { params } = match;

        if (!this.isAddMode()) {
            incrementSpinner();

            userService.getWACEligibleBBG(params.id)
                .then(
                    isEligible => {
                        
                        this.setState({ isEligible });
                    },
                    error => {
                        console.error("Error loading model:", error);
                        showError('Error loading model');
                    }
                )
                .finally(() => {
                    decrementSpinner();
                });
        }
    };


    getResource(serviceFunction, stateName, errorMessage) {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        serviceFunction()
            .then(
                response => {
                    const { data } = response;
                    const newState = {};
                    newState[stateName] = data;
                    this.setState(newState);
                },
                () => {
                    showError(errorMessage);
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    generateSelectOptions = () => {
        const { sapRoles, countries, tamZones, employeeTypes, shirtSizes } = this.state;

        const result = {};

        result.sapRoleOptions = toSelectOptions(sapRoles, {
            showDefault: true,
            defaultLabel: '-- Select Role --',
            valueFieldName: 'id',
            labelFieldName: 'name'
        });

        result.countryOptions = toSelectOptions(countries, {
            showDefault: true,
            defaultLabel: '-- Select Country --',
            valueFieldName: 'id',
            labelFieldName: 'description'
        });

        result.tamZoneOptions = toSelectOptionsStringArray(tamZones, {
            showDefault: true,
            defaultLabel: '-- Select TAM Zone --',
            valueFieldName: 'id',
            labelFieldName: 'name'
        });

        result.employeeTypeOptions = toSelectOptions(employeeTypes, {
            showDefault: true,
            defaultLabel: '-- Select Employee Type --',
            valueFieldName: 'id',
            labelFieldName: 'description'
        });
        result.shirtOptions = toSelectOptions(shirtSizes, {
            showDefault: true,
            defaultLabel: '-- Select Shirt Size --',
            valueFieldName: 'id',
            labelFieldName: 'name'
        });

        return result;
    }

    render() {
        const isAddMode = this.isAddMode();
        const { match, hasInternalAdminAccess, hasRegionalAdminAccess, hasResetEmail } = this.props;
        const { model, techIdExists, emailExists, b2cMatch, showResetEmail, permissionsBBG, isEligible } = this.state;
        const { techId, accountNumber, firstName, lastName, accountCode, hasAccessToOrgFinancialAccount,
            active, emailAddress, streetAddress, city, state,
            postalCode, country, supervisorId, isInB2C, isDuplicateEmail,
            isInviteValid, inviteLink, techIdRegion, locked, hireDate, employeeTypeId,
            sapRoleId, sapDomainId, sapJobPosId, comments, isInAD, adminTAMZone, hasNoSupervisor,
            phoneNumber, shirtSize } = model;
        const emailValid = this.isEmailValid(emailAddress);

        const { sapRoleOptions, tamZoneOptions, countryOptions, employeeTypeOptions, shirtOptions } = this.generateSelectOptions();

        let techIdExistsMessage = null;
        if (techIdExists) {
            techIdExistsMessage = 'Tech ID already exists.';
        }

        let emailValidationMessage = null;
        if (!emailValid && active) {
            emailValidationMessage = 'Email address is invalid';
        }
        if (emailExists && !!emailAddress) {
            emailValidationMessage = 'Email address already in use.';
        }
       
        const isReadonly = isAddMode && (!!techIdExists || !techId);

        const saveButtonEnabled = this.isSaveButtonEnabled();
        
        







        return (
            <UserAddEditTabContainer
                hasInternalAdminAccess={hasInternalAdminAccess}
                hasRegionalAdminAccess={hasRegionalAdminAccess}
                isAdd={isAddMode}
                editUi={(
                    // can you use comments to seperate what will display if match.params.id is Add or Edit?

                    <Row>
                        <Col md={12}>
                            <ResetEmailModal
                                show={showResetEmail}
                                active={active}
                                emailAddress={emailAddress}
                                emailValidationMessage={emailValidationMessage}
                                handleValueChanged={this.handleValueChanged}
                                handleSaveClick={this.handleResetEmailSaveClick}
                                handleCancelClick={() => {
                                    this.loadModel();
                                    this.setState({ showResetEmail: false });
                                }}
                            />
                            <fieldset>
                                <legend>{`${!match.params.id ? 'Add' : 'Edit'} User`}</legend>
                                <Row>
                                    <Col md={6}>
                                        
                                        {!techId ? (
                                            <Row>
                                                <Col sm={8}>
                                                    <FormSearchInput
                                                        fieldName='sapDomainId'
                                                        fieldValue={sapDomainId}
                                                        label='Domain'
                                                        itemListFunc={this.getDomainList}
                                                        valueLookupFunc={domainService.getById}
                                                        valueLookupIdName='id'
                                                        valueLookupDisplayName='name'
                                                        columns={[
                                                            { label: 'Id', fieldName: 'id' },
                                                            { label: 'Name', fieldName: 'name' },
                                                            {
                                                                label: 'Tech ID Prefix',
                                                                formatFunc: (item) => {
                                                                    const { techIdPrefix } = item;
                                                                    return !!techIdPrefix ? techIdPrefix : 'No Prefix Set';
                                                                }
                                                            },
                                                            { label: 'Email', fieldName: 'emailAddress' }
                                                        ]}
                                                        onSelectItem={(item) => {
                                                            const model = { ...this.state.model };
                                                            model.sapDomainId = item.id;
                                                            model.techIdRegion = item.id;

                                                            this.setState({ model });
                                                        }}
                                                        isItemSelectableFunc={(item) => {
                                                            const { techIdPrefix } = item;
                                                            return !!techIdPrefix;
                                                        }} />
                                                </Col>
                                                <Col sm={4}>
                                                    {!techId ? (
                                                        <Button style={{ marginTop: '1.7em' }} onClick={this.handleGenerateTechId} disabled={!techIdRegion}>Generate Tech ID</Button>
                                                    ) : null}

                                                </Col>
                                            </Row>
                                        ) : null}
                                        {(hasInternalAdminAccess || hasRegionalAdminAccess) && window.b2cManualInviteEnabled && !!emailAddress && isDuplicateEmail ? (
                                            <Alert variant='danger'>
                                                There is more than one user with this email address, they cannot be invited to Brunswick Partners B2C until a unique email is used
                                                <br /><br />
                                                <Alert.Link href={`/users?query=${encodeURIComponent(emailAddress)}&activeStatus=both`}>
                                                    View Details
                                                </Alert.Link>
                                            </Alert>
                                        ) : null}
                                        {(hasInternalAdminAccess || hasRegionalAdminAccess) && window.b2cManualInviteEnabled && !isInB2C && emailAddress && !isInviteValid && !isAddMode && !isDuplicateEmail ? (
                                            <Button onClick={this.handleInviteB2C}>Invite to B2C</Button>
                                        ) : null}
                                        {(hasInternalAdminAccess || hasRegionalAdminAccess) && isInviteValid ? (
                                            <>
                                                <Alert variant='info'>
                                                    User has been invited to Brunswick Partners B2C but has not yet signed up. If they are unable to use the link
                                                    provided you can click the Copy Users Link button to copy the invite link to the clipboard and send it to them
                                                    through other means
                                                </Alert>
                                                <div>
                                                    <FormCopyToClipboard
                                                        fieldName='inviteLink'
                                                        fieldValue={inviteLink}
                                                        label='Copy Users Link'
                                                    />
                                                </div>
                                            </>
                                        ) : null}
                                        {techId ? (
                                            <FormTextInput
                                                fieldName='techId'
                                                fieldValue={techId}
                                                label='Tech ID'
                                                validationMessage={techIdExistsMessage}
                                                onChange={this.handleValueChanged}
                                                required
                                                readonly />
                                        ) : null}
                                        <FormTextInput
                                            fieldName='emailAddress'
                                            fieldValue={emailAddress}
                                            label='Email'
                                            onChange={this.handleValueChanged}
                                            readonly={isInAD || isInB2C || isReadonly}
                                            validationMessage={emailValidationMessage}
                                            required={active} />
                                        {isInAD ? (
                                            <Alert variant='secondary'>
                                                Cannot change email address for Active Directory user
                                            </Alert>
                                        ) : null}
                                        {!isInAD && isInB2C && !hasResetEmail ? (
                                            <Alert variant='secondary'>
                                                Cannot change the email address for a user in Brunswick Partners B2C as this is their username
                                            </Alert>
                                        ) : null}
                                        {!isInAD && isInB2C && hasResetEmail ? (
                                            <Row>
                                                <Col md={10}>
                                                    <Alert variant='secondary'>
                                                        This user is associated with a user in Brunswick Partners B2C, reseting this users email will disassociate them from that B2C user
                                                    </Alert>
                                                </Col>
                                                <Col md={2}>
                                                    <Button onClick={this.showResetEmail}>Reset</Button>
                                                </Col>
                                            </Row>
                                        ) : null}
                                        {!!b2cMatch ? (
                                            <div>
                                                <Alert variant='info'>
                                                    This email address already exists in the Brunswick Partners B2C system, if this is not the correct user you will need to use a different email address
                                                </Alert>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {b2cMatch.firstName}
                                                            </td>
                                                            <td>
                                                                {b2cMatch.lastName}
                                                            </td>
                                                            <td>
                                                                <Button onClick={this.handlePopulateFromB2C}>
                                                                    Populate Fields
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        ) : null}
                                        <FormTextInput
                                            fieldName='firstName'
                                            fieldValue={firstName}
                                            label='First Name'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            required />
                                        <FormTextInput
                                            fieldName='lastName'
                                            fieldValue={lastName}
                                            label='Last Name'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            required />
                                        <FormTextInput
                                            fieldName='streetAddress'
                                            fieldValue={streetAddress}
                                            label='Street Address'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />
                                        <FormTextInput
                                            fieldName='city'
                                            fieldValue={city}
                                            label='City'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />
                                        <FormTextInput
                                            fieldName='state'
                                            fieldValue={state}
                                            label='State'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />
                                        <FormTextInput
                                            fieldName='postalCode'
                                            fieldValue={postalCode}
                                            label='Postal Code'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />
                                        <FormSelect
                                            fieldName='country'
                                            fieldValue={country}
                                            label='Country'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            options={countryOptions} />
                                        <FormTextInput
                                            fieldName='phoneNumber'
                                            fieldValue={phoneNumber}
                                            label='Phone Number'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />
                                        <FormDateInput
                                            fieldName='hireDate'
                                            fieldValue={hireDate}
                                            label='Hire Date'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />
                                        <FormSelect
                                            fieldName='shirtSize'
                                            fieldValue={shirtSize}
                                            label='Shirt Size'
                                            subLabel='(North American sizes)'
                                            onChange={this.handleValueChanged}
                                            options={shirtOptions}
                                            readonly={isReadonly} />


                                        <FormTextInput
                                            fieldName='wacEligible'
                                            label='WAC Eligibility'
                                            fieldValue={isEligible.data}
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                        />

                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={6}>
                                                <FormSwitchCheckbox
                                                    fieldName='active'
                                                    fieldValue={active}
                                                    label='Active'
                                                    onChange={this.handleValueChanged}
                                                    readonly={isReadonly}
                                                    isBool />
                                            </Col>
                                            <Col md={6}>
                                                <FormSwitchCheckbox
                                                    fieldName='locked'
                                                    fieldValue={locked}
                                                    label='Locked'
                                                    onChange={this.handleValueChanged}
                                                    readonly={isReadonly}
                                                    isBool />
                                            </Col>
                                        </Row>
                                        <FormSelect
                                            fieldName='employeeTypeId'
                                            fieldValue={employeeTypeId}
                                            label='Employee Type'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            options={employeeTypeOptions} />
                                        <FormSelect
                                            fieldName='sapRoleId'
                                            fieldValue={sapRoleId}
                                            label='Role'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            options={sapRoleOptions} />
                                        {techId ? (<FormSearchInput
                                            fieldName='sapDomainId'
                                            fieldValue={sapDomainId}
                                            label='Domain'
                                            itemListFunc={this.getDomainList}
                                            valueLookupFunc={domainService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='name'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />) : null}
                                        <FormSearchInput
                                            fieldName='sapJobPosId'
                                            fieldValue={sapJobPosId}
                                            label='Job Code'
                                            itemListFunc={jobCodeService.getList}
                                            valueLookupFunc={jobCodeService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='description'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly} />
                                        <FormSearchInput
                                            fieldName='accountNumber'
                                            fieldValue={accountNumber}
                                            label='Organization'
                                            itemListFunc={organizationService.getList}
                                            valueLookupFunc={organizationService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='name'
                                            onChange={this.handleValueChanged}
                                            required
                                            readonly={isReadonly} />
                                        <FormSearchInput
                                            fieldName='accountCode'
                                            fieldValue={accountCode}
                                            label='Account Code'
                                            itemListFunc={organizationService.getList}
                                            valueLookupFunc={organizationService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='name'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            required={this.isAccountCodeRequired()}
                                            showClear={!!accountCode} />
                                        <FormSwitchCheckbox
                                            fieldName='hasAccessToOrgFinancialAccount'
                                            fieldValue={hasAccessToOrgFinancialAccount}
                                            label='User can use Org Accounts'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            isBool />
                                        {!isAddMode ? (
                                            <FormField
                                                fieldName='set as primary manager'
                                                label={`This button adds this user as Primary Manager to all users within this organization ${accountNumber}. It does not make them an approver for ILT courses.  The Approval Role must be added through Learning Administration. `}
                                            >
                                                <br />
                                                <Button
                                                    className='form-button'
                                                    variant='primary'
                                                    title='Set User as Manager '
                                                    onClick={this.handleSetUserAsSupervisorClick}
                                                >
                                                    Set manager of {accountNumber}
                                                </Button>
                                            </FormField >
                                        ) : null}
                                        <FormSearchInput
                                            fieldName='supervisorId'
                                            fieldValue={supervisorId}
                                            label='Manager'
                                            itemListFunc={this.getPossibleSupervisors}
                                            valueLookupFunc={userService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='techId'
                                            valueLookupDisplayFunc={(item) => {
                                                const { firstName, lastName, techId } = item;
                                                return !!techId ? `${firstName} ${lastName} (${techId})` : `${firstName} ${lastName}`;
                                            }}

                                            columns={[
                                                {
                                                    label: 'Name',
                                                    formatFunc: (item) => {
                                                        const { firstName, lastName, techId } = item;
                                                        return !!techId ? `${firstName} ${lastName} (${techId})` : `${firstName} ${lastName}`;
                                                    }
                                                },
                                                { label: 'Email', fieldName: 'emailAddress' }
                                            ]}
                                            onChange={this.handleValueChanged}
                                            readonly={(!hasInternalAdminAccess && !hasRegionalAdminAccess) || isReadonly || hasNoSupervisor}
                                            required={!hasNoSupervisor} />
                                        <FormSwitchCheckbox
                                            fieldName='hasNoSupervisor'
                                            fieldValue={hasNoSupervisor}
                                            label='User has no Manager'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            isBool />
                                        <FormTextArea
                                            fieldName='comments'
                                            fieldValue={comments}
                                            label='Comments'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                        />
                                        {isInAD ? (<FormSelect
                                            fieldName='adminTAMZone'
                                            fieldValue={adminTAMZone}
                                            label='Admin TAM Zone'
                                            onChange={this.handleValueChanged}
                                            readonly={isReadonly}
                                            options={tamZoneOptions} />) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormButtonOk
                                            title="Save"
                                            onClick={this.handleSaveClick}
                                            disabled={!saveButtonEnabled}>
                                            <i className="fas fa-check"></i> Save
                                        </FormButtonOk>
                                        <FormButtonCancel title="Cancel" onClick={this.handleCancelClick}>
                                            <i className="fas fa-times"></i> Cancel
                                        </FormButtonCancel>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                )}
                roleUi={(
                    <UserRoleListComponent isInB2C={isInB2C} isInAD={isInAD} />
                )}
                BBGUI={(
                    <div>
                        {permissionsBBG !== null ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>BBG ID</th>
                                        <th>Permission Domain</th>
                                        <th>Action</th>
                                        <th>Brand</th>
                                        <th>Dealer</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {permissionsBBG.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.bbG_ID}</td>
                                            <td>{item.permissioN_DOMAIN}</td>
                                            <td>{item.action}</td>
                                            <td>{item.brand}</td>
                                            <td>{item.dealer}</td>
                                            <td>{item.location}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}
                    </div>
                   
                )}
                certUi={(
                    <UserCertComponent isInB2C={isInB2C} isInAD={isInAD} />
                )}
                userCertProgress={(
                    <UserCertProgress isInB2C={isInB2C} isInAD={isInAD} userID={match.params.id} />
                )}
            />
        );
    }

    showResetEmail = () => {
        this.setState({ showResetEmail: true });
    }

    isAccountCodeRequired = () => {
        // const { model } = this.state;
        // const { accountNumber } = model;

        // if (!!accountNumber && accountNumber !== 'PP000001') {
        //     return true;
        // }
        // Per Kolton this should no longer be required
        return false;
    }

    isEmailValid = (email) => {
        if (!email) {
            return false;
        }

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(String(email).toLowerCase());
    }

    getDomainList = (pageIndex, pageSize, query) => {
        return domainService.getList(pageIndex, pageSize, query, true);
    }

    getPossibleSupervisors = (pageIndex, pageSize, query) => {
        const { model } = this.state;
        const { accountNumber, id } = model;

        return userService.getPossibleSupervisors(pageIndex, pageSize, query, id, accountNumber);
    }

    isSaveButtonEnabled = () => {
        const { model, techIdExists, emailExists } = this.state;
        const { techId, firstName, lastName, isInAD, accountNumber, accountCode, emailAddress, hasNoSupervisor, supervisorId, active } = model;
        const emailValid = this.isEmailValid(emailAddress);

        if (!hasNoSupervisor && !supervisorId) {
            return false;
        }

        if (this.isAccountCodeRequired() && !accountCode) {
            return false;
        }

        if (techIdExists || (!techId && !isInAD) || emailExists || !firstName || !lastName || !accountNumber || (!emailAddress && active) || (!emailValid && active)) {
            return false;
        }

        return true;
    }

    handlePopulateFromB2C = () => {
        const { b2cMatch } = this.state;
        const model = { ...this.state.model };

        model.firstName = b2cMatch.firstName;
        model.lastName = b2cMatch.lastName;
        model.b2CId = b2cMatch.b2CId;
        model.isInB2C = true;
        this.setState({ model, b2cMatch: null });
    }

    handleValueChanged = (fieldName, value) => {
        const { match } = this.props;
        const model = { ...this.state.model };

        const { isEligible } = this.state;

        if (fieldName == 'wacEligible') {
            value = isEligible.data;
        }

        if (fieldName === 'emailAddress' && !!value) {
            value = value.trim();
        }

        if (fieldName === 'hasNoSupervisor' && !!value) {
            model.supervisorId = '';
        }

        model[fieldName] = value;

        if (fieldName === 'accountNumber') {
            model.supervisorId = '';
        }

        if (fieldName === 'locked' && !!value) {
            model.active = false;
        }

        if (fieldName === 'active' && !!value) {
            model.locked = false;
        }

        // We should default the account code with the same value as account number
        if (fieldName === 'accountNumber' && value !== 'PP000001') {
            model.accountCode = value;
        }

        this.setState({ model });

        if (fieldName === 'emailAddress') {
            const { emailAddress } = model;
            if (!this.isEmailValid(emailAddress)) {
                return;
            }

            this.debouncedDoesEmailExist(emailAddress);
        }

        if (fieldName === 'techId') {
            const { id, techId } = model;
            if (!techId) {
                return;
            }

            this.debouncedDoesTechIdExist(techId, id);
        }
    }

    handleGenerateTechId = () => {
        const { incrementSpinner, decrementSpinner } = this.props;
        const { model } = this.state;
        const { techIdRegion } = model;

        incrementSpinner();
        userService.getNextTechId(techIdRegion)
            .then((response) => {
                const { data } = response;
                this.handleValueChanged('techId', data);
            })
            .finally(() => {
                decrementSpinner();
            });
    }

    handleInviteB2C = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        const { model } = this.state;
        const { id } = model;

        incrementSpinner();
        userService.inviteB2C(id)
            .then(() => {
                this.loadModel();
            },
                () => {
                    showError('Error inviting user to B2C');
                })
            .finally(() => {
                decrementSpinner();
            });
    }

    doesTechIdExist = (techId, id) => {
        const { incrementSpinner, decrementSpinner } = this.props;
        incrementSpinner();
        userService.exists(techId, id)
            .then((response) => {
                const techIdExists = response.data;
                this.setState({ techIdExists: techIdExists == true });
            })
            .finally(() => {
                decrementSpinner();
            });
    }

    doesEmailExist = (email) => {
        const { incrementSpinner, decrementSpinner } = this.props;
        incrementSpinner();
        userService.emailExists(email)
            .then((response) => {
                const emailMatchResponse = response.data;
                this.setState({ emailExists: !!emailMatchResponse.id, b2cMatch: !!emailMatchResponse.b2CId && !emailMatchResponse.id ? emailMatchResponse : null });
            })
            .finally(() => {
                decrementSpinner();
            });
    }

    handleCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleSetUserAsSupervisorClick = () => {
        const { match, showPrompt } = this.props;
        const { model } = this.state;
        const { accountNumber } = model;

        showPrompt(
            'Set User As Supervisor?',
            `Are you sure you want to set this user as a supervisor for ${accountNumber}? ` +
            `This will overwrite the supervisor field on all users in the ${accountNumber} organization.`, () => {
                const { incrementSpinner, decrementSpinner, showError } = this.props;

                incrementSpinner();

                this.handleValueChanged('hasNoSupervisor', true);

                userService.setSupervisorForAccountNumberAsync(match.params.id, accountNumber)
                    .then(
                        () => {
                        },
                        () => {
                            showError('Error setting as supervisor');
                        }
                    ).finally(() => {
                        decrementSpinner();
                    });
            });
    }

    handleSaveClick = () => {
        const { model } = this.state;
        const { history, match, incrementSpinner, decrementSpinner, showError } = this.props;

        if (!this.isSaveButtonEnabled()) {
            return;
        }

        incrementSpinner();
        if (!match.params.id) {
            userService.add(model)
                .then(() => {
                    history.goBack();
                }, (error) => {
                    if (error.response.status == 400) {
                        showError('LMS Error saving user: ' + error.response.data);
                    }
                    else {
                        showError('Error saving user');
                    }
                })
                .finally(() => {
                    decrementSpinner();
                });
        } else {
            userService.update(model)
                .then(() => {
                    history.goBack();
                }, (error) => {
                    if (error.response.status == 400) {
                        showError('LMS Error saving user: ' + error.response.data);
                    }
                    else {
                        showError('Error saving user');
                    }
                }).catch(error => {console.log(error) })
                .finally(() => {
                    decrementSpinner();
                });
        }
    }

    handleResetEmailSaveClick = () => {
        const { model } = this.state;
        const { history, match, incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        userService.resetEmailAddress(model)
            .then(() => {
                this.loadModel();
            }, (error) => {
                if (error.response.status == 400) {
                    showError('LMS Error saving user: ' + error.response.data);
                }
                else {
                    showError('Error saving user');
                }
            })
            .finally(() => {
                decrementSpinner();
                this.loadModel();
                this.setState({ showResetEmail: false });
            });
    }

    isAddMode = () => {
        const { match } = this.props;
        const { url } = match;

        return url === '/users/add';
    }
}

UserAddEditPageComponent.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func,
    hasInternalAdminAccess: PropTypes.bool,
    hasResetEmail: PropTypes.bool
};

const UserAddEditPage = InfrastructureWrapper(withRouter(UserAddEditPageComponent));
export default UserAddEditPage;