import React, { Component } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormField, InfrastructureWrapper } from '../../components';

class AddedOrganizationComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayValue: ''
        }
    }

    componentDidMount() {
        this.loadSelectedDisplay();
    }

    componentDidUpdate(prevProps) {
        const { fieldValue, valueLookupFunc } = this.props;

        if (fieldValue !== prevProps.fieldValue || valueLookupFunc !== prevProps.valueLookupFunc) {
            this.loadSelectedDisplay();
        }

        if (!fieldValue && fieldValue !== prevProps.fieldValue && this.state.displayValue !== '') {
            this.setState({ displayValue: '' });
        }
    }

    loadSelectedDisplay = () => {
        const { incrementSpinner, decrementSpinner, showError, label,
            fieldValue, valueLookupFunc, valueLookupIdName, valueLookupDisplayName, valueLookupDisplayFunc } = this.props;
        if (!!fieldValue && !!valueLookupFunc) {
            incrementSpinner();
            valueLookupFunc(fieldValue)
                .then(response => {
                    const { data } = response;
                    const displayValue = !valueLookupDisplayFunc ? `${data[valueLookupDisplayName]} (${data[valueLookupIdName]})` : valueLookupDisplayFunc(data);
                    if (displayValue !== this.state.displayValue) {
                        this.setState({ displayValue });
                    }
                }, error => {
                    showError(`Error loading ${label}`)
                })
                .finally(() => {
                    decrementSpinner();
                });
        }
    }

    render() {
        const { displayValue } = this.state;
        const { fieldName, fieldValue, label, subLabel, removeClick } = this.props;

        return (
            <>
                <FormField
                    fieldName={fieldName}
                    label={label}
                    subLabel={subLabel}
                >
                    <InputGroup className="mb-2 mr-sm-2">
                        <Form.Control
                            placeholder={label}
                            as='input'
                            value={displayValue}
                            style={{ background: '#FFF' }}
                            className='input-group-append'
                            readOnly
                        />
                        <InputGroup.Append>
                            <Button
                                variant='secondary'
                                onClick={() => { removeClick(fieldValue); }}
                            >
                                <i className='fas fa-times'></i>
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </FormField >
            </>
        );
    }
}

AddedOrganizationComponent.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    validationMessage: PropTypes.string,
    autocomplete: PropTypes.string
};

const AddedOrganization = InfrastructureWrapper(AddedOrganizationComponent);
export default AddedOrganization;