import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { InfrastructureWrapper, FormSelect, FormButtonOk, FormTextInput } from '../../components';
import { userService } from '../../services';
import { toSelectOptions } from '../../helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import Table from 'react-bootstrap/Table';

class UserCertComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userCerts: [],
            certs: [],
            model: {},
            accruedHours: 0,
            annualCompletedItems: 0,
            annualRequiredItems: 0,
            hoursRequired: 0,
            triAnnualItemsAchieved: 0,
            triAnnualItemsRequired: 0,
            UserCertificationDetails: [],
            toggleCert: false,
        };
    }


    componentDidMount() {
        this.loadModel();
        this.loadModel2();
        this.loadModel3();
        this.loadModel4();
    }

    loadModel = () => {
        const { match, incrementSpinner, decrementSpinner, showError } = this.props;
        const { params } = match;

        if (params.id) {
            incrementSpinner();
            userService.getCertifications(params.id)
                .then(
                    response => {
                        const certs = response.data.userCerts;
                        this.setState({ userCerts: response.data.userCerts, certs: response.data.certs });
                    },
                    () => {
                        showError('Error loading Certifications');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }
    }
    loadModel2 = () => {
        const { incrementSpinner, decrementSpinner, showError, userID } = this.props;
        console.log(this.props)
        incrementSpinner();
        userService.getProfileCertificationDetailsByUser(userID, "Outboard_Initial_Certification")
            .then(
                response => {
                    const outboardCertDetails = response.data;
                    this.setState({ outboardCertDetails });
                },
                () => {
                    showError('Error loading certification details');
                }
            ).finally(() => {
                decrementSpinner();
            });
        userService.getProfileCertificationDetailsByUser(userID, "Mercruiser_Initial_Certificati")
            .then(
                response => {
                    const mercruiserCertDetails = response.data;
                    this.setState({ mercruiserCertDetails });
                },
                () => {
                    showError('Error loading certification details');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    loadModel3 = () => {
        const { incrementSpinner, decrementSpinner, showError, match } = this.props;

        incrementSpinner();
        userService.getProfileCertification(match.params.cID)
            .then(
                response => {
                    const model2 = response.data;
                    this.setState({ ...model2 });
                },
                () => {
                    showError('Error loading model');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    loadModel4 = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        userService.getProfile()
            .then(
                response => {
                    const model = response.data;
                    this.setState({ model });
                },
                () => {
                    showError('Error loading model');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }



    render() {
        const { accruedHours, annualCompletedItems, annualRequiredItems, hoursRequired,
            triAnnualItemsAchieved, triAnnualItemsRequired, model, outboardCertDetails, mercruiserCertDetails, toggleCert } = this.state;

        const { techId, firstName, lastName, sapDomainId } = model;
        const { match } = this.props;

        var isInitial = true;

        const check = "\u2713"; // check mark symbol

        const getCertDetails = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    if (!isInitial) {
                        return outboardCertDetails.hourItemsAvail.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td>{item.hours}</td>
                                    <td>{item.type}</td>
                                </tr>)
                        });
                    }
                    else {
                        return outboardCertDetails.staticItemReq.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td>{item.type}</td>
                                    <td style={{ fontSize: '1.5em' }} ></td>
                                </tr>)
                        });

                    }
                }
            } else {
                if (!!mercruiserCertDetails) {
                    if (!isInitial) {
                        return mercruiserCertDetails.hourItemsAvail.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td>{item.hours}</td>
                                    <td>{item.type}</td>
                                </tr>)
                        });
                    }
                    else {
                        return mercruiserCertDetails.staticItemReq.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td>{item.type}</td>
                                    <td style={{ fontSize: '1.5em' }} ></td>
                                </tr>)
                        });

                    }
                }
            }
          
        };


        const getCompleted = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.completedInitial.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                                <td>{item.type}</td>
                                <td style={{ fontSize: '1.5em' }} >{check}</td>
                            </tr>
                        )
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.completedInitial.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                                <td>{item.type}</td>
                                <td style={{ fontSize: '1.5em' }} >{check}</td>
                            </tr>
                        )
                    });
                }
            }
        };
        const getReqItems = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.staticItemReq.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                            </tr>)
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.staticItemReq.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                            </tr>)
                    });
                }
            }
        };

        const getTriCDetails = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.triAnnaulItemsAvail.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                            </tr>)
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.triAnnaulItemsAvail.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                            </tr>)
                    });
                }
            }
        };

        const getIncompletedDistanceInitialPool = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.incompleteInitialPool.map((item, index) => {
                        if (item.reqID.includes("Distance") && item.dmn_id == sapDomainId) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#ff5e4e', textAlign: 'center', lineHeight: '3' }} > &#10005; </td>
                                </tr>)
                        }
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.incompleteInitialPool.map((item, index) => {
                        if (item.reqID.includes("Distance") && item.dmn_id == sapDomainId) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#ff5e4e', textAlign: 'center', lineHeight: '3' }} > &#10005; </td>
                                </tr>)
                        }
                    });
                }
            }
        };



        const getCompletedDistanceInitialPool = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Distance")) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#5cff61', textAlign: 'center', lineHeight: '3' }} > {check} </td>
                                </tr>)
                        }
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Distance")) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#5cff61', textAlign: 'center', lineHeight: '3' }} > {check} </td>
                                </tr>)
                        }
                    });
                }
            }
        };



        const getIncompletedInPersonInitialPool = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.incompleteInitialPool.map((item, index) => {
                        if (item.reqID.includes("InP") && item.dmn_id == sapDomainId) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#ff5e4e', textAlign: 'center', lineHeight: '3' }} > &#10005; </td>
                                </tr>)
                        }
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.incompleteInitialPool.map((item, index) => {
                        if (item.reqID.includes("InP") && item.dmn_id == sapDomainId) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#ff5e4e', textAlign: 'center', lineHeight: '3' }} > &#10005; </td>
                                </tr>)
                        }
                    });
                }
            }
        };



        const getCompletedInPersonInitialPool = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("InP")) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#5cff61', textAlign: 'center', lineHeight: '3' }} > {check} </td>
                                </tr>)
                        }
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("InP")) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#5cff61', textAlign: 'center', lineHeight: '3' }} > { check} </td>
                                </tr>)
                        }
                    });
                }
            }
        };



        const getIncompletedElectiveInitialPool = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.incompleteInitialPool.map((item, index) => {
                        if (item.reqID.includes("Elec") && item.dmn_id == sapDomainId) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#ff5e4e', textAlign: 'center', lineHeight: '3' }} > &#10005; </td>
                                </tr>)
                        }
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.incompleteInitialPool.map((item, index) => {
                        if (item.reqID.includes("Elec") && item.dmn_id == sapDomainId) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#ff5e4e', textAlign: 'center', lineHeight: '3' }} > &#10005; </td>
                                </tr>)
                        }
                    });
                }
            }
        };



        const getCompletedElectiveInitialPool = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    return outboardCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Elec")) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#5cff61', textAlign: 'center', lineHeight: '3' }} > {check} </td>
                                </tr>)
                        }
                    });
                }
            } else {
                if (!!mercruiserCertDetails) {
                    return mercruiserCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Elec")) {
                            return (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td style={{ backgroundColor: '#5cff61', textAlign: 'center', lineHeight: '3' }} > {check} </td>
                                </tr>)
                        }
                    });
                }
            }
        };




        const getInPersonCount = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails && outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("InP")) != null) {
                    return outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("InP")).numItems;
                }
            } else {
                if (!!mercruiserCertDetails && mercruiserCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("InP")) != null) {
                    return mercruiserCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("InP")).numItems;
                } }
        };
        const getDistanceCount = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails && outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Dist")) != null) {
                    return outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Dist")).numItems;
                }
            } else {
                if (!!mercruiserCertDetails && mercruiserCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Dist")) != null) {
                    return mercruiserCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Dist")).numItems;
                }
            }
        };
        const getElectiveCount = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails && outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Elec")) != null) {
                    return outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Elec")).numItems;
                }
            } else {
                if (!!mercruiserCertDetails && mercruiserCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Elec")) != null) {
                    return mercruiserCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Elec")).numItems;
                }
            }
        };


        const hasElective = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    let val = outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Elec")) != null ? true : false;
                    return val;
                }
            } else {
                if (!!mercruiserCertDetails) {
                    let val = mercruiserCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Elec")) != null ? true : false;
                    return val;
                }
            }
        };






        const getDistanceCompletedCount = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    let ix = 0;
                    outboardCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Distance")) {
                            ++ix;
                        }
                    });
                    return ix;
                }
            } else {
                if (!!mercruiserCertDetails) {
                    let ix = 0;
                    mercruiserCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Distance")) {
                            ++ix;
                        }
                    });
                    return ix;
                }
            }
        };


        const getInpersonCompletedCount = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    let ix = 0;
                    outboardCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("InP")) {
                            ++ix;
                        }
                    });
                    return ix;
                }
            } else {
                if (!!mercruiserCertDetails) {
                    let ix = 0;
                    mercruiserCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("InP")) {
                            ++ix;
                        }
                    });
                    return ix;
                }
            }
        };


        const getElectiveCompletedCount = () => {
            if (!toggleCert) {
                if (!!outboardCertDetails) {
                    let ix = 0;
                    outboardCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Elec")) {
                            ++ix;
                        }
                    });
                    return ix;
                }
            } else {
                if (!!mercruiserCertDetails) {
                    let ix = 0;
                    mercruiserCertDetails.completedInitialPool.map((item, index) => {
                        if (item.reqID.includes("Elec")) {
                            ++ix;
                        }
                    });
                    return ix;
                }
            }
        };

        // get end of season 
        var today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        if (today.getMonth > 7) {
            today.setMonth(6, 31);
            today.setFullYear(today.getFullYear + 1);
        }
        else {
            today.setMonth(6, 31);

        }
        var todayString = today.toDateString();

        var triAnnualDueDate;
        const initialWidth = isInitial ? 12 : 4;

        return (
            <div>
                
                <header style={{ fontstyle: 'Industry', fontSize: '3em', textAlign: 'center', color: 'Red', backgroundColor: 'yellow' }} > Data on this page is updated on a daily basis</header>
                <header style={{ fontstyle: 'Industry', fontSize: '2em', textAlign: 'center', color: 'Black', }} >{!toggleCert ? "Outboard Progress" : "MerCruiser Progress"}</header>
                <button style={{ backgroundColor: 'red', border: 'none', color: 'white', borderRadius: '8px', marginBottom: '12px', padding: '8px', fontWeight: '600' }} onClick={() => this.setState({ toggleCert: !toggleCert })}>{!toggleCert ? "Click to show MerCruiser" : "Click to show outboard"}</button>

                <Row >

                    <Col xl={initialWidth} lg={12}>
                        {this.state.outboardCertDetails && !isInitial ? <p style={{ fontstyle: 'Soleto', fontSize: '2.2em' }}>Annual Hours Required: {hoursRequired}  <br /> Annual Hours Completed: {accruedHours} <br /> Due: {todayString}  </p> : null}
                        {this.state.outboardCertDetails && !isInitial ? <p style={{ fontstyle: 'Soleto', fontSize: '1.3em' }}> You must complete the required hours to succesfully maintain your certification  </p> : null}
                        {this.state.outboardCertDetails && !isInitial ?
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Training Name</th>
                                        <th>Hours Worth</th>
                                        <th>Training Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getCertDetails()}

                                </tbody>
                            </Table>
                            : null}

                        {isInitial ?
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Training Name</th>
                                        <th>Training Type</th>
                                        <th>Completed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getCertDetails()}
                                    {getCompleted()}

                                </tbody>
                            </Table>
                            : null}

                    </Col>

                    {this.stateoutboardCertDetails && !isInitial && annualRequiredItems > 0 ?
                        <Col xl={4} lg={6}>
                            <p style={{ fontstyle: 'Soleto', fontSize: '2.2em' }}> Required Courses <br />   </p>
                            <p style={{ fontstyle: 'Soleto', fontSize: '1.3em' }}>  <br /><br />You must complete all of these courses to succesfully maintain <br /> your certification this season. In addition to your hourly requirement. <br /><br /> </p>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Training Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getReqItems()}

                                </tbody>
                            </Table>

                        </Col>
                        : null}


                    {this.state.outboardCertDetails && !isInitial ?
                        <Col xl={4} lg={6}>
                            <Card>
                                <Card.Body >
                                    <Card.Title style={{ textAlign: 'center', fontSize: '2.8em' }}> Tri-Annual </Card.Title>
                                    <p style={{ fontstyle: 'Soleto', fontSize: '2.2em' }}> Classes Required: {triAnnualItemsRequired}  <br /> Classes Completed: {triAnnualItemsAchieved} <br /> Due: {triAnnualDueDate} </p>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Training Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getTriCDetails()}

                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                        : null}


                    {this.state.outboardCertDetails && isInitial && outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("Dist")) != null ?
                        <Col xl={4} lg={6}>
                            <Card>
                                <Card.Body >
                                    <Card.Title style={{ textAlign: 'center', fontSize: '2.8em' }}> Distance </Card.Title>
                                    <p style={{ fontstyle: 'Soleto', fontSize: '2.2em' }}> Classes Required: {getDistanceCount()}   <br /> Classes Completed: {getDistanceCompletedCount()}  </p>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Training Name</th>
                                                <th>Completed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getIncompletedDistanceInitialPool()}
                                            {getCompletedDistanceInitialPool()}

                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                        : null}


                    {this.state.outboardCertDetails && isInitial && outboardCertDetails.incompleteInitialPool.find(({ reqID }) => reqID.includes("InP")) != null ?
                        <Col xl={4} lg={6}>
                            <Card>
                                <Card.Body >
                                    <Card.Title style={{ textAlign: 'center', fontSize: '2.8em' }}> In Person </Card.Title>
                                    <p style={{ fontstyle: 'Soleto', fontSize: '2.2em' }}> Classes Required:  {getInPersonCount()} <br /> Classes Completed: {getInpersonCompletedCount()}   </p>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Training Name</th>
                                                <th> Completed </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getIncompletedInPersonInitialPool()}
                                            {getCompletedInPersonInitialPool()}

                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                        : null}


                    {this.state.outboardCertDetails && isInitial && hasElective() ?
                        <Col xl={4} lg={6}>
                            <Card>
                                <Card.Body >
                                    <Card.Title style={{ textAlign: 'center', fontSize: '2.8em' }}> Electives </Card.Title>
                                    <p style={{ fontstyle: 'Soleto', fontSize: '2.2em' }}> Classes Required:  {getElectiveCount()} <br /> Classes Completed: {getElectiveCompletedCount()}   </p>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Training Name</th>
                                                <th> Completed </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getIncompletedElectiveInitialPool()}
                                            {getCompletedElectiveInitialPool()}

                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                        : null}







                </Row >
            </div>
        );
    }
}




export default InfrastructureWrapper(withRouter(UserCertComponent));