import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const superAdminService = {
    toggleImpersonate,
    getimpersonatedRoles,
    setimpersonatedRoles
};

function toggleImpersonate() {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/admin/toggleImpersonate`, null, {
            headers
        });
    });
}

function getimpersonatedRoles() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/admin/impersonatedRoles`, {
            headers
        });
    });
}

function setimpersonatedRoles(model) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/admin/impersonatedRoles`, JSON.stringify(model), {
            headers
        });
    });
}