import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfrastructureWrapper } from '../../components';
import { Row, Col, Button } from 'react-bootstrap';
import { lmsAdminService } from '../../services';
import { handleResponseAsDownload } from '../../helpers/responseDownloadHelper';

class ClassDelinquencyFeesPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        lmsAdminService.getDelinquencyLastModified()
            .then(
                response => {
                    const { data } = response;
                    this.setState({ modifiedDate: data.modifiedDate });
                },
                () => {
                    showError('Error loading last modified date on lms file');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { modifiedDate } = this.state;

        return (
            <Row>
                <Col md={12}>
                    <fieldset>
                        <legend>Class Delinquency Fees</legend>
                    </fieldset>
                    <Row>
                        <Col md={6}>
                            {!!modifiedDate ? (
                                <p>
                                    LMS report file last updated at {moment(modifiedDate).format("MMMM Do YYYY, h:mm:ss a")}
                                </p>
                            ): null}
                            <Button onClick={this.handleGenerateClick}>Generate</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    handleGenerateClick = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        lmsAdminService.getDelinquencyList()
            .then(handleResponseAsDownload,
                () => {
                    showError('Error generating delinquency report');
                })
            .finally(() => {
                decrementSpinner();
            });
    }
}

ClassDelinquencyFeesPageComponent.propTypes = {
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    match: PropTypes.object.isRequired
};

const ClassDelinquencyFeesPage = InfrastructureWrapper(ClassDelinquencyFeesPageComponent);
export default ClassDelinquencyFeesPage;