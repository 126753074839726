import axios from 'axios';
import { navConstants } from '../constants';
import { apiHostUrl, getHeaders } from '../helpers';

export const navActions = {
    getMenu,
    incrementSpinner,
    decrementSpinner,
    showError,
    dismissError,
    showPrompt,
    dismissPrompt,
    showMessage,
    dismissMessage
};

function getMenu() {
    return (dispatch) => {
        dispatch(incrementSpinner());
        getHeaders({}, true).then((headers) => {
            return axios.get(`${apiHostUrl}/api/v1/configuration/menu`,
                {
                    headers
                })
                .then(response => {
                    const { data } = response;

                    dispatch((() => {
                        return {
                            type: navConstants.GET_MENU,
                            menuItems: data.menuItems,
                            isTestEnvironment: data.isTestEnvironment
                        };
                    })());

                    dispatch(decrementSpinner());
                })
                .catch(error => {
                    console.error("Error fetching menu:", error);
                    dispatch(decrementSpinner());
                    dispatch(showError("Failed to fetch menu. Please try again."));
                });
        });
    };
}

function incrementSpinner() {
    return { type: navConstants.INCREMENT_SPINNER };
}

function decrementSpinner() {
    return { type: navConstants.DECREMENT_SPINNER };
}

function showError(message) {
    return {
        type: navConstants.SHOW_ERROR,
        message
    };
}

function dismissError() {
    return { type: navConstants.DISMISS_ERROR };
}

function showPrompt(title, message, onAccept, zIndex) {
    return {
        type: navConstants.SHOW_PROMPT,
        title,
        message,
        onAccept,
        zIndex
    };
}

function dismissPrompt() {
    return { type: navConstants.DISMISS_PROMPT };
}

function showMessage(title, message) {
    return {
        type: navConstants.SHOW_MESSAGE,
        title,
        message
    };
}

function dismissMessage() {
    return { type: navConstants.DISMISS_MESSAGE };
}