import React, { Component } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormField from './FormField';

class FormCopyToClipboard extends Component {
    copyCodeToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")
    }

    render() {
        const { fieldName, fieldValue, label, subLabel } = this.props;

        return (
            <FormField
                fieldName={fieldName}
                label={label}
                subLabel={subLabel}
            >
                <InputGroup className="mb-2">
                    <Form.Control
                        placeholder={`Enter ${label}`}
                        as='input'
                        value={fieldValue !== null && fieldValue !== undefined ? fieldValue : ''}
                        readOnly
                        ref={(textarea) => this.textArea = textarea}
                    />
                    <InputGroup.Append>
                        <Button className='input-group-append' onClick={this.copyCodeToClipboard}>
                            Copy to Clipboard
                        </Button>
                    </InputGroup.Append>
                </InputGroup>

            </FormField >
        );
    }
}

FormCopyToClipboard.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    subLabel: PropTypes.string
};

export default FormCopyToClipboard;