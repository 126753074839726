import React from 'react';
import { connect } from 'react-redux';
import { navActions } from '../actions';

const mapStateToProps = (state) => ({
    permissionsLoaded: state.configurationReducer.permissionsLoaded,
    hasInternalAdminAccess: state.configurationReducer.hasInternalAdminAccess,
    hasSupervisorAccess: state.configurationReducer.hasSupervisorAccess,
    hasTechnicianAccess: state.configurationReducer.hasTechnicianAccess,
    hasSuperAdminAccess: state.configurationReducer.hasSuperAdminAccess,
    hasRegionalAdminAccess: state.configurationReducer.hasRegionalAdminAccess,
    hasResetEmail: state.configurationReducer.hasResetEmail
});

const mapDispatchToProps = (dispatch) => ({
    incrementSpinner: () => dispatch(navActions.incrementSpinner()),
    decrementSpinner: () => dispatch(navActions.decrementSpinner()),
    showError: (message) => dispatch(navActions.showError(message)),
    showPrompt: (title, message, onAccept) => dispatch(navActions.showPrompt(title, message, onAccept)),
    showMessage: (title, message) => dispatch(navActions.showMessage(title, message))
});

export default function InfrastructureWrapper(ComposedClass) {
    class Component extends React.Component {
        render() {
            return <ComposedClass {...this.props} />;
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(Component);
}