import React, { Component } from 'react';
import { connect } from 'react-redux';
import App from "./App";
import NavMenu from './NavMenu';
import Footer from './Footer';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { UserPage } from './pages';
import { Spinner, ErrorModal, PromptModal, MessageModal, ReAuthModal } from './components';
import LoginPage from './pages/LoginPage';
import ExpiredInvitePage from './pages/ExpiredInvitePage';
import { AdminPage } from './pages/admin';
import IntelligencePage from './pages/intelligence';
import { authProvider } from './helpers';
import { configurationActions } from './actions';
import { DocumentationPage } from './pages/documentation';
import DocumentationMenu from './components/DocumentationMenu';
import { Alert } from 'react-bootstrap';
import HomePage from './pages/HomePage';
import ManagerDashboard from './pages/managerDashboard/ManagerDashboard';
import AuthErrorPage from './pages/AuthErrorPage';
import UserEditProfilePage from './pages/users/UserEditProfilePage';
import CertDetails from './pages/users/CertDetails';
import RoleDebugPage from './pages/users/RoleDebugPage';
import ClassPage from './pages/classes/ClassPage';
import EnrollClassPage from './pages/classes/EnrollClassPage';



class RoutesComponent extends Component {
    constructor(props) {
        super(props);

        this.checkCount = 0;

        this.state = {
            user: null,
            loaded: false
        };
    }

    componentDidMount() {
        const { permissionsLoaded, getUserPermissions } = this.props;
        
        this.redirectToLoginIfNotAuthenticated();
        
        const user = this.getUser();
        if (user) {
            if (!permissionsLoaded) {
                getUserPermissions();
            }
        }
        else {
            this.checkUserInterval = setInterval(this.checkForUserAndPermissions, 250);
        }
    }

    componentDidUpdate(prevProps) {
        this.redirectToLoginIfNotAuthenticated();

        if (this.props.location !== prevProps.location) {
            if (window._loq) {
                window._loq.push(['page']);
            }
        }
    }

    redirectToLoginIfNotAuthenticated = () => {
        if (authProvider.hasLoaded() && authProvider.isExpired()) {
            if (window.location.href !== window.location.origin && window.location.href !== window.location.origin + '/' &&
                window.location.href.toLowerCase() !== (window.location.origin + '/ExpiredInvite').toLowerCase() &&
                window.location.pathname.toLowerCase() !== '/error' && window.location.hash.indexOf('error_description') < 0) {
                window.location.replace(window.location.origin);
            }
        }
    }

    checkForUserAndPermissions = () => {
        const { permissionsLoaded, getUserPermissions } = this.props;
        const authLoaded = authProvider.hasLoaded();

        if (this.checkCount > 40 || (authLoaded && authProvider.isExpired())) {
            clearInterval(this.checkUserInterval);
            this.setState({ loaded: true });
            return;
        }

        this.checkCount++;
        if (authLoaded) {
            const user = this.getUser();
            if (user) {
                //console.log('checkForUserAndPermissions', this.checkCount);
                clearInterval(this.checkUserInterval);
                this.setState({ loaded: true });

                if (!permissionsLoaded) {
                    getUserPermissions();
                }
            }
        }
    }




    getUser = () => {
        const authLoaded = authProvider.hasLoaded();
        let user = null;

        if (authLoaded) {
            user = authProvider.getUser();
        }

        this.setState({ user });
        return user;
    }

    render() {
        const { hasInternalAdminAccess, permissionsLoaded, hasAnyRoleAccess, hasSuperAdminAccess,
            activeUser, lockedUser, completeProfile } = this.props;
        const { user, loaded } = this.state;
        const isAuthenticated = !!user && !authProvider.isExpired();

        const isDocumentation = window.location.pathname.toLowerCase().startsWith('/documentation');

        return (
            <>
              
                <div style={isAuthenticated && permissionsLoaded && !completeProfile && activeUser ? { opacity: 0.5 } : {}}>
                    {!isDocumentation ? <NavMenu user={user} /> : <DocumentationMenu />}
                    <div className={!isDocumentation ? 'body-content' : 'documentation-content'}>

                        <div style={{ paddingRihgt: '0.5%', paddingLeft: '0.5%', paddingBottom: '10%', paddingTop: '0.5%', overflow: 'auto' }}>
                        <Route path='/expiredinvite' component={ExpiredInvitePage} />
                        <Route path='/error' component={AuthErrorPage} />
                        {isAuthenticated && permissionsLoaded && !activeUser && !lockedUser ? (
                            <Alert variant='secondary'>
                                <Alert.Heading>Account Status</Alert.Heading>
                                <p>It looks like your account is not active, please contact your supervisor
                                    or admin to get it enabled if this is not correct</p>
                            </Alert>
                        ) : null}
                        {isAuthenticated && permissionsLoaded && lockedUser ? (
                            <Alert variant='warning'>
                                <Alert.Heading>Account Status</Alert.Heading>
                                <p>It looks like your account is locked, please contact your supervisor
                                    or admin to get it enabled if this is not correct</p>
                            </Alert>
                        ) : null}
                        {isAuthenticated && permissionsLoaded && completeProfile ? (
                            <>
                                <Route exact path='/' component={HomePage} />
                                <Route path='/users' component={UserPage} />
                                <Route exact path='/classes' component={ClassPage} /> 
                                <Route exact path='/classes/enroll' component={EnrollClassPage} />
                                <Route exact path='/cert/:cID' component={CertDetails} />

                                <Route path='/admin' render={() => {
                                    if (hasInternalAdminAccess || hasSuperAdminAccess)
                                        return <AdminPage />;
                                    return <Redirect to='/' />;
                                }} >
                                </Route>

                                <Route path='/manager-dashboard' render={() => {
                                    if (hasInternalAdminAccess || hasSuperAdminAccess)
                                        return <ManagerDashboard />;
                                    return <Redirect to='/' />;
                                }} />

                                <Route path='/intelligence' render={() => {
                                    if (hasInternalAdminAccess || hasSuperAdminAccess)
                                        return <IntelligencePage />;
                                    return <Redirect to='/' />;
                                }} />

                                <Route path='/documentation/:path' render={() => {
                                    if (hasAnyRoleAccess)
                                        return <DocumentationPage />;
                                    return <Redirect to='/' />;
                                }} />

                                <Route path='/roledebug' render={() => {
                                    return <RoleDebugPage />;
                                }} />

                            </>
                        ) : null}
                        {!isAuthenticated && loaded ? (
                            <Route path='/' component={LoginPage} />
                        ) : null}
                        </div>
                    </div>
                    <Spinner />
                    <ErrorModal />
                    <PromptModal />
                    <MessageModal />
                    <ReAuthModal />

                    {!isDocumentation ? <Footer user={user} style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} /> : null}
                </div>
                {isAuthenticated && permissionsLoaded && !completeProfile && activeUser ? (
                    <div style={{ position: 'absolute', left: '5em', right: '5em', top: '7em', bottom: '7em', border: '#343a40 1px solid', borderRadius: '1em', padding: '2em', background: 'white', opacity: 1, overflow: 'auto' }}>
                        <UserEditProfilePage />
                    </div>
                ) : null}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    permissionsLoaded: state.configurationReducer.permissionsLoaded,
    hasInternalAdminAccess: state.configurationReducer.hasInternalAdminAccess,
    hasAnyRoleAccess: state.configurationReducer.hasAnyRoleAccess,
    hasSuperAdminAccess: state.configurationReducer.hasSuperAdminAccess,
    activeUser: state.configurationReducer.activeUser,
    lockedUser: state.configurationReducer.lockedUser,
    completeProfile: state.configurationReducer.completeProfile
});

const mapDispatchToProps = (dispatch) => ({
    getUserPermissions: () => dispatch(configurationActions.getPermissions())
});

const Routes = connect(mapStateToProps, mapDispatchToProps)(withRouter(RoutesComponent));
export default Routes;