import React, { Component } from 'react';
import { Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfrastructureWrapper } from '../../components';
import { domainService } from '../../services';

const pageSizeDefault = 20;
const pageIndexDefault = 0;

class DomainInvitePageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isRunning: false
        };
    }

    componentDidMount() {
        this.getList();
        this.checkStateInterval = setInterval(this.checkState, 3000);
    }

    getList = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        domainService.getInviteStatus()
            .then(
                response => {
                    const items = response.data;
                    this.setState({ items });
                },
                () => {
                    showError('Error loading status list');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    checkState = () => {
        const { showError } = this.props;
        domainService.isBulkInviteRunning()
            .then(
                response => {
                    const { isRunning } = response.data;
                    this.setState({ isRunning });

                    if (!isRunning) {
                        clearInterval(this.checkStateInterval);
                        this.checkStateInterval = null;
                    }

                    this.getList();
                },
                () => {
                    clearInterval(this.checkStateInterval);
                    this.checkStateInterval = null;
                    showError('Error checking invite status');
                }
            );
    }

    handleInviteRegionClick = (domainId, organizationType, domainName) => {
        const { showPrompt } = this.props;

        showPrompt(
            'Invite all Region users?',
            `Are you sure you want invite all users with a valid email address in ${domainName}? `, () => {
                const { incrementSpinner, decrementSpinner, showError } = this.props;

                incrementSpinner();

                domainService.inviteBatch(domainId, organizationType)
                    .then(
                        (response) => {
                            // Set Interval
                            this.checkStateInterval = setInterval(this.checkState, 3000);
                        },
                        () => {
                            showError('Error inviting batch');
                        }
                    ).finally(() => {
                        decrementSpinner();
                    });
            });

    }

    handleReInviteRegionClick = (domainId, organizationType, domainName) => {
        const { showPrompt } = this.props;

        showPrompt(
            'ReInvite all Region users?',
            `Are you sure you want invite all users with a valid email address in ${domainName} that have expired invites? `, () => {
                const { incrementSpinner, decrementSpinner, showError } = this.props;

                incrementSpinner();

                domainService.reinviteBatch(domainId, organizationType)
                    .then(
                        (response) => {
                            // Set Interval
                            this.checkStateInterval = setInterval(this.checkState, 3000);
                        },
                        () => {
                            showError('Error inviting batch');
                        }
                    ).finally(() => {
                        decrementSpinner();
                    });
            });

    }

    handleAbortClick = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        domainService.cancelBulkInviteTask()
            .then(
                () => {
                    this.checkState();
                },
                () => {
                    showError('Error aborting');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { items, isRunning } = this.state;

        const invitedAndAccepted = items.invitedAndAccepted || []
        const invitedNotAccepted = items.invitedNotAccepted || []
        const notInvited = items.notInvited || []

        return (
            <Row>
                <Col md={12}>
                    <fieldset>
                        <legend>Domain Invite Status</legend>
                    </fieldset>
                    {isRunning ? (<Row>
                        <h2>
                            Processing invites...
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </h2>
                        <Button onClick={this.handleAbortClick}>Abort</Button>
                    </Row>) : null}
                    <Row>
                        <Col md={4}>
                            <h4>Not Invited By Domain</h4>
                            <p>* Includes only users with email address</p>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Domain</th>
                                        <th>TAM Zone</th>
                                        <th>User Count</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notInvited.map((item) => {
                                        return (
                                            <tr key={item.id + item.organizationType}>
                                                <td>{item.name}</td>
                                                <td>{item.organizationType}</td>
                                                <td>{item.userCount}</td>
                                                <td>{!isRunning ? (<Button onClick={() => this.handleInviteRegionClick(item.id, item.organizationType, item.name)}>Invite</Button>) : null}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <h4>Invited and Accepted By Domain</h4>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Domain</th>
                                        <th>TAM Zone</th>
                                        <th>User Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invitedAndAccepted.map((item) => {
                                        return (
                                            <tr key={item.id + item.organizationType}>
                                                <td>{item.name}</td>
                                                <td>{item.organizationType}</td>
                                                <td>{item.userCount}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <h4>Invited and Not Accepted By Domain</h4>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Domain</th>
                                        <th>TAM Zone</th>
                                        <th>User Count</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invitedNotAccepted.map((item) => {
                                        return (
                                            <tr key={item.id + item.organizationType}>
                                                <td>{item.name}</td>
                                                <td>{item.organizationType}</td>
                                                <td>{item.userCount}</td>
                                                <td>{!isRunning ? (<Button onClick={() => this.handleReInviteRegionClick(item.id, item.organizationType, item.name)}>Re-invite expired</Button>) : null}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

DomainInvitePageComponent.propTypes = {
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func,
    showPrompt: PropTypes.func
};

const DomainInvitePage = InfrastructureWrapper(withRouter(DomainInvitePageComponent));
export default DomainInvitePage;