import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const shirtSizeService = {
    getAll,
};

function getAll() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/shirtsize/all`, {
            headers
        });
    });
}