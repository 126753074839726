import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DomainListPage, DomainInvitePage } from '.';

class DomainPageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}`} component={DomainListPage} />
                <Route exact path={`${url}/invites`} component={DomainInvitePage} />
            </>
        );
    }
}

DomainPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const DomainPage = withRouter(DomainPageComponent);
export default DomainPage;