import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImpersonateAdminPage from './ImpersonateAdminPage';
import { JobCodePage } from '../jobcodes';
import { OrganizationPage } from '../organizations';
import { DomainPage } from '../domains';
import { UserRolePage } from '../userroles';
import { CountryPage } from '../countries';
import { AccountCodePage } from '../accountCodes';
import EmailTemplatePage from '../emailTemplate/EmailTemplatePage';
import ClassDelinquencyFeesPage from './ClassDelinquencyFeesPage';

class AdminPageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}/impersonate`} component={ImpersonateAdminPage} />
                <Route path={`${url}/countries`} component={CountryPage} />
                <Route path={`${url}/jobcodes`} component={JobCodePage} />
                <Route path={`${url}/organizations`} component={OrganizationPage} />
                <Route path={`${url}/domains`} component={DomainPage} />
                <Route path={`${url}/userroles`} component={UserRolePage} />
                <Route path={`${url}/accountCodes`} component={AccountCodePage} />
                <Route path={`${url}/emailTemplates`} component={EmailTemplatePage} />
                <Route path={`${url}/classdelinquencyfees`} component={ClassDelinquencyFeesPage} />
            </>
        );
    }
}

AdminPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const AdminPage = withRouter(AdminPageComponent);
export default AdminPage;