import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './FormButton.css';

const FormButtonOk = ({ onClick, title, children, disabled }) => {
    return (
        <Button
            className='form-button'
            variant='primary'
            title={title}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </Button>
    );
};

FormButtonOk.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default FormButtonOk;