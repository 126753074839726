import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';

class DocumentationMenuComponent extends Component {
    render() {

        return (
            <Navbar bg='dark' sticky='top' variant='dark'>
                <Navbar.Brand href='/'>
                    <div className='logo-border'>
                        <div className='logo documentation'></div>
                    </div>
                </Navbar.Brand>
                <Nav className='ml-auto'>
                    <Nav.Link href='/'>Close</Nav.Link>
                </Nav>
            </Navbar>
        );
    }
}

DocumentationMenuComponent.propTypes = {
};

const mapStateToProps = (state) => ({
});

const DocumentationMenu = connect(mapStateToProps, null)(DocumentationMenuComponent);
export default DocumentationMenu;