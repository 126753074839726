import React from 'react'
import './widget.css'

function WidgetTitle({ children }) {
    return (
        <>
            <h2 className='widget-title'>{children}</h2>
        </>
    )
}

export default WidgetTitle;