import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Spinner.css';

class SpinnerComponent extends Component {
    render() {
        const { spinnerShowing } = this.props;
        if (!spinnerShowing) {
            return null;
        }
        return (
            <div className='spinner spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
            </div>
        );
    }
}

SpinnerComponent.propTypes = {
    spinnerShowing: PropTypes.bool
};

const mapStateToProps = (state) => ({
    spinnerShowing: state.navReducer.spinnerShowing
});

const Spinner = connect(mapStateToProps)(SpinnerComponent);
export default Spinner;