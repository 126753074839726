import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { InfrastructureWrapper } from '../../components';
import {roleService } from '../../services';

class RoleDebugPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            debugRoles: {}
        };
    }

    componentDidMount() {
        this.loadModel();
    }

    loadModel = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

            incrementSpinner();
            roleService.getMyRoles().then(response => {
                this.setState({ debugRoles: response.data });
            }).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { debugRoles } = this.state;

        return (
            <Row>
                <Col md={12}>
                    {JSON.stringify(debugRoles)}
                </Col>
            </Row>
        );
    }
}

const RoleDebugPage = InfrastructureWrapper(withRouter(RoleDebugPageComponent));
export default RoleDebugPage;