import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfrastructureWrapper, FormSwitchCheckbox, FormTextInput, FormSelect } from '../../components';
import { configurationActions, navActions } from '../../actions';
import { Row, Col, Button } from 'react-bootstrap';
import { superAdminService, organizationService } from '../../services';
import debounce from 'debounce';
import { toSelectOptionsStringArray } from '../../helpers';

class ImpersonateAdminPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {
                internalAdminRole: false,
                supervisorRole: false,
                technicianRole: false,
                regionalAdminRole: false,
                supervisorOrganizations: '',
                adminDomains: ''
            },
            tamZones: []
        };

        this.debouncedSaveImpersonatedRoles = debounce(this.saveImpersonatedRoles, 500);
    }

    componentDidMount() {
        const { incrementSpinner, decrementSpinner, showError, permissionsLoaded, getUserPermissions } = this.props;
        if (!permissionsLoaded) {
            getUserPermissions();
        }

        incrementSpinner();
        superAdminService.getimpersonatedRoles()
            .then((response) => {
                const { data } = response;
                this.setState({ model: data });
            },
                () => {
                    showError('Error getting impersonate groups');
                })
            .finally(() => {
                decrementSpinner();
            });

        incrementSpinner();
        const promises = [
            this.getResource(organizationService.getTAMZones, 'tamZones', 'Error loading TAM Zones')
        ];

        Promise.all(promises).finally(() => {
            decrementSpinner();
        });
    }

    getResource(serviceFunction, stateName, errorMessage) {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        serviceFunction()
            .then(
                response => {
                    const { data } = response;
                    const newState = {};
                    newState[stateName] = data;
                    this.setState(newState);
                },
                () => {
                    showError(errorMessage);
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { hasSuperAdminAccess, impersonateOn } = this.props;
        const { model, tamZones } = this.state;
        const { supervisorRole, technicianRole, regionalAdminRole, internalAdminRole, supervisorOrganizations, adminDomains } = model;

        if (!hasSuperAdminAccess) {
            return (<></>);
        }

        const tamZoneOptions = toSelectOptionsStringArray(tamZones, {
            showDefault: true,
            defaultLabel: 'Select TAM Zone'
        });

        return (
            <Row>
                <Col md={12}>
                    <fieldset>
                        <legend>Super Admin</legend>
                    </fieldset>
                    <Row>
                        <Col md={6}>
                            <FormSwitchCheckbox
                                fieldName='impersonateOn'
                                fieldValue={impersonateOn}
                                label='Impersonate Permissions'
                                onChange={this.handleValueChanged}
                                trueText='On'
                                falseText='Off'
                                isBool
                            />
                        </Col>
                        <Col md={6}>
                            <FormSwitchCheckbox
                                fieldName='internalAdminRole'
                                fieldValue={internalAdminRole}
                                label='Internal Admin'
                                onChange={this.handleValueChanged}
                                isBool
                            />
                            <FormSwitchCheckbox
                                fieldName='supervisorRole'
                                fieldValue={supervisorRole}
                                label='Supervisor'
                                onChange={this.handleValueChanged}
                                isBool
                            />
                            {!!supervisorRole ? (
                                <FormTextInput
                                    fieldName='supervisorOrganizations'
                                    fieldValue={supervisorOrganizations}
                                    label='Supervisor Oranizations (comma separated)'
                                    onChange={this.handleValueChanged}
                                    required={supervisorRole} />
                            ) : null}
                            <FormSwitchCheckbox
                                fieldName='technicianRole'
                                fieldValue={technicianRole}
                                label='Technician'
                                onChange={this.handleValueChanged}
                                isBool
                            />
                            <FormSwitchCheckbox
                                fieldName='regionalAdminRole'
                                fieldValue={regionalAdminRole}
                                label='Regional Admin'
                                onChange={this.handleValueChanged}
                                isBool
                            />
                            {!!regionalAdminRole ? (
                                <FormTextInput
                                    fieldName='supervisorOrganizations'
                                    fieldValue={supervisorOrganizations}
                                    label='Regional Admin Oranizations (comma separated)'
                                    onChange={this.handleValueChanged}
                                    required={regionalAdminRole && !adminDomains} />
                            ) : null}
                            {!!regionalAdminRole ? (
                                <FormTextInput
                                    fieldName='adminDomains'
                                    fieldValue={adminDomains}
                                    label='Regional Admin Domains (comma separated)'
                                    onChange={this.handleValueChanged}
                                    required={regionalAdminRole && !supervisorOrganizations} />
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    handleImpersonateToggleClick = () => {
        const { incrementSpinner, decrementSpinner, showError, getUserPermissions, getMenu } = this.props;
        const { model } = this.state;

        incrementSpinner();
        superAdminService.setimpersonatedRoles(model)
            .then(() => {
                superAdminService.toggleImpersonate()
                    .then(() => { },
                        () => {
                            showError('Error toggling impersonate');
                        })
                    .finally(() => {
                        getUserPermissions();
                        getMenu();
                        decrementSpinner();
                    });
            },
                () => {
                    showError('Error toggling impersonate groups');
                })
            .finally(() => {
                getUserPermissions();
                getMenu();
                decrementSpinner();
            });
    }

    handleValueChanged = (fieldName, value) => {
        const { model } = this.state;

        if (fieldName === 'supervisorRole' && !!value) {
            model.regionalAdminRole = false;
        }

        if (fieldName === 'regionalAdminRole' && !!value) {
            model.supervisorRole = false;
        }

        if (fieldName !== 'impersonateOn') {
            model[fieldName] = value;

            this.setState({ model });
            this.debouncedSaveImpersonatedRoles();
        } else {
            this.handleImpersonateToggleClick();
        }
    }

    saveImpersonatedRoles = () => {
        const { incrementSpinner, decrementSpinner, showError, getUserPermissions, getMenu } = this.props;
        const { model } = this.state;
        incrementSpinner();
        superAdminService.setimpersonatedRoles(model)
            .then(() => { },
                () => {
                    showError('Error toggling impersonate groups');
                })
            .finally(() => {
                getUserPermissions();
                getMenu();
                decrementSpinner();
            });
    }
}

ImpersonateAdminPageComponent.propTypes = {
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    getMenu: PropTypes.func,
    match: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    permissionsLoaded: state.configurationReducer.permissionsLoaded,
    hasSuperAdminAccess: state.configurationReducer.hasSuperAdminAccess,
    impersonateOn: state.configurationReducer.impersonateOn
});

const mapDispatchToProps = (dispatch) => ({
    getMenu: () => dispatch(navActions.getMenu()),
    getUserPermissions: () => dispatch(configurationActions.getPermissions())
});

const ImpersonateAdminPage = connect(mapStateToProps, mapDispatchToProps)(InfrastructureWrapper(withRouter(ImpersonateAdminPageComponent)));
export default ImpersonateAdminPage;