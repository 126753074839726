import React from 'react'
import './widget.css'

function Widget({ children, height, width }) {
    const containerStyle = { height: `${height}rem`, width: `${width}rem` };
    return (
    <>
            <div className="widget-container" style={containerStyle}>
                {children}
            
        </div>
    </>
    )
}

export default Widget;