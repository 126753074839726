import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navActions } from '../actions';

class ErrorModalComponent extends Component {
    render() {
        const { errorList, dismissError } = this.props;
        if (!errorList || errorList.length === 0) {
            return null;
        }

        const currentError = errorList[0];

        return (
            <Modal show={true} centered onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{currentError}</Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={dismissError}>Ok</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ErrorModalComponent.propTypes = {
    errorList: PropTypes.array,
    dismissError: PropTypes.func
};

const mapStateToProps = (state) => ({
    errorList: state.navReducer.errorList
});

const mapDispatchToProps = (dispatch) => ({
    dismissError: () => dispatch(navActions.dismissError())
});

const ErrorModal = connect(mapStateToProps, mapDispatchToProps)(ErrorModalComponent);
export default ErrorModal;