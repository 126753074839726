import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const lmsAdminService = {
    getDelinquencyList,
    getDelinquencyLastModified
};

function getDelinquencyList() {
    return getHeaders().then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/LmsAdmin/DelinquencyList`, null, {
            headers
        });
    });
}

function getDelinquencyLastModified() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/LmsAdmin/DelinquencyListLastUpdate`, {
            headers
        });
    });
}