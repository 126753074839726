import React from 'react'
import './widget.css'
import WidgetIcon from './WidgetIcon'

function WidgetContent({ data }) {

    const content = data.sort((a, b) => {
        const date1 = new Date(a.startDate)
        const date2 = new Date(b.startDate)
        return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
    }).map((item) => {
        const splitStartDateAndTime = item.startDate.split("T")
        const splitToReorderDate = splitStartDateAndTime[0]
        const splitReorderedDate = splitToReorderDate.split("-")
        const reorderDate = [splitReorderedDate[1], splitReorderedDate[2], splitReorderedDate[0]]
        const startDate = reorderDate.join("/")
        const startTime = splitStartDateAndTime[1]

        const splitEndDateAndTime = item.endDate.split("T")
        const splitToReorderEndDate = splitEndDateAndTime[0]
        const splitReorderedEndDate = splitToReorderEndDate.split("-")
        const reorderEndDate = [splitReorderedEndDate[1], splitReorderedEndDate[2], splitReorderedEndDate[0]]
        const endDate = reorderEndDate.join("/")

        return (
            <div key={Math.random() * 100} className={item.enrollmentStatus === 'ENROLL' ? 'widget-item-container' : 'widget-item-container widget-item-container-red'} style={{ marginBottom: '6px', borderBottom: '1px solid #d4d4d4' }}>
                {item.classTitle ? <h3 className="widget-main-content">{item.classTitle} - {item.studentFname}&nbsp;{item.studentLname}</h3> : null}
                {item.name ? <h3 className="widget-main-content">{item.name}</h3> : null}
                {item.techId ? <span className='widget-secondary-content'>Tech ID: &nbsp;{item.techId}</span> : null}
                {item.enrollmentStatus ? <span className='widget-details'>{item.enrollmentStatus}</span> : null}
                {item.location ? <span className='widget-details'>{item.location}</span> : null}
                {item.startDate ? <time className='widget-details' dateTime={startTime} >{`Begins at ${startTime.slice(0, 5)} ${item.timezone}`}</time> : null}
                {item.startDate ? <time className='widget-details' >{startDate} - {endDate}</time> : null}
                {item.startDate ? <WidgetIcon date={item.startDate} /> : null}

            </div>
        )
    })

    return (
        <>
            {content.length > 0 ? content : <h4 className="no-classes-message">No classes scheduled</h4>}
        </>
    )
}

export default WidgetContent;
