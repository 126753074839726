import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfrastructureWrapper } from '../../components';
import { intelligenceService } from '../../services';

class WaitlistPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        intelligenceService.getWaitlist()
            .then(
                response => {
                    const items = response.data;
                    this.setState({ items });
                },
                () => {
                    showError('Error loading waitlist report');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { items } = this.state;

        return (
            <Row>
                <Col md={12}>
                    <Row>
                        <Col sm={6}>
                            <h4>
                                Waitlist Report
                            </h4>
                        </Col>
                    </Row>
                    <Row><br /></Row>
                    {items.map((item) => {
                        const { componentId, courseTitle, classes } = item;
                        return (
                            <Row key={componentId}>
                                <Col sm={12}>
                                    <h5>{courseTitle}</h5>
                                    <h6 style={{ color: 'gray' }}>{componentId}</h6>
                                </Col>
                                {classes.map((courseClass) => {
                                    const { displayName, scheduleId, instructor, totalWaitlist } = courseClass;
                                    return (
                                        <Col sm={3} key={scheduleId}>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th>{displayName}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Class Id: {scheduleId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Instructor: {instructor}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Waitlist: {totalWaitlist}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    )
                                })}
                            </Row>
                        );
                    })}
                </Col>
            </Row>
        );
    }
}

WaitlistPageComponent.propTypes = {
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func,
    showPrompt: PropTypes.func
};

const WaitlistPage = InfrastructureWrapper(withRouter(WaitlistPageComponent));
export default WaitlistPage;