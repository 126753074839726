import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { CompactPagedList } from './list';
import InfrastructureWrapper from './InfrastructureWrapper';
import debounce from 'debounce';

class MiniSearchListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            totalRecords: 0,
            pageIndex: 0,
            pageSize: 5,
            query: ''
        };

        this.debouncedGetList = debounce(this.getList, 500);
    }

    componentDidMount() {
        const { pageIndex, pageSize, query } = this.state;
        this.getList(pageIndex, pageSize, query);
    }

    componentDidUpdate(prevProps, prevState) {
        const { itemListFunc } = this.props;
        const { pageIndex, pageSize, query } = this.state;

        if (itemListFunc && (pageIndex !== prevState.pageIndex || pageSize !== prevState.pageSize ||
            query !== prevState.query)) {
            this.debouncedGetList(pageIndex, pageSize, query);
        }
    }

    getList = (pageIndex, pageSize, query) => {
        const { incrementSpinner, decrementSpinner, showError, itemListFunc } = this.props;

        if (itemListFunc) {
            incrementSpinner();
            itemListFunc(pageIndex, pageSize, query)
                .then(
                    response => {
                        const results = response.data;
                        const { items, totalRecords } = results;
                        this.setState({ items, totalRecords });
                    },
                    () => {
                        showError('Error loading list');
                    })
                .catch((error) => { console.log(error) })
                .finally(() => {
                    decrementSpinner();
                    this.debouncedGetList.flush();
                });
        }
    }

    render() {
        const { pageIndex, pageSize, query, items, totalRecords } = this.state;
        const { valueLookupHideId, valueLookupIdName, valueLookupDisplayName, valueLookupDisplayFunc, onSelectValue,
            onSelectItem, onRemoveValue } = this.props;

        const isItemSelectableFunc = this.props.isItemSelectableFunc || (() => { return true });

        let columns = [];

        if (!this.props.columns || this.props.columns.length === 0) {
            if (!valueLookupHideId) {
                columns.push({ fieldName: valueLookupIdName, label: 'Id' });
            }

            if (!!valueLookupDisplayFunc) {
                columns.push({
                    label: 'Name', formatFunc: (item) => {
                        return valueLookupDisplayFunc(item);
                    }
                });
            } else {
                columns.push({ fieldName: valueLookupDisplayName, label: 'Name' });
            }
        }
        else {
            columns = [...this.props.columns];
        }

        columns.push({
            label: '', formatFunc: (item) => {
                if (isItemSelectableFunc(item) || !onRemoveValue) {
                    return (
                        <Button disabled={!isItemSelectableFunc(item)} onClick={() => {
                            if (onSelectValue) {
                                onSelectValue(item[valueLookupIdName]);
                            }
                            if (onSelectItem) {
                                onSelectItem(item);
                            }
                        }}>Select</Button>
                    );
                } else if (!isItemSelectableFunc(item) && !!onRemoveValue) {
                    return (
                        <Button onClick={() => {
                            if (onRemoveValue) {
                                onRemoveValue(item[valueLookupIdName]);
                            }
                        }}>Remove</Button>
                    );
                }
            }
        });

        return (
            <>
                <CompactPagedList
                    query={query}
                    queryPlaceholder='Search'
                    onSearchClick={this.handleSearchClicked}
                    columns={columns}
                    items={items}
                    totalRecords={totalRecords}
                    pageIndex={pageIndex + 1}
                    pageSize={pageSize}
                    onNavigateToPageNumber={this.handleNavigateToPageNumber}
                />
            </>
        );
    }

    handleNavigateToPageNumber = (pageIndex) => {
        this.setState({ pageIndex: pageIndex - 1 });
    }

    handleSearchClicked = (query) => {
        this.setState({ query, pageIndex: 0 });
    }
}

const MiniSearchList = InfrastructureWrapper(MiniSearchListComponent);
export default MiniSearchList;