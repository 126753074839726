import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListButton from './ListButton';

const ListEditButtonComponent = ({ history, to, title, onClick }) => {
    return (
        <ListButton
            to={to}
            title={title}
            onClick={onClick}
        >
            <i className='fas fa-pencil-alt'></i>
        </ListButton>
    );
};

ListEditButtonComponent.propTypes = {
    history: PropTypes.object,
    to: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func
};

const ListEditButton = withRouter(ListEditButtonComponent);
export default ListEditButton;