import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CountryListPage, CountryImportPage } from './';

class CountryPageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}`} component={CountryListPage} />
                <Route exact path={`${url}/import`} component={CountryImportPage} />
            </>
        );
    }
}

CountryPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const CountryPage = withRouter(CountryPageComponent);
export default CountryPage;