import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Card, Carousel, Col, ProgressBar, Row, Table } from 'react-bootstrap';
import { InfrastructureWrapper } from '../../components';
import { userService } from '../../services';
import { Accordion, Collapse, Button } from 'react-bootstrap';
import CertificationBadge from '../../components/CertificationBadge';
import CustomToggleButton from '../../components/CustomToggleButton';
import CertificationProgressBadge from '../../components/CertificationProgressBadge';

import { Calendar, globalizeLocalizer, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import "react-big-calendar/lib/css/react-big-calendar.css";


class EnrollClassPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            enrollEnabled: true,
            withdrawEnabled: false,
            cancelled:false,
            certifications: [],
            certificationsIP: [],
            toggleButtonValue: '1', // Initialize toggleButtonValue
            selectedClass: null,
            currentClasses: [],
            OtherClassesOfSameType: [],
            classID: null,
            itemID: null,
            events: [],
            currentEvents: [],
            enrollPayload: null
        };
    }





    handleSuccessFactorsClick = () => {
        window.location = 'https://mercuryuniversity.com';
    }





    componentDidMount() {
        this.getCertificationStatuses();
        this.getCertificationProgressStatuses();
        const searchParams = new URLSearchParams(this.props.location.search);
        const classID = searchParams.get('class');
        const itemID = searchParams.get('item');
        // Set the classID in the component's state for usage
        if (classID) {
            this.setState({ classID });
        }
        if (itemID) {
            this.setState({ itemID });
            // You can perform further actions or API calls using this classID
        }
        this.getEnrolLPagePayload(classID, itemID);
    }




    getCertificationStatuses = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        incrementSpinner();
        userService.getProfileCertifications()
            .then(
                response => {
                    const certifications = response.data;
                    this.setState({ certifications });
                },
                () => {
                    showError('Error loading certification status');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    getCertificationProgressStatuses = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        incrementSpinner();
        userService.getProfileCertificationsInProgress()
            .then(
                response => {
                    const certificationsIP = response.data;
                    this.setState({ certificationsIP });
                },
                () => {
                    showError('Error loading certification status');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }




    // ==============================================
    // ************ ATTEMPT ENROLLMENT **************
    // ==============================================

    handleWithdrawClick = (classID) => {
        userService.attemptWithdraw(classID)
            .then(response => {
                // Handle the response here
                window.alert(`Withdraw attempt response:    ${response.data}`);
            })
            .catch(error => {
                // Handle errors in the enrollment attempt
                window.alert('Error during withdraw attempt.');
                console.error('Error:', error);
            });
    }




    handleButtonClick = (classID) => {
        userService.attemptEnrollment(classID)
            .then(response => {
                // Handle the response here
                window.alert(`Enrollment attempt response:    ${response.data}`);
            })
            .catch(error => {
                // Handle errors in the enrollment attempt
                window.alert('Error during enrollment attempt.');
                console.error('Error:', error);
            });
    }


    // ==============================================
    // ************ SELECT CLASS ********************
    // ==============================================

    selectClass(event) {
        const { history } = this.props;
        history.push(`/classes/enroll?class=${event.classID}&item=${event.itemID}`);
    }


    getEnrolLPagePayload = (classID, itemID) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        this.setState({ isLoading: true })
        incrementSpinner();
        userService.getEnrollPagePayload(classID, itemID)
            .then(
                response => {
                    const ePayload = response.data;
                    // Assuming ePayload contains SelectedClass, currentClasses, and OtherClassesOfSameType properties
                    const { selectedClass, currentClasses, otherClassesOfSameType } = ePayload;


                    // Update the component state with the retrieved data
                    this.setState({ selectedClass, currentClasses, otherClassesOfSameType, enrollPayload: ePayload });

                    const allClasses = [...otherClassesOfSameType];


                    if (allClasses && allClasses.length > 0) {
                        const events = allClasses.map(classInfo => ({
                            start: new Date(classInfo.startDate),
                            end: new Date(classInfo.endDate),
                            title: classInfo.className,
                            itemID: classInfo.class_id,
                            classID: classInfo.scheduleID,
                            enrollmentStatus: classInfo.enrollmentStatus
                        }));
                        const currentEvents = currentClasses.map(classInfo => ({
                            start: new Date(classInfo.startDate),
                            end: new Date(classInfo.endDate),
                            title: classInfo.className,
                            itemID: classInfo.class_id,
                            classID: classInfo.scheduleID,
                            enrollmentStatus: classInfo.enrollmentStatus
                        }));
                        this.setState({ events: [...events, ...currentEvents], currentEvents: [...currentEvents] });


                        currentEvents.map((item) => {

                            if (item.itemID === itemID) {
                                this.setState({ enrollEnabled: false, withdrawEnabled: true });
                                if (item.enrollmentStatus === "CANCELLED") { this.setState({ cancelled:true}); }
                                
                            } 
                        })


                    } else {
                        console.log('CurrentClasses and OtherClassesOfSameType are null or empty');
                        this.setState({ events: [] }); // Set events to an empty array or handle it as needed
                    }

                },
                error => {
                    showError('Error loading enrollment payload');
                    console.error('Error:', error);
                }
            ).finally(() => {
                this.setState({ isLoading: false })
                decrementSpinner();
            });
    }






    render() {
        const { certifications, certificationsIP, classID, itemID, selectedClass, isLoading, currentEvents, enrollEnabled, withdrawEnabled, events } = this.state;
        const { hasInternalAdminAccess, hasRegionalAdminAccess } = this.props;


        const localizer = momentLocalizer(moment);

        console.log(currentEvents)
        console.log(this.state.events)

        return (
            <div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {isLoading ? <h5>Loading...</h5> :
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <legend>Course Enrollment</legend>
                            <h5>{selectedClass?.className}</h5>
                            <h6 style={{ color: 'gray' }}>{classID}</h6>
                            <h6>{selectedClass?.totalSeats - selectedClass?.currentSeatCount > 0 ? selectedClass?.totalSeats - selectedClass?.currentSeatCount : 0} {selectedClass?.totalSeats - selectedClass?.currentSeatCount === 1 ? 'seat' : 'seats'} available</h6>
                            <Row>
                                <Col lg={8}>

                                    {enrollEnabled && !isLoading ? <Button variant="primary" onClick={() => this.handleButtonClick(classID)}>
                                        {selectedClass?.totalSeats - selectedClass?.currentSeatCount > 0 ? 'Enroll' : 'Join waitlist'}
                                    </Button>
                                        : <>
                                            <Button variant="secondary" disabled onClick={() => this.handleButtonClick(classID)}>
                                                {selectedClass?.totalSeats - selectedClass?.currentSeatCount >= 0 ? 'Enroll' : 'Join waitlist'}
                                            </Button>
                                            <Button style={{ marginLeft: '10px' }} variant="danger" onClick={ () => this.handleWithdrawClick(classID)} >
                                                Withdraw
                                            </Button>
                                        </>}

                                </Col>
                            </Row>
                            <div>
                                {selectedClass?.address ? <span>Instructor: {selectedClass?.address}</span> : null}
                                {selectedClass?.city ? <span>Instructor: {selectedClass?.city}</span> : null}
                                {selectedClass?.instructor ? <span>Instructor: {selectedClass?.instructor}</span> : null}
                            </div>
                        </div>
                    }

                    <Row>
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            <div >
                                <p style={{ fontSize: '15px', fontWeight: '500' }}> Other dates for this class</p>
                                <Calendar
                                    localizer={localizer}
                                    defaultDate={new Date()}
                                    views={["month", "day", "agenda"]}
                                    defaultView="month"
                                    events={this.state.events}
                                    eventPropGetter={event => ({
                                        style: {
                                            backgroundColor: event.enrollmentStatus === 'ENROLL' ? ('#49C45A')
                                                : event.enrollmentStatus === 'PENDING' ? ('#da1c2e')
                                                    : event.enrollmentStatus === null ? ('#3174ad')
                                                        : ('#3174ad'),
                                            display: event.enrollmentStatus === ('CANCEL_NO_CHG' || 'CANCELLED' || 'CANCEL_CHG') ? ('none') : ('block')
                                        }
                                    })}
                                    style={{ height: "60vh", width: "40vw" }}
                                    onSelectEvent={event => this.selectClass(event)}
                                //handleSelectEvent={event => alert(event.title)}
                                />
                            </div>
                            <div>
                                <div style={{ display: 'flex', gap: '20px', marginTop: '10px', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                        <div style={{ height: '15px', width: '15px', backgroundColor: '#3174ad' }}></div>
                                        <span>Other dates for this class</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                        <div style={{ height: '15px', width: '15px', backgroundColor: '#49C45A' }}></div>
                                        <span>Currently enrolled</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                        <div style={{ height: '15px', width: '15px', backgroundColor: '#da1c2e' }}></div>
                                        <span>Pending supervisor approval</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                    <Col lg={8}>
                        <Table striped bordered hover >
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                </tr>
                                <tr>
                                    <td>{selectedClass && selectedClass.description}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </div>
            </div>
        );
    }
}

const EnrollClassPage = withRouter(InfrastructureWrapper(EnrollClassPageComponent));
export default EnrollClassPage;