import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FilterField from './FilterField';

const FilterSelect = ({ fieldName, fieldValue, label, onChange, options }) => {
    return (
        <FilterField
            fieldName={fieldName}
            label={label}
        >
            <Form.Control
                as='select'
                value={fieldValue || ''}
                onChange={(event) => { onChange(fieldName, event.target.value); }}
            >
                {options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
            </Form.Control>
        </FilterField >
    );
};

FilterSelect.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array
};

export default FilterSelect;