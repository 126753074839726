import React, { Component } from 'react';
import { Form, InputGroup, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class ListFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: ''
        };
    }

    componentDidMount() {
        const { query } = this.props;
        this.setState({ query });
    }

    componentDidUpdate(prevProps) {
        const { query } = this.props;
        if (query !== prevProps.query) {
            this.setState({ query });
        }
    }

    render() {
        const { placeholder, children } = this.props;
        const { query } = this.state;

        return (
            <Form.Group>
                <InputGroup className='mb-3'>
                    <input
                        className='form-control'
                        type='search'
                        placeholder={placeholder}
                        value={query}
                        onChange={this.handleQueryChanged}
                        onKeyPress={this.handleKeyPress}
                        autoFocus
                    />
                    <Dropdown
                        as={ButtonGroup}
                        className='input-group-append'
                        alignRight={true}
                    >
                        {children ? (
                            <Dropdown.Toggle
                                split
                                variant="secondary"
                                style={{ borderRadius: 0, borderRightColor: '#ced4da' }}
                            />
                        ) : null}
                        <Button
                            variant='secondary'
                            onClick={this.handleSearchClick}
                            style={{ borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem', marginLeft: '0' }}
                        >
                            <i className='fas fa-search'></i>
                        </Button>

                        <Dropdown.Menu style={{ width: '45vh', padding: '1rem' }}>
                            {children}
                        </Dropdown.Menu>
                    </Dropdown>
                </InputGroup>
            </Form.Group>
        );
    }

    handleQueryChanged = (event) => {
        this.setState({ query: event.target.value });

        // If there is no input type on nativeEvent it's coming from a
        // the clear search button
        if (!event.nativeEvent.inputType) {
            const { onSearchClick } = this.props;
            onSearchClick('');
        }
    }

    handleKeyPress = (event) => {
        const { onSearchClick } = this.props;
        const { query } = this.state;
        if (event.charCode === 13 && onSearchClick) {
            onSearchClick(query);
        }
    }

    handleSearchClick = () => {
        const { onSearchClick } = this.props;
        const { query } = this.state;
        if (onSearchClick) {
            onSearchClick(query);
        }
    }
}

ListFilters.propTypes = {
    query: PropTypes.string,
    placeholder: PropTypes.string,
    onSearchClick: PropTypes.func,
    children: PropTypes.any
};

export default ListFilters;