import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Card, Carousel, Col, ProgressBar, Row } from 'react-bootstrap';
import { InfrastructureWrapper } from '../components';
import { userService } from '../services';
import { Accordion, Collapse, Button } from 'react-bootstrap';
import CertificationBadge from '../components/CertificationBadge';
import CertificationProgressBadge from '../components/CertificationProgressBadge';


class HomePageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            certifications: [],
            certificationsIP: []
        };


    }

    handleSuccessFactorsClick = () => {
        window.location = 'https://mercuryuniversity.com';
    }

    componentDidMount() {
        this.getCertificationStatuses();
        this.getCertificationProgressStatuses();
    }

    getCertificationStatuses = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        incrementSpinner();
        userService.getProfileCertifications()
            .then(
                response => {
                    const certifications = response.data;
                    this.setState({ certifications });
                },
                () => {
                    showError('Error loading certification status');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    getCertificationProgressStatuses = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        incrementSpinner();
        userService.getProfileCertificationsInProgress()
            .then(
                response => {
                    const certificationsIP = response.data;
                    this.setState({ certificationsIP });
                },
                () => {
                    showError('Error loading certification status');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { certifications, certificationsIP } = this.state;
        const { hasInternalAdminAccess, hasRegionalAdminAccess } = this.props;

        return (
            <div>

                <Row style={{ marginBottom: '60px' }}>


            <Col xl={4} lg={6} >
                    <Row style = {{marginBottom: '10px'}}>
                        <Col xl="auto" lg={12}>
                            <a href="https://catalog.mercuryuniversity.com/CourseCatalog/#/" >
                                    <img style={{ width: '200px', border: '4px solid #969696' }} src="images/2022CourseCatalogLogo.png" />
                            </a> 
                        </Col>
                        <Col xl="auto" lg={12}>
                            <a href="https://vimeo.com/showcase/7335150" >
                                    <img style={{ width: '200px', border: '4px solid #969696' }} src="images/2022TechTipLogo.png" />
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="auto" lg={12}>
                            <a href="https://soundcloud.com/user-531240210" >
                                    <img style={{ width: '200px', border: '4px solid #969696' }} src="images/TechTalkPodcast2022.png"  />
                            </a>
                        </Col>
                        <Col xl="auto" lg={12}>
                            <a href="/users/profile" >
                                    <img style={{ width: '200px', border: '4px solid #969696' }} src="images/UserProfileLogo.png"  />
                            </a>
                        </Col>
        
        
                    </Row>
                </Col>
        
                <Col xl={4} lg={6} style = {{width: '500px'}} >
                    <Card >
                        <a href='https://mercuryuniversity.com'>
                        <Card.Header style={{textAlign: 'center'}}>Merc U  </Card.Header>
                        <Card.Img src='/images/MU_Lockup_Horz_Clr.png' />
                        </a>
                    </Card>
                </Col>

                    <Col xl={4} lg={6}  >

                        <Accordion style={{ width: '30em' }}>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Certifications
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        {certifications.map((item) => {
                                            let category = '';
                                            let master = false;
                                            let newlyCert = false;
                                            let ignoreInitial = false;
                                            if (item.name.indexOf('Outboard') >= 0) {
                                                category = 'Outboard';
                                            }

                                            if (item.name.indexOf('Mercruiser') >= 0) {
                                                category = 'Mercruiser';
                                            }

                                            if (item.name.indexOf('Marine Systems Technician') >= 0) {
                                                category = 'MST';
                                            }


                                            certifications.map((item2) => { // check for master
                                                if (item2.name.indexOf('Master') >= 0 && item2.name.indexOf(item.name) >= 0) {
                                                    master = true;
                                                }
                                            })

                                            if (item.curriculumId.indexOf('Initial') > 0) {
                                                newlyCert = true;
                                                certifications.map((item3) => { // check if the user has a cert as well as initial

                                                    if (item.name.indexOf(item3.name) >= 0 && item3.curriculumId.indexOf('Initial') == -1) {
                                                        newlyCert = false; // found a child id
                                                    }
                                                })
                                            }



                                            if (!!category) {
                                                return (
                                                    <CertificationBadge curriculumId={item.curriculumId} category={category} key={item.curriculumId} isMaster={master} cName={item.name} newlyCertified={newlyCert} />
                                                );
                                            }

                                            return null;
                                        })}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        In Progress
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <CertificationProgressBadge curriculumId='Outboard_Initial_Certification' category='Outboard' isMaster={false} name='Outboard Certification' />
                                        <CertificationProgressBadge curriculumId='Mercruiser_Initial_Certificati' category='Mercruiser' isMaster={false} name='Mercruiser Certification' />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>







                        



                    </Col>


                </Row >



                <Row>

                    <Col xl={4} lg={12}  >
                        <iframe src='https://player.vimeo.com/video/673719196?h=a7b4840f53'
                            frameBorder='1'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            title='video'
                            width='540'
                            height='480'
                        />

                    </Col>



                    <Col xl={8} lg={12} style={{ paddingLeft: "200px" }} >
                        <img src="/images/MU_NEWS.png" height="200" width="450" position="absolute" top="5" />
                        <p style={{ fontstyle: 'Soleto', fontSize: '2.2em', paddingLeft: "32px" }}>Welcome to the Learning Dock!</p>
                    </Col>





                </Row>
            </div>
        );
    }
}

const HomePage = withRouter(InfrastructureWrapper(HomePageComponent));
export default HomePage;