import React, { Component } from 'react';
import { Row, Col, Button, Table, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { InfrastructureWrapper, AddButton, ListDeleteButton } from '../../components';
import { userService } from '../../services';

class UserRoleListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            userRoleDetails: {}
        };
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (match.params.id && match.params.id !== prevProps.match.params.id) {
            this.loadModel();
        }
    }

    componentDidMount() {
        this.loadModel();
    }

    loadModel = () => {
        const { match, incrementSpinner, decrementSpinner, showError } = this.props;
        const { params } = match;

        if (params.id) {
            incrementSpinner();
            userService.getAssignedRoles(params.id)
                .then(
                    response => {
                        const items = response.data;
                        this.setState({ items });
                    },
                    () => {
                        showError('Error loading model');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
            incrementSpinner();
            userService.getAssignedRoleDetails(params.id)
                .then(
                    response => {
                        const userRoleDetails = response.data;
                        this.setState({ userRoleDetails });
                    },
                    () => {
                        showError('Error loading model');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }
    }

    render() {
        const { items, userRoleDetails } = this.state;
        const { match } = this.props;
        const { url } = match;
        const { hasFullAdminGroup } = userRoleDetails;

        return (
            <>
                {hasFullAdminGroup ? (
                    <Row>
                        <Col md={6}>
                            <br />
                            <Alert variant='info'>
                                This user is a full admin so adding role organization/domains is redundant
                            </Alert>
                        </Col>
                    </Row>
                ) : null}
                <Row>
                    <Col md={12}>
                        <h4>
                            Roles
                            <AddButton title='Add Role' to={`${url}/role/add`} />
                        </h4>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Control Organization</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.filter(item => item.roleId != 1).map((item, index) => {
                                    const { userId, roleId, roleDescription, organizationId, organizationName,
                                        systemDomainId, systemDomainName } = item;
                                    return (
                                        <tr key={index}>
                                            <td>{roleDescription}</td>
                                            <td>
                                                {organizationId ? `${organizationName} (${organizationId})` : ''}
                                                {systemDomainId ? `${systemDomainName} (${systemDomainId})` : ''}
                                            </td>
                                            <td>
                                                <ListDeleteButton
                                                    title='Delete'
                                                    onClick={() => this.handleDeleteClick(userId, roleId, organizationId, systemDomainId)} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        );
    }

    handleDeleteClick = (userId, roleId, organizationId, systemDomainId) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        userService.removeAssignedRole(userId, roleId, organizationId, systemDomainId)
            .then(() => {
                this.loadModel();
            }, () => {
                showError('Error removing role');
            })
            .finally(() => {
                decrementSpinner();
            });
    }
}


export default InfrastructureWrapper(withRouter(UserRoleListComponent));