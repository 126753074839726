import axios from 'axios';
import { apiHostUrl } from "./ajaxHelpers";
import { storageHelper } from "./storageHelper";
//import { B2CAuthProvider, ADAuthProvider } from 'msal.js-helpers';
import { B2CAuthProvider, ADAuthProvider } from './authProviderBase'

class UserAuthProvider extends B2CAuthProvider {
    constructor(config) {
        super(config);
    }

    signOut() {
        return this.getHeaders({}).then((headers) => {
            return axios.delete(`${apiHostUrl}/api/v1/auth/signout`, {
                headers
            }).then(() => {
                return super.signOut();
            });
        });
    }

    reAuthenticate = () => {
        return this.msalApplication.acquireTokenPopup(this.silentTokenRefreshRequest).catch((error) => {
            //console.log("Acquire token popup failed", error);
            this.signOut();
        });
    }
}

export let authProvider = null;
let config = null;
if (storageHelper.isAdminMode() || !window.b2cEnabled) {
    config = {
        ...window.authConfig,
        cacheLocation: 'localStorage',
        redirectUri: window.location.origin,
        silentRedirectUri: window.location.origin + '/auth_redirect.html',
        validateAuthority: true,
        scopes: [
            'email',
            'profile',
            'User.Read'
        ]
    }

    authProvider = new ADAuthProvider(config);
}
else {
    config = {
        ...window.b2cConfig,
        cacheLocation: 'localStorage',
        redirectUri: window.location.origin,
        silentRedirectUri: window.location.origin + '/auth_redirect.html',
        validateAuthority: false,
        scopes: [
            ...window.b2cConfig.scopes,
            'openid', 
            'profile', 
            'email'
        ]
    }

    authProvider = new UserAuthProvider(config);
}