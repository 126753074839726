import React from 'react';
import './App.css';
import { InfrastructureWrapper } from './components';

const App = ({ hasSupervisorAccess }) => {
  return (
    <div className='container'>
      <div className='row'>
      </div>
    </div>
  );
}

export default InfrastructureWrapper(App);
