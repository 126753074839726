import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { InfrastructureWrapper, FormSelect, FormButtonOk, FormTextInput } from '../../components';
import { userService } from '../../services';
import { toSelectOptions } from '../../helpers';
import BootstrapTable from 'react-bootstrap-table-next';

class UserCertComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userCerts: [],
            certs: [],
            model: {},
        };
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (match.params.id && match.params.id !== prevProps.match.params.id) {
            this.loadModel();
        }
    }

    componentDidMount() {
        this.loadModel();
    }

    loadModel = () => {
        const { match, incrementSpinner, decrementSpinner, showError } = this.props;
        const { params } = match;

        if (params.id) {
            incrementSpinner();
            userService.getCertifications(params.id)
                .then(
                    response => {
                        const certs = response.data.userCerts;
                        this.setState({ userCerts: response.data.userCerts, certs: response.data.certs });
                    },
                    () => {
                        showError('Error loading Certifications');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }
    }

    generateSelectOptions = () => {
        const { certs } = this.state;

        const result = {};
        result.certOptions = toSelectOptions(certs, {
            showDefault: true,
            defaultLabel: '-- Select Cert --',
            valueFieldName: 'id',
            labelFieldName: 'curriculumId'
        });
        return result;
    }

    render() {
        const { userCerts, model } = this.state;
        const { certOptions } = this.generateSelectOptions();
        const saveButtonEnabled = this.isSaveButtonEnabled();
        const { certId, notes } = model;  
        const { hasInternalAdminAccess } = this.props;

        const columns = [{
            dataField: 'name',
            text: 'Certificate',
            sort: true
        }, {
            dataField: 'notes',
            text: 'Notes',
            sort: true
        }];

        const defaultSorted = [{
            dataField: 'name',
            order: 'desc'
        }];

        const showInputs = hasInternalAdminAccess ? '' : 'd-none';

        return (
            <><Row>
                <Col md={12} lg={12}>
                    <BootstrapTable
                        bootstrap4
                        striped
                        hover
                        condensed
                        keyField='id'
                        data={userCerts}
                        columns={columns}
                        defaultSorted={defaultSorted} />
                </Col>
            </Row>
                <Row className={showInputs}>
                    <Col>
                        <FormSelect
                            fieldName='certId'
                            fieldValue={certId}
                            label='Certifications'
                            onChange={this.handleValueChanged}
                            options={certOptions}
                        />
                    </Col>
                </Row>
                <Row className={showInputs}>
                    <Col>
                        <FormTextInput
                            fieldName='notes'
                            fieldValue={notes}
                            label='Notes'
                            onChange={this.handleValueChanged}
                             />
                    </Col>
                </Row>
                <Row className={showInputs}>
                    <Col md={6}>
                        <FormButtonOk
                            title="Save"
                            onClick={this.handleSaveClick}
                            disabled={!saveButtonEnabled}>
                            <i className="fas fa-check"></i> Save
                        </FormButtonOk>
                    </Col>
                    <Col md={6}>
                        <FormButtonOk
                            title="Check and Insert"
                            onClick={this.handleCheckInsertClick}>
                            <i className="fa fa-graduation-cap"></i> Check and Insert
                        </FormButtonOk>
                    </Col>
                </Row>
            </>

        );
    }


    isSaveButtonEnabled = () => {
        const { model } = this.state;
        const { certId } = model;

        if (!certId) {
            return false;
        }

        return true;
    }

    handleValueChanged = (fieldName, value) => {
        const model = { ...this.state.model };

        model[fieldName] = value;

        this.setState({ model });  
    }

    handleCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleSaveClick = () => {
        const { model } = this.state;
        const { incrementSpinner, decrementSpinner, showError, match } = this.props;
        const { params } = match;
        
        if (!this.isSaveButtonEnabled()) {
            return;
        }
        incrementSpinner();
        
        userService.addVariance(params.id, model)
            .then(() => {
                this.loadModel();
            }, (error) => {
                if (error.response.status === 400) {
                    showError('LMS Error saving profile: ' + error.response.data);
                }
                else {
                    showError('Error saving profile');
                }
            })
            .finally(() => {
                decrementSpinner();
            });
    }

    handleCheckInsertClick = () => {
        const { model } = this.state;
        const { incrementSpinner, decrementSpinner, showError, match } = this.props;
        const { params } = match;

        incrementSpinner();

        userService.checkInsertCertification(params.id)
            .then(() => {
                this.loadModel();
            }, (error) => {
                if (error.response.status === 400) {
                    showError('LMS Error Checking and Inserting Certifications: ' + error.response.data);
                }
                else {
                    showError('Error Checking and Inserting Certifications');
                }
            })
            .finally(() => {
                decrementSpinner();
            });
    }
}




export default InfrastructureWrapper(withRouter(UserCertComponent));