import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'react-bootstrap';
import AddEditListItem from './AddEditListItem';

class AddEditListComponent extends Component {
    render() {
        const { listTitle, columns, items, onSaveClicked, onAddClick, onDeleteClick, rowKeyColumnName } = this.props;

        return (
            <>
                <h4>{listTitle} <Button size='sm' variant='outline-dark' onClick={onAddClick}><i className='fas fa-plus'></i> Add</Button></h4>
                <Table>
                    <thead>
                        <tr>
                            {columns.map((column, index) => {
                                const { className, label, labelRender, hidden } = column;
                                if (hidden) {
                                    return null;
                                }

                                return (
                                    <th
                                        key={index}
                                        className={className || ''}
                                    >
                                        {labelRender ? labelRender() : label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {items.length === 0 ? (<tr><td className='table-no-results' colSpan={columns.length}>No Results</td></tr>) : null}
                        {items.map((item) => {

                            return (
                                <AddEditListItem
                                    key={item[rowKeyColumnName]}
                                    columns={columns}
                                    item={item}
                                    onSaveClicked={onSaveClicked}
                                    onDeleteClick={onDeleteClick}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            </>
        );
    }
}

AddEditListComponent.propTypes = {
    listTitle: PropTypes.string,
    columns: PropTypes.array,
    items: PropTypes.array,
    onSaveClicked: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onAddClick: PropTypes.func,
    rowKeyColumnName: PropTypes.string
};

const AddEditList = AddEditListComponent;
export default AddEditList;