import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChartComponent = ({ data, onChartClick }) => {
    if (!data || !data.labels || !data.values) {
        // Handle the case where data is missing or incomplete
        return null; // or render an error message or a loading indicator
    } else {

        const nonNegativeSecondBarValues = (data.secondBarValues || []).map(value => Math.max(value, 0));

        const chartData = {
            labels: data.labels,
            datasets: [
                {
                    label: 'Complete',
                    backgroundColor: 'blue',
                    data: data.values,
                },
                {
                    label: 'Incomplete',
                    backgroundColor: 'red',
                    data: nonNegativeSecondBarValues || [], 
                },
            ],
        };

        const options = {
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
            onClick: (event, elements) => {
                if (elements && elements.length > 0) {
                    const clickedIndex = elements[0].index;

                    // Determine if the clicked bar is 'Complete' or 'Incomplete'
                    const datasetIndex = elements[0].datasetIndex;
                    const datasetLabel = chartData.datasets[datasetIndex].label;

                    const clickedLabel = data.labels && data.labels[clickedIndex];
                    if (clickedLabel) {
                        const isComplete = datasetLabel === 'Complete';

                        // Call the callback function in the parent component with the clicked label and isComplete
                        onChartClick(clickedLabel, isComplete);
                    }
                }
            },
        };



        const calculateChartSize = (data) => {
            const numberOfBars = data.labels.length; // or data.values.length
            const barWidth = 80; // Adjust this value based on your preference
            const spacingBetweenBars = 10; // Adjust this value based on your preference

            const width = numberOfBars * (barWidth + spacingBetweenBars);
            const height = 400; // Set your desired height

            return { width, height };
        };

        const { width, height } = calculateChartSize(chartData);

        return (
            <div style={{ width: `${width}px`, height: `${height}px` }}>
                <Bar data={chartData} options={options} />
            </div>
        );
    }
};

export default BarChartComponent;
