import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfrastructureWrapper } from '../components';
import { Alert } from 'react-bootstrap';
import { authProvider } from '../helpers';

class AuthErrorPageComponent extends Component {

    componentDidUpdate() {
        if (authProvider.hasLoaded() && !authProvider.isExpired()) {
            window.location.href = window.location.origin + '/';
        }
    }

    render() {
        const hash2Obj = window.location.hash
            .replace('#', '')
            .split("&")
            .map(v => v.split("="))
            .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} );

        return (
            <div>
                <div className='row'>
                    <div className='col-sm-8'>
                        <Alert variant='warning'>
                            Error authenticating, Error: {decodeURIComponent(hash2Obj.description)}
                        </Alert>
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}

AuthErrorPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const AuthErrorPage = withRouter(InfrastructureWrapper(AuthErrorPageComponent));
export default AuthErrorPage;