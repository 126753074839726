import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const configurationService = {
    getPermissions
};

function getPermissions() {
    return getHeaders({}, true).then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/configuration/permissions`, {
            headers
        });
    });
}