import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListDeleteButton, ListEditButton, InfrastructureWrapper } from '../../components';
import { jobCodeService } from '../../services';
import { PagedList } from '../../components/list';

const pageSizeDefault = 20;
const pageIndexDefault = 0;

class JobCodeListPageComponent extends Component {
    constructor(props) {
        super(props);

        const { pageIndex, pageSize, query } = this.getUrlParameters();
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.query = query;

        this.state = {
            items: [],
            totalRecords: 0
        };
    }

    componentDidUpdate() {
        const { pageIndex, pageSize, query  } = this.getUrlParameters();

        if (this.pageIndex != pageIndex || this.pageSize != pageSize || this.query != query) {
            this.pageIndex = pageIndex;
            this.pageSize = pageSize;
            this.query = query;

            this.getList(pageIndex, pageSize, query);
        }
    }

    componentDidMount() {
        const { pageIndex, pageSize, query } = this.getUrlParameters();
        this.getList(pageIndex, pageSize, query);
    }

    getUrlParameters = () => {
        const params = new URLSearchParams(window.location.search);
        return {
            pageIndex: +params.get('pageIndex') || pageIndexDefault,
            pageSize: +params.get('pageSize') || pageSizeDefault,
            query: params.get('query') || ''
        };
    }

    getList = (index, size, query) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        jobCodeService.getList(index, size, query)
            .then(
                response => {
                    const results = response.data;
                    const { items, totalRecords } = results;
                    this.setState({ items, totalRecords });
                },
                () => {
                    showError('Error loading jobCode list');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { items, totalRecords } = this.state;
        const { pageIndex, pageSize, query } = this.getUrlParameters();
        const { match } = this.props;
        const { url } = match;

        return (
            <Row>
                <Col md={12}>
                    <PagedList
                        pageTitle='Job Codes'
                        columns={[
                            { fieldName: 'id', label: 'Id' },
                            { fieldName: 'description', label: 'Description' }
                        ]}
                        items={items}
                        totalRecords={totalRecords}
                        pageIndex={pageIndex + 1}
                        pageSize={pageSize}
                        onNavigateToPageNumber={this.handleNavigateToPageNumber}
                        onPageSizeChanged={this.handlePageSizeChanged}
                        query={query}
                        onSearchClick={this.handleSearchClick}
                        queryPlaceholder='Search Id or Description'
                    />
                </Col>
            </Row>
        );
    }

    handleSearchClick = (query) => {
        this.handleFilterValueChanged('query', query);
    }

    handleNavigateToPageNumber = (pageIndex) => {
        this.handleFilterValueChanged('pageIndex', pageIndex - 1);
    }

    handlePageSizeChanged = (pageSize) => {
        this.handleFilterValueChanged('pageSize', pageSize);
    }

    handleFilterValueChanged = (fieldName, value, event) => {
        const { match } = this.props;
        const { url } = match;

        if (event) {
            event.stopPropagation();
        }
        const { history } = this.props;
        const params = new URLSearchParams(window.location.search);

        params.set(fieldName, value);
        if (fieldName === 'pageSize' || fieldName === 'query') {
            params.delete('pageIndex');
        }

        if (value === '' || value == 0 ||
            (fieldName === 'pageIndex' && value == pageIndexDefault) ||
            (fieldName === 'pageSize' && value == pageSizeDefault)) {
            params.delete(fieldName);
        }

        history.push(`${url}?${params.toString()}`);
    }

    handleDeleteClick = (id) => {
        const { showPrompt } = this.props;
        const { pageIndex, pageSize, query } = this.getUrlParameters();

        showPrompt('Delete Job Code?', 'Are you sure you want to delete this job code?', () => {
            const { incrementSpinner, decrementSpinner, showError } = this.props;

            incrementSpinner();
            jobCodeService.remove(id)
                .then(
                    () => {
                        this.getList(pageIndex, pageSize, query);
                    },
                    () => {
                        showError('Error deleting job code');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        });
    }
}

JobCodeListPageComponent.propTypes = {
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func,
    showPrompt: PropTypes.func
};

const JobCodeListPage = InfrastructureWrapper(withRouter(JobCodeListPageComponent));
export default JobCodeListPage;