import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './/FormButton.css';

const FormButtonCancel = ({ onClick, title, children, disabled }) => {
    return (
        <Button
            className='form-button'
            variant='secondary'
            title={title}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </Button>
    );
};

FormButtonCancel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default FormButtonCancel;