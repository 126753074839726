import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FilterField = ({ fieldName, label, children }) => {
    return (
        <>
            <Form.Group controlId={fieldName}>
                <Form.Label>{label}</Form.Label>
                {children}
            </Form.Group>
        </>
    );
};

FilterField.propTypes = {
    fieldName: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any
};

export default FilterField;