import { combineReducers } from 'redux';

import { navReducer } from './navReducer';
import { configurationReducer } from './configurationReducer';

const rootReducer = combineReducers({
    navReducer,
    configurationReducer
});

export default rootReducer;