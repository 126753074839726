import React from 'react'

function WidgetIcon({ date }) {

    const splitDateAndTime = date.split("T")
    const splitDate = splitDateAndTime[0].split("-")
    const year = splitDate[0]
    const monthValue = splitDate[1]
    const day = splitDate[2]
    let month;

    switch (monthValue) {
        case '01':
            month = 'Jan';
            break
        case '02':
            month = 'Feb';
            break
        case '03':
            month = 'Mar';
            break
        case '04':
            month = 'Apr';
            break
        case '05':
            month = 'May';
            break
        case '06':
            month = 'Jun';
            break
        case '07':
            month = 'Jul';
            break
        case '08':
            month = 'Aug';
            break
        case '09':
            month = 'Sep';
            break
        case '10':
            month = 'Oct';
            break
        case '11':
            month = 'Nov';
            break
        case '12':
            month = 'Dec';
            break
    }

    return (
        <>
            <div className='widget-calendar-icon-container'>
                <span className='calendar-icon-month'>{month}</span>
                <span className='calendar-icon-day'>{ day }</span>
            </div>
        </>
    )
}

export default WidgetIcon;