import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { InfrastructureWrapper, FormSelect, FormSearchInput, FormButtonOk, FormButtonCancel } from '../../components';
import { domainService, organizationService, roleService, userService } from '../../services';
import { toSelectOptions } from '../../helpers';
import AddedOrganization from './AddedOrganizationComponent';
import FormSearchInputMulti from '../../components/FormSearchInputMulti';

class UserAddRolePageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {
                organizationIds: [],
                domainIds: []
            },
            roles: []
        };
    }

    componentDidMount() {
        const { incrementSpinner, decrementSpinner, match } = this.props;
        const { params } = match;

        incrementSpinner();
        const promises = [
            this.getResource(() => { return roleService.getAll(params.id) }, 'roles', 'Error loading roles')
        ];

        Promise.all(promises).finally(() => {
            decrementSpinner();
        });
    }

    getResource(serviceFunction, stateName, errorMessage) {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        serviceFunction()
            .then(
                response => {
                    const { data } = response;
                    const newState = {};
                    newState[stateName] = data;
                    this.setState(newState);
                },
                () => {
                    showError(errorMessage);
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    generateSelectOptions = () => {
        const { roles } = this.state;

        const result = {};

        result.roleOptions = toSelectOptions(roles, {
            showDefault: true,
            defaultLabel: '-- Select Role --',
            valueFieldName: 'id',
            labelFieldName: 'description'
        });

        return result;
    }

    render() {
        const { model } = this.state;
        const { roleId, organizationId, organizationIds, domainId, domainIds } = model;
        const { roleOptions } = this.generateSelectOptions();

        const saveButtonEnabled = this.isSaveButtonEnabled();

        return (
            <Row>
                <Col md={12}>
                    <fieldset>
                        <legend>Add Role</legend>
                        <Row>
                            <Col md={6}>
                                <FormSelect
                                    fieldName='roleId'
                                    fieldValue={roleId}
                                    label='Role'
                                    onChange={this.handleValueChanged}
                                    options={roleOptions} />
                                {organizationIds.map((item, index) => {
                                    return (
                                        <AddedOrganization
                                            key={index}
                                            fieldName={'item' + index}
                                            fieldValue={item}
                                            label='Organization'
                                            valueLookupFunc={organizationService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='name'
                                            removeClick={this.handleRemoveOrganization} />
                                    );
                                })}
                                {roleId == 4 ? (
                                    <FormSearchInput
                                        fieldName='organizationId'
                                        fieldValue={organizationId}
                                        label='Add Organization'
                                        itemListFunc={organizationService.getList}
                                        valueLookupFunc={organizationService.getById}
                                        valueLookupIdName='id'
                                        valueLookupDisplayName='name'
                                        onChange={this.handleValueChanged}
                                        required={!organizationIds || organizationIds.length < 1} />
                                ) : null}
                                {roleId == 6 ? (
                                    <>
                                        <FormSearchInput
                                            fieldName='organizationId'
                                            fieldValue={organizationId}
                                            label='Add Organization'
                                            itemListFunc={organizationService.getList}
                                            valueLookupFunc={organizationService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='name'
                                            onChange={this.handleValueChanged}
                                            required={organizationIds.length == 0 && domainIds.length == 0} />
                                        {domainIds.map((item, index) => {
                                            return (
                                                <AddedOrganization
                                                    key={index}
                                                    fieldName={'item' + index}
                                                    fieldValue={item}
                                                    label='Domain'
                                                    valueLookupFunc={domainService.getById}
                                                    valueLookupIdName='id'
                                                    valueLookupDisplayName='name'
                                                    removeClick={this.handleRemoveDomain} />
                                            );
                                        })}
                                        <FormSearchInputMulti
                                            fieldName='domainId'
                                            fieldValue={domainId}
                                            label='Add Domain'
                                            itemListFunc={domainService.getList}
                                            valueLookupFunc={domainService.getById}
                                            valueLookupIdName='id'
                                            valueLookupDisplayName='name'
                                            onChange={this.handleValueChanged}
                                            onRemove={this.handleRemoveDomain}
                                            isItemSelectableFunc={(item) => domainIds.indexOf(item.id) < 0}
                                            required={organizationIds.length == 0 && domainIds.length == 0} />
                                    </>
                                ) : null}
                                <FormButtonOk
                                    title="Save"
                                    onClick={this.handleSaveClick}
                                    disabled={!saveButtonEnabled}>
                                    <i className="fas fa-check"></i> Save
                                </FormButtonOk>
                                <FormButtonCancel title="Cancel" onClick={this.handleCancelClick}>
                                    <i className="fas fa-times"></i> Cancel
                                </FormButtonCancel>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        );
    }

    isSaveButtonEnabled = () => {
        const { model } = this.state;
        const { roleId, organizationIds, domainIds } = model;
        if (!roleId) {
            return false;
        }

        if (roleId == 4 && (!organizationIds || organizationIds.length < 1)) {
            return false;
        }
        if (roleId == 6 && (organizationIds.length == 0 && domainIds.length == 0)) {
            return false;
        }

        return true;
    }

    handleValueChanged = (fieldName, value) => {
        const model = { ...this.state.model };

        if (fieldName === 'roleId') {
            model.organizationIds = [];
        }

        if (fieldName === 'organizationId') {
            if (this.state.model.organizationIds.indexOf(value) < 0) {
                model.organizationIds = [...this.state.model.organizationIds, value];
            }
        } else if (fieldName === 'domainId') {
            if (this.state.model.domainIds.indexOf(value) < 0) {
                model.domainIds = [...this.state.model.domainIds, value];
            }
        } else {
            model[fieldName] = value;
        }

        this.setState({ model });
    }

    handleRemoveOrganization = (organizationId) => {
        const model = { ...this.state.model };
        model.organizationIds = [...this.state.model.organizationIds].filter(item => item !== organizationId);
        this.setState({ model });
    }

    handleRemoveDomain = (domainId) => {
        const model = { ...this.state.model };
        model.domainIds = [...this.state.model.domainIds].filter(item => item !== domainId);
        this.setState({ model });
    }

    handleCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleSaveClick = () => {
        const { model } = this.state;
        const { history, match, incrementSpinner, decrementSpinner, showError } = this.props;

        if (!this.isSaveButtonEnabled()) {
            return;
        }

        incrementSpinner();
        userService.addAssignedRoles(match.params.id, model)
            .then(() => {
                history.goBack();
            }, () => {
                showError('Error saving model');
            })
            .finally(() => {
                decrementSpinner();
            });
    }
}

const UserAddRolePage = InfrastructureWrapper(withRouter(UserAddRolePageComponent));
export default UserAddRolePage;

