import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const employeeTypeService = {
    getAll,
    getList
};

function getAll() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/employeeType/all`, {
            headers
        });
    });
}

function getList(pageIndex, pageSize, query) {
    if (!pageSize) {
        pageSize = 10;
    }

    let apiUrl = `${apiHostUrl}/api/v1/employeeType/list?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${query}`;
    }

    return getHeaders().then((headers) => {
        return axios.get(apiUrl, {
            headers
        });
    });
}