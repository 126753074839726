import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AccountCodeListPage, AccountCodeImportPage } from '.';

class AccountCodePageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}`} component={AccountCodeListPage} />
                <Route exact path={`${url}/import`} component={AccountCodeImportPage} />
            </>
        );
    }
}

AccountCodePageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const AccountCodePage = withRouter(AccountCodePageComponent);
export default AccountCodePage;