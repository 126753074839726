import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ListButton, ListEditButton, ListDeleteButton } from '../';

class AddEditListItemComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            model: { ...props.item }
        };
    }

    render() {
        const { columns } = this.props;
        const { isEdit } = this.state;

        return (
            <tr>
                {columns.map((column, columnIndex) => {
                    const { className, hidden } = column;
                    if (hidden) {
                        return null;
                    }

                    return (
                        <td key={columnIndex} className={className}>
                            {!isEdit ? this.renderViewColumnContents(column) : this.renderEditColumnContents(column)}
                        </td>
                    );
                })}
            </tr>
        );
    }

    handleEditClicked = () => {
        this.setState({ isEdit: true });
    }

    handleCancelEditClicked = () => {
        const { item } = this.props;
        this.setState({ isEdit: false, model: { ...item } });
    }

    handleSaveClicked = () => {
        const { onSaveClicked } = this.props;
        const { model } = this.state;
        this.setState({ isEdit: false });

        onSaveClicked(model);
    }

    renderViewColumnContents(column) {
        const { model } = this.state;
        const { fieldName, formatFunc, contentRender, columnType } = column;
        const { onDeleteClick } = this.props;

        switch (columnType) {
            case 'EditButton':
                return (
                    <>
                        <ListEditButton title='Edit' onClick={this.handleEditClicked} />
                    </>
                );
            case 'DeleteButton':
                return (
                    <>
                        <ListDeleteButton title='Delete' onClick={() => onDeleteClick(model)} />
                    </>
                );
            default:
                return (
                    <>
                        {formatFunc && !contentRender ? formatFunc(model) : model[fieldName]}
                        {contentRender ? contentRender(model) : null}
                    </>
                );
        }
    }

    renderEditColumnContents(column) {
        const { model } = this.state;
        const { fieldName, formatFunc, contentRender, columnType, label } = column;

        switch (columnType) {
            case 'EditButton':
                return (
                    <>
                        <ListButton
                            title='Cancel'
                            onClick={this.handleCancelEditClicked}
                        >
                            <i className='fas fa-ban'></i>
                        </ListButton>
                        &nbsp;
                        <ListButton
                            title='Save'
                            onClick={this.handleSaveClicked}
                        >
                            <i className='fas fa-save'></i>
                        </ListButton>
                    </>
                );
            case 'DeleteButton':
                return null;
            case 'TextInput':
                return (
                    <Form.Control
                        placeholder={label}
                        aria-label={label}
                        as='input'
                        value={model[fieldName] !== null ? model[fieldName] : ''}
                        size='sm'
                        onChange={(event) => this.handleValueChanged(fieldName, event.target.value)}
                    />
                );
            default:
                return (
                    <>
                        {formatFunc && !contentRender ? formatFunc(model) : model[fieldName]}
                        {contentRender ? contentRender(model) : null}
                    </>
                );
        }
    }

    handleValueChanged = (fieldName, value) => {
        const model = { ...this.state.model };
        model[fieldName] = value;
        this.setState({ model });
    }
}

AddEditListItemComponent.propTypes = {
    columns: PropTypes.array,
    item: PropTypes.object,
    onSaveClicked: PropTypes.func,
    onDeleteClick: PropTypes.func
};

const AddEditListItem = AddEditListItemComponent;
export default AddEditListItem;