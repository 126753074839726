import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserRoleListPage, UserRoleImportPage } from '.';

class UserRolePageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}`} component={UserRoleListPage} />
                <Route exact path={`${url}/import`} component={UserRoleImportPage} />
            </>
        );
    }
}

UserRolePageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const UserRolePage = withRouter(UserRolePageComponent);
export default UserRolePage;