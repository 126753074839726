export const toSelectOptions = (sourceArray, options) => {
    sourceArray = sourceArray || [];
    options = options || {};
    let { valueFieldName, labelFieldName, showDefault, defaultValue, defaultLabel, labelFunc } = options;
    valueFieldName = valueFieldName || 'id';
    labelFieldName = labelFieldName || 'name';
    defaultValue = defaultValue || '';
    defaultLabel = defaultLabel || '';

    const results = sourceArray.map(item => {
        return { value: item[valueFieldName], label: !labelFunc ? item[labelFieldName] : labelFunc(item) };
    });

    if (showDefault) {
        results.unshift({ value: defaultValue, label: defaultLabel });
    }

    return results;
};

export const toSelectOptionsStringArray = (sourceArray, options) => {
    sourceArray = sourceArray || [];
    options = options || {};
    let { showDefault, defaultValue, defaultLabel } = options
    defaultValue = defaultValue || '';
    defaultLabel = defaultLabel || '';

    const results = sourceArray.map(item => {
        return { value: item, label: item };
    });

    if (showDefault) {
        results.unshift({ value: defaultValue, label: defaultLabel });
    }

    return results;
};