import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './AddEditButton.css';

const AddButtonComponent = ({ history, to, title, children }) => {
    return (
        <Button
            size='sm'
            variant='outline-dark'
            className='add-edit-button'
            title={title}
            onClick={() => { history.push(to); }}>
            {!!children ? children : (<><i className='fas fa-plus'></i> Add</>)}
        </Button>
    );
};

AddButtonComponent.propTypes = {
    history: PropTypes.object,
    to: PropTypes.string,
    title: PropTypes.string
};

const AddButton = withRouter(AddButtonComponent);
export default AddButton;