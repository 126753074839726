import React, { Component } from 'react';
import { connect } from 'react-redux';
import { configurationActions, navActions } from './actions';
import PropTypes from 'prop-types';
import { authProvider } from './helpers';
import { Navbar, Nav, Row, Col, Container, NavDropdown } from 'react-bootstrap';
import App from "./App";

class NavMenuComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { permissionsLoaded, getUserPermissions } = this.props;
        const { user } = this.props;

        if (user) {
            this.props.getMenu();

            if (!permissionsLoaded) {
                getUserPermissions();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { permissionsLoaded, getUserPermissions } = this.props;
        const { user } = this.props;

        if (user !== prevProps.user || (user && !permissionsLoaded)) {
            this.props.getMenu();
            getUserPermissions();
        }
    }

    render() {
        const { isTestEnvironment, impersonateOn, user, activeUser } = this.props;
        const menuItems = this.props.menuItems || [];


        let userMenuItems = [
            { text: 'Log on', onClick: this.handleLoginClick }
        ];

        if (user) {
            let displayName = '';
            const { name, idTokenClaims } = user;

            if (!name || name.toLowerCase() === 'unknown') {
                const { family_name, given_name, emails } = idTokenClaims;
                if (!!family_name && !!given_name) {
                    displayName = given_name + ' ' + family_name;
                }
                else if (!!given_name) {
                    displayName = given_name;
                }
                else {
                    displayName = emails[0];
                }
            } else {
                displayName = name;
            }

            if (activeUser) {
                userMenuItems = [
                    { text: displayName, to: '/users/profile' },
                    // { text: 'Change Password', onClick: this.handleChangePasswordClick },
                    { text: 'Log off', onClick: this.handleLogoutClick }
                ];
            }else {
                userMenuItems = [
                    { text: 'Log off', onClick: this.handleLogoutClick }
                ];
            }
        }

        return (
            <Navbar className="color-nav" sticky='top' variant='dark'>
                <Navbar.Brand href='/'>
                    <div className='logo-border'>
                        <div className='logo'></div>
                        {isTestEnvironment && !impersonateOn ? (
                            <div className='alert-danger text-center logo-sub-alert'>
                                Non-Production
                            </div>
                        ) : null}
                        {isTestEnvironment && impersonateOn ? (
                            <div className='alert-success text-center logo-sub-alert'>
                                Test Environment/Impersonating User
                            </div>
                        ) : null}
                        {!isTestEnvironment && impersonateOn ? (
                            <div className='alert-success text-center logo-sub-alert'>
                                Impersonating User
                            </div>
                        ) : null}
                    </div>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className='ml-auto'>
                        {menuItems.map((item, index) => {
                            if (item.children && item.children.length > 0) {
                                return (
                                    <NavDropdown key={index} title={item.text} alignRight={index === (menuItems.length - 1)}>
                                        {item.children.map((child, childIndex) => (
                                            <NavDropdown.Item key={childIndex} href={child.to}>{child.text}</NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                );
                            }

                            return (
                                <Nav.Link key={index} href={item.to}>{item.text}</Nav.Link>
                            );
                        })}
                        <NavDropdown title={<i className='fas fa-user'></i>} alignRight={true}>
                            {userMenuItems.map((item, index) => (
                                <NavDropdown.Item key={index} href={item.to} onClick={item.onClick}>{item.text}</NavDropdown.Item>
                            ))}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>


        );
    }

    handleChangePasswordClick = () => {
    }

    handleLogoutClick = () => {
        authProvider.signOut();
    }

    handleLoginClick = () => {
        authProvider.signOn();
    }
}

NavMenuComponent.propTypes = {
    menuItems: PropTypes.array,
    getMenu: PropTypes.func,
    isTestEnvironment: PropTypes.bool,
    user: PropTypes.object
};

const mapStateToProps = (state) => ({
    permissionsLoaded: state.configurationReducer.permissionsLoaded,
    menuItems: state.navReducer.menuItems,
    isTestEnvironment: state.navReducer.isTestEnvironment,
    impersonateOn: state.configurationReducer.impersonateOn,
    activeUser: state.configurationReducer.activeUser
});

const mapDispatchToProps = (dispatch) => ({
    getMenu: () => dispatch(navActions.getMenu()),
    getUserPermissions: () => dispatch(configurationActions.getPermissions())
});

const NavMenu = connect(mapStateToProps, mapDispatchToProps)(NavMenuComponent);
export default NavMenu;