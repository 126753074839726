import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

export default function UserAddEditTabContainer({ hasInternalAdminAccess, hasRegionalAdminAccess, isAdd, editUi, roleUi, BBGUI, certUi, userCertProgress }) {
    if ((!hasInternalAdminAccess && !hasRegionalAdminAccess) || isAdd) {
        return editUi;
    }
    return (
        <Tabs defaultActiveKey='edit'>
            <Tab eventKey='edit' title='Edit'>
                {editUi}
            </Tab>
            <Tab eventKey='certProgress' title='Certification Progress'>
                {userCertProgress}
            </Tab>
            <Tab eventKey='role' title='Roles'>
                {roleUi}
            </Tab>
            {(!isAdd) && (
            <Tab
                eventKey='BBG' title='BBG'>
                {BBGUI}
            </Tab>
            
            )}
            {(hasInternalAdminAccess || hasRegionalAdminAccess) && (
                <Tab eventKey='certs' title='Certifications'>
                    {certUi}
                </Tab>
            )}
        </Tabs>
    )
}