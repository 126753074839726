import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table } from 'react-bootstrap';
import { ListFilters, ListPaginator } from '.';
import AddButton from '../AddButton';

const PagedList = ({ pageTitle, addUrl, addTooltip, addContent, columns, items, totalRecords, pageIndex, pageSize, query,
    onNavigateToPageNumber, onPageSizeChanged, onSearchClick, buttonToolbar, queryPlaceholder, filterMenu, filterSubMenu,
    onRowClick, sortByColumn, sortColumn, sortDescending, onSortClick }) => {

    return (
        <>
            <Row>
                <Col sm={6}>
                    <h4>
                        {pageTitle}
                        {addUrl ? (<AddButton title={addTooltip} to={addUrl}>{addContent}</AddButton>) : null}
                    </h4>
                    {onSearchClick ? (
                        <ListFilters
                            query={query}
                            onSearchClick={onSearchClick}
                            placeholder={queryPlaceholder}
                        >
                            {filterMenu}
                        </ListFilters>
                    ) : null}
                    {filterSubMenu}
                </Col>
                <Col sm={6}>
                    {pageSize ? (
                        <div sm={6}>
                            <ListPaginator
                                pageIndex={pageIndex}
                                pageSize={pageSize}
                                totalCount={totalRecords}
                                onPageClick={onNavigateToPageNumber}
                                onPageSizeChanged={onPageSizeChanged}
                            />
                        </div>) : null}
                    {buttonToolbar}
                </Col>
            </Row>
            <Row><br /></Row>
            <Row>
                <Table striped hover>
                    <thead>
                        <tr>
                            {columns.map((column, index) => {
                                const { className, label, labelRender, hidden, sortColumnName } = column;
                                if (hidden) {
                                    return null;
                                }

                                return (
                                    <th
                                        key={index}
                                        className={`${className || ''}${sortByColumn && sortColumnName ? ' sortable-column' : ''}`}
                                        onClick={onSortClick ? () => { onSortClick(sortColumnName); } : null}
                                    >
                                        {labelRender ? labelRender() : label}
                                        &nbsp;
                                        {sortByColumn && sortColumn === sortColumnName ? (
                                            <i className={`fas fa-sort-amount-${!sortDescending ? 'down' : 'up'}-alt`}></i>
                                        ) : null}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {items.length === 0 ? (<tr><td className='table-no-results' colSpan={columns.length}>No Results</td></tr>) : null}
                        {items.map((item, index) => {
                            return (
                                <tr key={index} onClick={onRowClick ? () => { onRowClick(item); } : null}>
                                    {columns.map((column, index) => {
                                        const { fieldName, formatFunc, contentRender, className, hidden } = column;
                                        if (hidden) {
                                            return null;
                                        }

                                        return (
                                            <td key={index} className={className}>
                                                {formatFunc && !contentRender ? formatFunc(item) : item[fieldName]}
                                                {contentRender ? contentRender(item) : null}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col sm={6} />
                {pageSize && totalRecords > pageSize ? (
                    <Col sm={6}>
                        <ListPaginator
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            totalCount={totalRecords}
                            onPageClick={onNavigateToPageNumber}
                            onPageSizeChanged={onPageSizeChanged}
                        />
                    </Col>) : null}
            </Row>
        </>
    );
};

PagedList.propTypes = {
    pageTitle: PropTypes.string,
    addUrl: PropTypes.string,
    addTooltip: PropTypes.string,
    columns: PropTypes.array,
    items: PropTypes.array,
    totalRecords: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    query: PropTypes.string,
    onNavigateToPageNumber: PropTypes.func,
    onPageSizeChanged: PropTypes.func,
    onSearchClick: PropTypes.func,
    buttonToolbar: PropTypes.element,
    queryPlaceholder: PropTypes.string,
    filterMenu: PropTypes.element,
    filterSubMenu: PropTypes.element,
    onRowClick: PropTypes.func,
    sortByColumn: PropTypes.bool,
    sortColumn: PropTypes.string,
    sortDescending: PropTypes.bool,
    onSortClick: PropTypes.func
};

export default PagedList;