import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListPaginatorNumber } from './ListPaginator';

import './ListPaginator.css';


export default class CompactListPaginator extends Component {
    render() {
        const { pageIndex, pageSize, totalCount, onPageClick } = this.props;

        // Calculate the number of records that fall before this page
        const countBeforeCurrentPage = ((pageIndex - 1) * pageSize);
        // Calculate the number of pages that come before this one
        const previousPages = countBeforeCurrentPage / pageSize;
        // Calculate the number of items remaining after this page
        const remainingCount = totalCount - (pageIndex * pageSize);
        // Calculate the number of pages that come after this one
        const remainingPages = Math.ceil(remainingCount / pageSize);

        const maxPageNumber = Math.ceil(totalCount / pageSize);

        let pagesPerSideOfCurrent = 4;

        // If we have a lot page numbers we'll reduce the number of pages shown so the pager
        // doesn't take up too much space
        if (pageIndex >= 10) {
            pagesPerSideOfCurrent = 3;
        }

        if (pageIndex >= 100) {
            pagesPerSideOfCurrent = 2;
        }

        // We are targeting (pagesPerSideOfCurrent * 2) + 1 page numbers listed with the current one in the middle.
        // We'll use maxRemainingPages to calculate how many previous pages we should render.
        let maxRemainingPages = Math.min(remainingPages, pagesPerSideOfCurrent);
        if (previousPages < pagesPerSideOfCurrent) {
            // If the number of previous pages is less than pagesPerSideOfCurrent we'll bump up our remaining next pages to compensate
            maxRemainingPages = Math.min(maxRemainingPages + (pagesPerSideOfCurrent - previousPages), remainingPages);
        }

        const nextPageNumbers = this.getNextPageNumbers(pageIndex, maxRemainingPages);

        const fromNumber = countBeforeCurrentPage + 1;
        let toNumber = pageSize + countBeforeCurrentPage;
        if (toNumber > totalCount) {
            toNumber = totalCount;
        }

        return (
            <div className='paginator'>
                <div className='paginator-summary'>
                    {totalCount >= 1 ? (<>Showing {fromNumber}-{toNumber} of {totalCount}</>) : 'No Results'}
                </div>
                <div>
                    {pageIndex > 1 ? <ListPaginatorNumber pageIndex={1} onPageClick={onPageClick}><i className="fas fa-angle-double-left"></i></ListPaginatorNumber> : null}
                    {pageIndex > 1 ? <ListPaginatorNumber pageIndex={pageIndex - 1} onPageClick={onPageClick}><i className="fas fa-angle-left"></i></ListPaginatorNumber> : null}
                    <ListPaginatorNumber pageIndex={pageIndex} onPageClick={onPageClick} selected>{pageIndex}</ListPaginatorNumber>
                    {nextPageNumbers.length > 0 ? <ListPaginatorNumber pageIndex={pageIndex + 1} onPageClick={onPageClick}><i className="fas fa-angle-right"></i></ListPaginatorNumber> : null}
                    {nextPageNumbers.length > 0 ? <ListPaginatorNumber pageIndex={maxPageNumber} onPageClick={onPageClick}><i className="fas fa-angle-double-right"></i></ListPaginatorNumber> : null}
                </div>
            </div>
        );
    }

    handlePreviousClick = (event) => {
        const { onPageClick } = this.props;
        const targetPage = event.currentTarget.getAttribute('data-page');
        onPageClick(targetPage);
    }

    getNextPageNumbers(pageIndex, remainingPages) {
        const nextPages = [];
        pageIndex++;
        while (nextPages.length < remainingPages) {
            nextPages.push(pageIndex++);

        }

        return nextPages;
    }
}

CompactListPaginator.propTypes = {
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    onPageClick: PropTypes.func
};