import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../node_modules/react-datepicker/dist/react-datepicker.css';
import './FormDateInput.css';

const FormDateInput = ({ fieldName, fieldValue, label, required, onChange, readonly }) => {
    return (
        <div className='form-group'>
            <span className='form-label' id={fieldName}>
                {label}
            </span>
            <DatePicker
                className={`form-control ${!fieldValue && required ? 'is-invalid' : ''}`}
                selected={fieldValue ? Date.parse(fieldValue.substring(0, 10) + 'T00:00:00') : ''}
                dateFormat='MM/dd/yyyy'
                onChange={(value) => { onChange(fieldName, value.toISOString()); }}
                placeholderText={`Enter ${label}`}
                readOnly={readonly}
                isClearable={fieldValue}
                ariaLabelledBy={fieldName}
            />
        </div>
    );
};

FormDateInput.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
};

export default FormDateInput;