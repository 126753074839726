import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const domainService = {
    getAll,
    getById,
    getList,
    uploadFile,
    getInviteStatus,
    inviteBatch,
    reinviteBatch,
    isBulkInviteRunning,
    cancelBulkInviteTask
};

function getAll() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/domain/all`, {
            headers
        });
    });
}

function getById(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/domain/${id}`, {
            headers
        });
    });
}

function getList(pageIndex, pageSize, query, requirePrefix) {
    if (!pageSize) {
        pageSize = 10;
    }

    requirePrefix = requirePrefix || false;

    let apiUrl = `${apiHostUrl}/api/v1/domain/list?pageIndex=${pageIndex}&pageSize=${pageSize}&requirePrefix=${requirePrefix}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${query}`;
    }

    return getHeaders().then((headers) => {
        return axios.get(apiUrl, {
            headers
        });
    });
}

function uploadFile(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    return getHeaders({ 'Content-Type': 'multipart/form-data' }).then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/domain/import`, formData, {
            onUploadProgress,
            headers
        });
    });
}

function getInviteStatus() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/domain/invite/status`, {
            headers
        });
    });
}

function inviteBatch(id, organizationType) {
    let apiUrl = `${apiHostUrl}/api/v1/domain/${id}/invite/batch`;

    if (!!organizationType) {
        apiUrl += `?organizationType=${organizationType}`;
    }
    return getHeaders().then((headers) => {
        return axios.put(apiUrl, null, {
            headers
        });
    });
}

function reinviteBatch(id, organizationType) {
    let apiUrl = `${apiHostUrl}/api/v1/domain/${id}/reinvite/batch`;

    if (!!organizationType) {
        apiUrl += `?organizationType=${organizationType}`;
    }
    return getHeaders().then((headers) => {
        return axios.put(apiUrl, null, {
            headers
        });
    });
}

function isBulkInviteRunning() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/domain/invite/batch/running`, {
            headers
        });
    });
}

function cancelBulkInviteTask() {
    return getHeaders().then((headers) => {
        return axios.delete(`${apiHostUrl}/api/v1/domain/invite/batch`, {
            headers
        });
    });
}