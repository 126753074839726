import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const emailTemplateService = {
    add,
    update,
    getById,
    getList,
    sendTestEmail
};

function add(model) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/emailTemplate`, JSON.stringify(model), {
            headers
        });
    });
}

function update(model) {
    const { id } = model;
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/emailTemplate/${id}`, JSON.stringify(model), {
            headers
        });
    });
}

function getById(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/emailTemplate/${id}`, {
            headers
        });
    });
}

function sendTestEmail(id) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/emailTemplate/${id}/sendTest`, null, {
            headers
        });
    });
}

function getList(pageIndex, pageSize, query) {
    if (!pageSize) {
        pageSize = 10;
    }

    let apiUrl = `${apiHostUrl}/api/v1/emailTemplate/list?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${query}`;
    }

    return getHeaders().then((headers) => {
        return axios.get(apiUrl, {
            headers
        });
    });
}