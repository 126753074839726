import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OrganizationListPage } from '.';

class OrganizationPageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}`} component={OrganizationListPage} />
            </>
        );
    }
}

OrganizationPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const OrganizationPage = withRouter(OrganizationPageComponent);
export default OrganizationPage;