import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const intelligenceService = {
    getWaitlist,
    getNoEmailList,
    getDuplicateEmailList
};

function getWaitlist() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/lmsintelligence/waitlist`, {
            headers
        });
    });
}

function getNoEmailList(pageIndex, pageSize, query, active) {
    let apiUrl = `${apiHostUrl}/api/v1/lmsintelligence/noemail?pageIndex=${pageIndex}&pageSize=${pageSize}&active=${active || ''}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${encodeURIComponent(query)}`;
    }

    return getHeaders().then((headers) => {
        //console.log(axios.get(apiUrl, {headers}));
        return axios.get(apiUrl, {
            headers
        });
    });
}


function getDuplicateEmailList(pageIndex, pageSize, query) {
    let apiUrl = `${apiHostUrl}/api/v1/lmsintelligence/duplicateemail?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${encodeURIComponent(query)}`;
    }

    return getHeaders().then((headers) => {
        return axios.get(apiUrl, {
            headers
        });
    });
}