class StorageHelper {
    constructor() {
        this.storage = window.localStorage;
    }

    getUserMode = () => {
        return this.storage.getItem('UserMode');
    }

    isAdminMode = () => {
        return this.getUserMode() === 'admin';
    }

    setAdminMode = () => {
        this.storage.setItem('UserMode', 'admin');
    }

    setUserMode = () => {
        this.storage.setItem('UserMode', 'user');
    }

    isEndUserMode = () => {
        return !this.isAdminMode();
    }
}


export const storageHelper = new StorageHelper();