import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

class DocumentationPageComponent extends Component {
    render() {
        const { match } = this.props;
        const { params } = match;
        const { path } = params;

        // TODO get correct base url

        return (
            <>
                <iframe src={`https://tcatalog.mercuryuniversity.com/${path}`} width='100%' height='100%'></iframe>
            </>
        );
    }
}

DocumentationPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const DocumentationPage = withRouter(DocumentationPageComponent);
export default DocumentationPage;