import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormField from './FormField';

const FormSelect = ({ fieldName, fieldValue, label, subLabel, required, onChange, options, validationMessage, readonly }) => {
    return (
        <FormField
            fieldName={fieldName}
            label={label}
            subLabel={subLabel}
            validationMessage={validationMessage}
        >
            <Form.Control
                as='select'
                value={fieldValue == undefined || fieldValue == null ? '' : fieldValue}
                isInvalid={(required && (fieldValue == undefined || fieldValue == null || fieldValue === '')) || validationMessage}
                onChange={(event) => { if(!readonly) onChange(fieldName, event.target.value); }}
                readOnly={readonly}
            >
                {options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
            </Form.Control>
        </FormField >
    );
};

FormSelect.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    readonly: PropTypes.bool,
    validationMessage: PropTypes.string
};

export default FormSelect;