import React, { useState, useEffect } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import '../css/CustomToggleButton.css'; // Import the CSS file

function CustomToggleButton(props) {
    const { radios, onToggleChange } = props;
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');


    useEffect(() => {
        onToggleChange(radioValue);
    }, [radioValue, onToggleChange]);



    return (
        <>
            <ButtonGroup className="mb-2 custom-btn-group">
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    );
}

export default CustomToggleButton;