import React, { Component } from 'react';
import { userService } from '../services';

import ProgressBar from 'react-bootstrap/ProgressBar';
import { InfrastructureWrapper } from '.';

class CertificationBadge extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accruedHours: 0,
            annualCompletedItems: 0,
            annualRequiredItems: 0,
            hoursRequired: 0,
            triAnnualItemsAchieved: 0,
            triAnnualItemsRequired: 0
        }
    }

    componentDidMount() {
        this.loadModel();
    }

    loadModel = () => {
        const { incrementSpinner, decrementSpinner, showError, curriculumId } = this.props;

        incrementSpinner();
        userService.getProfileCertification(curriculumId)
            .then(
                response => {
                    const model = response.data;
                    this.setState({ ...model });
                },
                () => {
                    showError('Error loading model');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    render() {
        const { category } = this.props;
        const { accruedHours, annualCompletedItems, annualRequiredItems, hoursRequired,
            triAnnualItemsAchieved, triAnnualItemsRequired} = this.state;

        const { curriculumId, isMaster, newlyCertified } = this.props;

        if (hoursRequired == 0 && annualRequiredItems == 0 && triAnnualItemsRequired == 0) {
            return null;
        }

        let hoursProgress = 100;
        if (hoursRequired > 0) {
            hoursProgress = (accruedHours / hoursRequired) * 100;
        }

        let itemProgress = 100;
        if (annualRequiredItems > 0) {
            itemProgress = (annualCompletedItems / annualRequiredItems) * 100;
        }

        let annualProgress = 100;
        if(annualRequiredItems > 0){  // if there are no annual required items then this will cause an artifical boost in progress even if nothing is done
        annualProgress = (hoursProgress + itemProgress) / 2;
                                    }
        else{
        annualProgress = hoursProgress;
            }

        let triAnnualProgress = 100;
        var isInitial = false;
        if (triAnnualItemsRequired > 0 || newlyCertified) {
            triAnnualProgress = (triAnnualItemsAchieved / triAnnualItemsRequired) * 100;
        } else { isInitial = true;  }


        const getVariant = () => {
            if(annualProgress <= 50 ){
            return "danger";
                                    }
            if(annualProgress > 50  && annualProgress < 100) {
                return "warning";
            }
            else{
                return "success";
            }
                          };
                        
        const getVariantTri = () => {
            if(triAnnualProgress <= 50 ){
            return "danger";
                                    }
            if(triAnnualProgress > 50  && triAnnualProgress < 100) {
                return "warning";
            }
            else{
                return "success";
            }
                          };  
        const obSource = isMaster ?  "/images/Outboard_Master.png" : "/images/obcertified.png";
        const mcSource = isMaster ?  "/images/MerCruiser_Master.png" : "/images/MerCruiser Certified.png";

            return (
                <div>
                      <a href={`cert/${curriculumId}`}>  
                        {category == 'Outboard' && !isInitial ? <img style={{paddingLeft: "100px", width: "250px", height: "150px", margin: '0.5em'}} src={obSource}  /> : null}
                        {category == 'Mercruiser' && !isInitial ? <img style={{paddingLeft: "100px", width: "250px", height: "150px", margin: '0.5em'}} src={mcSource} /> : null}
                       </a>  
                        {category == 'MST' ? <img style={{paddingLeft: "100px", width: "250px", height: "150px", margin: '0.5em'}} src={"/images/MST.png"} /> : null}

                     {hoursRequired > 0 && !isInitial && !newlyCertified || annualRequiredItems > 0 && !isInitial && !newlyCertified ? <ProgressBar variant={getVariant()} now={annualProgress} label='Annual' position="absolute" top="100"  style={{margin: '0.5em', height: '2em' }}/> : null}

                    {triAnnualItemsRequired > 0 && !isInitial && !newlyCertified ? <ProgressBar variant={getVariantTri()} now={triAnnualProgress} label='Tri-Annual' position="absolute" top="100" style={{margin: '0.5em', height: '2em'}} /> : null} 

                </div>
            )
    }

}

export default InfrastructureWrapper(CertificationBadge);
