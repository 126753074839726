import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormField from './FormField';

const FormTextInput = ({ fieldName, fieldValue, label, subLabel, maxLength, required, onChange, validationMessage, readonly, autocomplete }) => {
    return (
        <FormField
            fieldName={fieldName}
            label={label}
            subLabel={subLabel}
            validationMessage={validationMessage}
        >
            <Form.Control
                placeholder={`Enter ${label}`}
                as='input'
                value={fieldValue !== null && fieldValue !== undefined ? fieldValue : ''}
                className={`${(readonly ? 'form-control-plaintext' : '')}`}
                isInvalid={(required && (fieldValue === null || fieldValue === undefined || fieldValue === '')) || validationMessage}
                onChange={onChange && !readonly ? (event) => { onChange(fieldName, event.target.value); } : null}
                maxLength={maxLength}
                readOnly={readonly}
                autoComplete={autocomplete}
            />
        </FormField >
    );
};

FormTextInput.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    validationMessage: PropTypes.string,
    autocomplete: PropTypes.string
};

export default FormTextInput;