import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

export default function UserListPageTabContainer({ hasInternalAdminAccess, hasRegionalAdminAccess, editUi, businessIntell }) {

    return (
        <Tabs defaultActiveKey='edit'>
            <Tab eventKey='edit' title='Edit'>
                {editUi}
            </Tab>
            {/* <Tab eventKey='certProgress' title='BI'>
                {businessIntell}
            </Tab>    version 2 */}
        </Tabs>
    )
}