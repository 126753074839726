import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmailTemplateListPage from './EmailTemplateListPage';
import EmailTemplateAddEditPage from './EmailTemplateAddEditPage';

class EmailTemplatePageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}`} component={EmailTemplateListPage} />
                <Route exact path={`${url}/edit/:id`} component={EmailTemplateAddEditPage} />
            </>
        );
    }
}

EmailTemplatePageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const EmailTemplatePage = withRouter(EmailTemplatePageComponent);
export default EmailTemplatePage;