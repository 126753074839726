import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FormField = ({ fieldName, label, subLabel, children, validationMessage }) => {
    return (
        <>
            <Form.Group controlId={fieldName}>
                <Form.Label>
                    {label}
                    <span className='sub-label'>
                        {subLabel ? `: ${subLabel}` : ''}
                    </span>
                </Form.Label>
                {children}
                <Form.Text className='invalid-feedback'>
                    {validationMessage}
                </Form.Text>
            </Form.Group>
        </>
    );
};

FormField.propTypes = {
    fieldName: PropTypes.string,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    children: PropTypes.any,
    validationMessage: PropTypes.string
};

export default FormField;