import React, { Component } from 'react';
import { Row, Col, Badge, Collapse, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfrastructureWrapper, ListEditButton } from '../../components';
import { intelligenceService } from '../../services';
import { FilterSelect, PagedList } from '../../components/list';

const pageSizeDefault = 20;
const pageIndexDefault = 0;

class NoEmailListPageComponent extends Component {
    constructor(props) {
        super(props);

        const { pageIndex, pageSize, query, activeStatus } = this.getUrlParameters();
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.query = query;
        this.activeStatus = activeStatus;

        this.state = {
            items: [],
            totalRecords: 0,
            filtersOpen: false
        };
    }

    componentDidUpdate() {
        const { pageIndex, pageSize, query, activeStatus } = this.getUrlParameters();

        if (this.pageIndex != pageIndex || this.pageSize != pageSize || 
            this.query != query || this.activeStatus != activeStatus) {
            this.pageIndex = pageIndex;
            this.pageSize = pageSize;
            this.query = query;
            this.activeStatus = activeStatus;

            this.getList(pageIndex, pageSize, query, activeStatus);
        }
    }

    componentDidMount() {
        const { pageIndex, pageSize, query, activeStatus } = this.getUrlParameters();
        this.getList(pageIndex, pageSize, query, activeStatus);
    }

    getList = (pageIndex, pageSize, query, activeStatus) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        let active = null;
        if (activeStatus === 'active') {
            active = "true";
        } else if (activeStatus === 'inactive') {
            active = "false";
        }

        incrementSpinner();
        intelligenceService.getNoEmailList(pageIndex, pageSize, query, active)
            .then(
                response => {
                    const results = response.data;
                    const { items, totalRecords } = results;
                    this.setState({ items, totalRecords });
                },
                () => {
                    showError('Error loading no email report');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    getUrlParameters = () => {
        const params = new URLSearchParams(window.location.search);

        return {
            pageIndex: +params.get('pageIndex') || pageIndexDefault,
            pageSize: +params.get('pageSize') || pageSizeDefault,
            query: params.get('query') || '',
            activeStatus: params.get('activeStatus') || 'active'
        };
    }

    render() {
        const { items, totalRecords, filtersOpen } = this.state;
        const { pageIndex, pageSize, query, activeStatus, } = this.getUrlParameters();

        const defaultActiveStatus = 'active';

        const activeStatusOptions = [
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
            { value: 'both', label: 'Both' }
        ];

        return (
            <Row>
                <Col md={12}>
                    <PagedList
                        pageTitle='No Email Report'
                        columns={[
                            { fieldName: 'techId', label: 'Tech ID' },
                            {
                                label: 'Name', contentRender: (item) => {
                                    const { firstName, lastName } = item;
                                    return (
                                        <>
                                            {firstName} {lastName}
                                        </>
                                    );
                                }
                            },
                            {
                                label: 'Status', contentRender: (item) => {
                                    const { active } = item;
                                    return (
                                        <>
                                            {active ? 'Active' : 'Inactive'}
                                        </>
                                    );
                                }
                            },
                            { label: 'Edit', contentRender: (item) => (<ListEditButton title='Edit User' to={`/users/edit/${item.id}`} />), className: 'text-center' }
                        ]}
                        items={items}
                        totalRecords={totalRecords}
                        pageIndex={pageIndex + 1}
                        pageSize={pageSize}
                        onNavigateToPageNumber={this.handleNavigateToPageNumber}
                        onPageSizeChanged={this.handlePageSizeChanged}
                        query={query}
                        onSearchClick={this.handleSearchClick}
                        queryPlaceholder='Search Name or Tech Id'
                        filterSubMenu={(
                            <div className='list-filters-clear-buttons'>
                                <Badge pill variant='secondary' onClick={() => { this.setState({ filtersOpen: !filtersOpen }); }}>
                                    Status: {(activeStatusOptions.filter(item => item.value == activeStatus)[0] || {}).label}
                                    &nbsp;
                                {activeStatus !== defaultActiveStatus ? (
                                        <i className='fas fa-times-circle' onClick={(event) => this.handleFilterValueChanged('activeStatus', '', event)} />
                                    ) : null}
                                </Badge>
                                {query || activeStatus !== defaultActiveStatus ? (
                                    <Badge pill variant='primary' onClick={this.clearAllFilters}>
                                        Clear All
                                        &nbsp;
                                        <i className='fas fa-times-circle' />
                                    </Badge>
                                ) : null}

                                <Collapse in={filtersOpen}>
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <FilterSelect
                                                    fieldName='activeStatus'
                                                    fieldValue={activeStatus}
                                                    label='Status'
                                                    onChange={this.handleFilterValueChanged}
                                                    options={activeStatusOptions}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button onClick={() => { this.setState({ filtersOpen: false }); }}>
                                                    Close
                                            </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                        )}
                    />
                </Col>
            </Row>
        );
    }

    handleSearchClick = (query) => {
        this.handleFilterValueChanged('query', query);
    }

    handleNavigateToPageNumber = (pageIndex) => {
        this.handleFilterValueChanged('pageIndex', pageIndex - 1);
    }

    handlePageSizeChanged = (pageSize) => {
        this.handleFilterValueChanged('pageSize', pageSize);
    }

    clearAllFilters = () => {
        const { history, match } = this.props;
        const { url } = match;
        const params = new URLSearchParams(window.location.search);

        params.delete('pageIndex');
        params.delete('query');
        params.delete('activeStatus');

        history.push(`${url}?${params.toString()}`);
    }

    handleFilterValueChanged = (fieldName, value, event) => {
        const { match } = this.props;
        const { url } = match;

        if (event) {
            event.stopPropagation();
        }
        const { history } = this.props;
        const params = new URLSearchParams(window.location.search);

        params.set(fieldName, value);
        if (fieldName === 'pageSize' || fieldName === 'query' || fieldName === 'activeStatus') {
            params.delete('pageIndex');
        }

        if (value === '' || value == 0 ||
            (fieldName === 'pageIndex' && value == pageIndexDefault) ||
            (fieldName === 'pageSize' && value == pageSizeDefault)) {
            params.delete(fieldName);
        }

        history.push(`${url}?${params.toString()}`);
    }
}

NoEmailListPageComponent.propTypes = {
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func,
    showPrompt: PropTypes.func
};

const NoEmailListPage = InfrastructureWrapper(withRouter(NoEmailListPageComponent));
export default NoEmailListPage;