import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const userService = {
    getList,
    getById,
    add,
    update,
    updateProfile,
    exists,
    emailExists,
    getPossibleSupervisors,
    getProfile,
    getProfileSettings,
    updateProfileSetting,
    getNew,
    getNextTechId,
    inviteB2C,
    getAssignedRoles,
    getAssignedRoleDetails,
    addAssignedRoles,
    removeAssignedRole,
    setSupervisorForAccountNumberAsync,
    getProfileCertification,
    getProfileCertifications,
    getProfileCertificationsInProgress,
    getProfileCertificationDetails,
    resetEmailAddress,
    getCertifications,
    addVariance,
    checkInsertCertification,
    getCertAndUncertCounts,
    getProfileCertificationDetailsByUser,
    getTeamClasses,
    getAvailableClasses,
    getEnrollPagePayload,
    attemptEnrollment,
    getBI,
    attemptWithdraw,
    getBBGPermissions,
    getWACEligibleBBG
};

function getList(pageIndex, pageSize, query, active, tamZone, domain) {
    if (!pageSize) {
        pageSize = 10;
    }

    const requestBody = {
        pageIndex,
        pageSize,
        active: active || '',
        tamZone: tamZone || null,
        domain: domain || '',
        query: query || ''
    };



    return getHeaders().then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/user/list`, requestBody, {
            headers
        });
    });
}
function getById(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/${id}`, {
            headers
        });
    });
}

function getNew() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/new`, {
            headers
        });
    });
}

function getProfile() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/profile`, {
            headers
        });
    });
}

function getProfileCertification(curriculum) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/profile/certification?curriculum=${curriculum}`, {
            headers
        });
    });
}

function getProfileSettings() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/profile/settings`, {
            headers
        });
    });
}

function updateProfileSetting(key, value) {
    return getHeaders().then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/user/profile/settings/${key}/${value}`, null, {
            headers
        });
    });
}


function getPossibleSupervisors(pageIndex, pageSize, query, userId, accountNumber) {
    if (!pageSize) {
        pageSize = 10;
    }

    let apiUrl = `${apiHostUrl}/api/v1/user/PossibleSupervisors?pageIndex=${pageIndex}&pageSize=${pageSize}&userId=${userId || ''}&accountNumber=${accountNumber}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${query}`;
    }

    return getHeaders().then((headers) => {
        return axios.get(apiUrl, {
            headers
        });
    });
}

function add(model) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/user`, JSON.stringify(model), {
            headers
        });
    });
}

function update(model) {
    const { id } = model;
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/user/${id}`, JSON.stringify(model), {
            headers
        });
    });
}

function resetEmailAddress(model) {
    const { id } = model;
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/user/${id}/resetEmail`, JSON.stringify(model), {
            headers
        });
    });
}

function setSupervisorForAccountNumberAsync(id, accountNumber) {
    return getHeaders().then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/user/${id}/SetSupervisorFor/${accountNumber}`, null, {
            headers
        });
    });
}

function updateProfile(model) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/user/profile`, JSON.stringify(model), {
            headers
        });
    });
}

function exists(techId, id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/TechId/exists/${techId}${id ? `?id=${id}` : ''}`, {
            headers
        });
    });
}

function emailExists(email) {
    return getHeaders().then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/user/email/exists`, { email }, {
            headers
        });
    });
}

function getNextTechId(techIdRegion) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/TechId/NextAvailable?techIdRegion=${techIdRegion}`, {
            headers
        });
    });
}

function inviteB2C(id) {
    //{ 'Content-Type': 'application/json' }
    return getHeaders().then((headers) => {
        return axios.put(`${apiHostUrl}/api/v1/user/${id}/InviteB2C`, null, {
            headers
        });
    });
}

function getAssignedRoles(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/${id}/roles`, {
            headers
        });
    });
}

function getAssignedRoleDetails(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/${id}/roles/details`, {
            headers
        });
    });
}

function addAssignedRoles(id, model) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/user/${id}/roles`, JSON.stringify(model), {
            headers
        });
    });
}

function removeAssignedRole(id, roleId, organizationId, systemDomainId) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.put(
            `${apiHostUrl}/api/v1/user/${id}/roles/remove`,
            JSON.stringify({ roleId, organizationIds: [organizationId], domainIds: [systemDomainId] }),
            {
                headers
            });
    });
}

function getProfileCertifications() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/profile/certifications`, {
            headers
        });
    });
}

function getProfileCertificationsInProgress() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/profile/certificationsInProgress`, {
            headers
        });
    });
}

function getProfileCertificationDetails(curriculum) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/profile/certificationDetails?curriculum=${curriculum}`, {
            headers
        });
    });
}


function getCertifications(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/${id}/certifications`, {
            headers
        });
    });
}

function addVariance(id, model) {
    return getHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/user/${id}/certification`, JSON.stringify(model), {
            headers
        });
    });
}






function getCertAndUncertCounts(pageIndex, pageSize, query, active, tamZone, domain, certID) {
    if (!pageSize) {
        pageSize = 10;
    }

    let apiUrl = `${apiHostUrl}/api/v1/user/certAndUncertCounts?certID=${certID}&pageIndex=${pageIndex}&pageSize=${pageSize}&active=${active || ''}&tamZone=${tamZone || ''}&domain=${domain || ''}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${encodeURIComponent(query)}`;
    }

    return getHeaders().then((headers) => {
        return axios.get(apiUrl, {
            headers
        });
    });


}







function checkInsertCertification(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/${id}/checkinsertcert`, {
            headers
        });
    });
}



function getProfileCertificationDetailsByUser(userID, curriculum) {

    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/user/profile/certificationDetailsByUser?userID=${userID}&curriculum=${curriculum}`, {
            headers
        });
    });
}


function getTeamClasses() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/ExternalLMS/teamClasses`, {
            headers
        });
    });
}


function getAvailableClasses() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/ExternalLMS/availableClasses`, {
            headers
        });
    });
}


function getEnrollPagePayload(schdID, classID) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/ExternalLMS/enrollPage?schdID=${schdID}&classID=${classID}`, {
            headers
        });
    });
}

function attemptEnrollment(schdID) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/ExternalLMS/attemptEnroll?schdID=${schdID}`, {
            headers
        });
    });
}



function getBI(pageIndex, pageSize, query, active, tamZone, domain) {
    if (!pageSize) {
        pageSize = 10;
    }

    const requestBody = {
        pageIndex,
        pageSize,
        active: active || '',
        tamZone: tamZone || null,
        domain: domain || '',
        query: query || ''
    };
    

    

        return getHeaders().then((headers) => {
            return axios.post(`${apiHostUrl}/api/v1/ExternalLMS/searchBIPayload`, requestBody, {
                headers
            });
    });
}

function attemptWithdraw(schdID) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/ExternalLMS/attemptWithdraw?schdID=${schdID}`, {
            headers
        });
    });
}


function getBBGPermissions(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/ExternalLMS/getBBGPermissions?id=${id}`, {
            headers
        });
    });
}

function getWACEligibleBBG(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/ExternalLMS/getBBGWACEligible?id=${id}`, {
            headers
        });
    });
}


