import React from 'react';
import PropTypes from 'prop-types';
import ListButton from './ListButton';

const ListDeleteButtonComponent = ({ onClick, title }) => {
    return (
        <ListButton
            title={title}
            onClick={onClick}
        >
            <i className='fas fa-trash-alt'></i>
        </ListButton>
    );
};

ListDeleteButtonComponent.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string
};

const ListDeleteButton = ListDeleteButtonComponent;
export default ListDeleteButton;