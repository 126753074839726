import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { authProvider } from '../helpers';

class ReAuthModalComponent extends Component {
    constructor(props) {
        super(props);

        this.authenticating = false;
        this.checkPaused = false;
        this.state = {
            show: false
        };
    }

    componentDidMount() {
        const authLoaded = authProvider.hasLoaded();
        if (authLoaded && !!authProvider.getUser()) {
            this.checkSession();
            this.setupCheckSession();
        }
    }

    componentDidUpdate() {
        const authLoaded = authProvider.hasLoaded();
        if (authLoaded && !!authProvider.getUser() && !this.checkPaused) {
            this.checkSession();
            if (!this.checkSessionInterval) {
                this.setupCheckSession();
            }
        }
    }

    setupCheckSession = () => {
        if (!!authProvider.getUser()) {
            this.checkSessionInterval = setInterval(this.checkSession, 60000);
            this.authenticating = false;
            this.checkPaused = false;
        }
    }

    checkSession = () => {
        const { show } = this.state;
        if (authProvider.isAlmostExpired()) {
            clearInterval(this.checkSessionInterval);
            this.checkSessionInterval = null;
            this.checkPaused = true;

            if (!show && !this.authenticating) {
                this.setState({ show: true });
            }
        } else {
            if (show) {
                // If we aren't almost expired and still showing the dialog hide it
                this.setState({ show: false });
            }
        }
    }

    render() {
        const { show } = this.state;

        return (
            <Modal show={show} centered>
                <Modal.Header>
                    <Modal.Title>Session Timeout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your session is about to timeout, do you want to continue using the application?</Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={this.handleReAuthClick}>Yes</Button>
                    <Button variant='secondary' onClick={this.handleSignoutClick}>No</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleReAuthClick = () => {
        authProvider.reAuthenticate();
        this.setState({ show: false });
        this.authenticating = true;
        // wait a bit before running this check so we don't immediately pop the dialog while we are re-authenticating
        setTimeout(() => { this.setupCheckSession(); }, 3000)
    }

    handleSignoutClick = () => {
        authProvider.signOut();
        this.setState({ show: false });
    }
}

const ReAuthModal = ReAuthModalComponent;
export default ReAuthModal;