 import { navConstants } from '../constants';

const initialState = {
    spinnerCount: 0,
    spinnerShowing: false,
    errorList: [],
    prompt: null,
    message: null,
    isTestEnvironment: false
};

export function navReducer(state = initialState, action) {
    let { spinnerCount, spinnerShowing, errorList } = state;

    switch (action.type) {
        case navConstants.GET_MENU:
            return {
                ...state,
                menuItems: action.menuItems,
                isTestEnvironment: action.isTestEnvironment
            };
        case navConstants.INCREMENT_SPINNER:
            spinnerCount = spinnerCount + 1;
            spinnerShowing = spinnerCount > 0;

            return {
                ...state,
                spinnerCount,
                spinnerShowing
            };
        case navConstants.DECREMENT_SPINNER:
            spinnerCount = spinnerCount - 1;
            spinnerShowing = spinnerCount > 0;
            if (spinnerCount < 0) {
                spinnerCount = 0;
            }

            return {
                ...state,
                spinnerCount,
                spinnerShowing
            };
        case navConstants.SHOW_ERROR:
            errorList = [...state.errorList, action.message];
            return {
                ...state,
                errorList
            };
        case navConstants.DISMISS_ERROR:
            errorList = [...state.errorList];
            errorList.shift();

            return {
                ...state,
                errorList
            };
        case navConstants.SHOW_PROMPT:
            return {
                ...state,
                prompt: {
                    title: action.title,
                    message: action.message,
                    onAccept: action.onAccept,
                    zIndex: action.zIndex
                }
            };
        case navConstants.DISMISS_PROMPT:
            return {
                ...state,
                prompt: null
            };
        case navConstants.SHOW_MESSAGE:
            return {
                ...state,
                message: {
                    title: action.title,
                    message: action.message
                }
            };
        case navConstants.DISMISS_MESSAGE:
            return {
                ...state,
                message: null
            };
        default:
            return state;
    }
}