import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { JobCodeListPage } from './';

class JobCodePageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}`} component={JobCodeListPage} />
            </>
        );
    }
}

JobCodePageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const JobCodePage = withRouter(JobCodePageComponent);
export default JobCodePage;