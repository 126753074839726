import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import WaitlistPage from './WaitlistPage';
import NoEmailListPage from './NoEmailListPage';
import DuplicateEmailListPage from './DuplicateEmailListPage';

class IntelligencePageComponent extends Component {
    render() {
        const { match } = this.props;
        const { url } = match;

        return (
            <>
                <Route exact path={`${url}/waitlist`} component={WaitlistPage} />
                <Route exact path={`${url}/noemail`} component={NoEmailListPage} />
                <Route exact path={`${url}/duplicateemail`} component={DuplicateEmailListPage} />
            </>
        );
    }
}

IntelligencePageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const IntelligencePage = withRouter(IntelligencePageComponent);
export default IntelligencePage;