import React, { Component } from 'react';
import { Row, Col, Badge, Button, Collapse } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListDeleteButton, ListEditButton, InfrastructureWrapper } from '../../components';
import { domainService, organizationService, userService } from '../../services';
import { PagedList, FilterSelect } from '../../components/list';
import { toSelectOptions, toSelectOptionsStringArray } from '../../helpers';
import UserListPageTabContainer from './UserListPageTabContainer';
import BarChartComponent from '../../components/BarChartComponent';
import Table from 'react-bootstrap/Table';
import './userListPage.css'



import PieChartC from '../../components/PieChart';

const pageSizeDefault = 20;
const pageIndexDefault = 0;

class UserListPageComponent extends Component {
    constructor(props) {
        super(props);

        const { pageIndex, pageSize, query, activeStatus, tamZone, domain } = this.getUrlParameters();
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.query = query;
        this.activeStatus = activeStatus;
        this.tamZone = tamZone;
        this.domain = domain;

        this.state = {
            uniqueQUALIDs: [],
            completedItems: {},
            techIds: {},
            completedItemCountById: {},
            uniqueItemIdsByCurric: {},
            items: [],
            totalRecords: 0,
            filtersOpen: false,
            tamZones: [],
            obCounts: [],
            mcCounts: [],
            domains: [],
            showPieChart: false,
            isFirstRender: true,
        };
    }

    componentDidUpdate() {
        const { pageIndex, pageSize, query, activeStatus, tamZone, domain } = this.getUrlParameters();

        if (this.pageIndex != pageIndex || this.pageSize != pageSize || this.query != query ||
            this.activeStatus != activeStatus || this.tamZone != tamZone || this.domain != domain) {
            this.pageIndex = pageIndex;
            this.pageSize = pageSize;
            this.query = query;
            this.activeStatus = activeStatus;
            this.tamZone = tamZone;
            this.domain = domain;

            this.getList(pageIndex, pageSize, query, activeStatus, tamZone, domain);
          //  this.fillMCCert(pageIndex, pageSize, query, activeStatus, tamZone, domain);
          // this.fillOBCert(pageIndex, pageSize, query, activeStatus, tamZone, domain);

          //this.getBI(pageIndex, pageSize, query, activeStatus, tamZone, domain);

        }
    }

    

    componentDidMount() {
        const { incrementSpinner, decrementSpinner } = this.props;
        const { pageIndex, pageSize, query, activeStatus, tamZone, domain } = this.getUrlParameters();

        incrementSpinner();
        const promises = [
            this.getResource(organizationService.getTAMZones, 'tamZones', 'Error loading TAM Zones'),
            this.getResource(domainService.getAll, 'domains', 'Error loading domains')
        ];

        Promise.all(promises)
            .then(() => {
                // After loading TAM Zones and domains, fetch the user list
                //this.fillMCCert(pageIndex, pageSize, query, activeStatus, tamZone, domain);
                //this.fillOBCert(pageIndex, pageSize, query, activeStatus, tamZone, domain);
               // this.getBI(pageIndex, pageSize, query, activeStatus, tamZone, domain);
                return this.getList(pageIndex, pageSize, query, activeStatus, tamZone, domain);
            })
            .finally(() => {
                decrementSpinner();
            });
    }
    
    





    getResource(serviceFunction, stateName, errorMessage) {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        incrementSpinner();
        serviceFunction()
            .then(
                response => {
                    const { data } = response;
                    const newState = {};
                    newState[stateName] = data;
                    this.setState(newState);
                },
                () => {
                    showError(errorMessage);
                }
            ).finally(() => {
                decrementSpinner();
            });
    }



    getUrlParameters = () => {
        const params = new URLSearchParams(window.location.search);

        return {
            pageIndex: +params.get('pageIndex') || pageIndexDefault,
            pageSize: +params.get('pageSize') || pageSizeDefault,
            query: params.get('query') || '',
            activeStatus: params.get('activeStatus') || 'active',
            tamZone: params.get('tamZone') || 'all',
            domain: params.get('domain') || ''
        };
    }



    getList = (index, size, q, activeStatus, tamZone, domain) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        const { query } = this.state;

        let active = null;
        if (activeStatus === 'active') {
            active = "true";
        } else if (activeStatus === 'inactive') {
            active = "false";
        }
        
        incrementSpinner();
        userService.getList(index, size, query, active, tamZone !== 'all' ? tamZone : '', domain)
            .then(
                response => {
                    const results = response.data;
                    const { items, totalRecords } = results;
                    this.setState({ items, totalRecords });
                },
                () => {
                    showError('Error loading user list');
                }
            ).finally(() => {
                decrementSpinner();
            });

    }










    getBI = (pageIndex, pageSize, q, activeStatus, tamZone, domain) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        const { query } = this.state;

        let active = null;
        if (activeStatus === 'active') {
            active = "true";
        } else if (activeStatus === 'inactive') {
            active = "false";
        }

        incrementSpinner();
        userService.getBI(pageIndex, 10000, query, active, tamZone !== 'all' ? tamZone : '', domain)
            .then(
                response => {
                    const { techIds, completedItems, certDetails, requiredItems } = response.data;
                    const uniqueQUALIDs = new Set();
                    const uniqueItemIdsByCurric = {};
                    const completedItemCountById = {};
                    

                    // Loop through requiredItems and add QUAL_ID values to the Set
                    requiredItems.forEach(item => {
                        if (item.curric.includes("Initial")) {
                            uniqueQUALIDs.add(item.curric);

                            // Check if the curric key exists in the object, if not, create a new array
                            if (!uniqueItemIdsByCurric[item.curric]) {
                                uniqueItemIdsByCurric[item.curric] = [];
                            }

                            uniqueItemIdsByCurric[item.curric].push(item.id);
 
                        }
                    });

                    
                    // Loop through uniqueItemIdsByCurric
                    for (const curric in uniqueItemIdsByCurric) {
                        if (uniqueItemIdsByCurric.hasOwnProperty(curric)) {
                            const itemIds = uniqueItemIdsByCurric[curric];
                            

                            // Loop through each itemId
                            for (const itemId of itemIds) {
                                // Filter completedItems to include only unique techID values for the current itemId
                                const uniqueTechIds = [...new Set(completedItems
                                    .filter(completedItem => completedItem.id === itemId)
                                    .map(completedItem => completedItem.techID)
                                )];

                                // Count the number of unique techID values
                                const itemCount = uniqueTechIds.length;
                                

                                // Update completedItemCountById
                                completedItemCountById[itemId] = itemCount;

                                // console.log(`    Count in completedItems: ${itemCount}`);
                            }

                        }
                    }


                    

                    // Convert the Set back to an array if needed
                    const uniqueQUALIDsArray = Array.from(uniqueQUALIDs);

                    

                    // Set the state with the received data, including uniqueQUALIDs
                    this.setState({
                        completedItems,
                        techIds,
                        certDetails,
                        requiredItems,
                        uniqueQUALIDs: uniqueQUALIDsArray,
                        uniqueItemIdsByCurric,
                        completedItemCountById
                    });
                },
                () => {
                    showError('Error loading BI');
                }
            ).finally(() => {
                decrementSpinner();
            });
            
    }
    




   






    fillOBCert = (index, size, query, activeStatus, tamZone, domain) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        let active = null;
        if (activeStatus === 'active') {
            active = "true";
        } else if (activeStatus === 'inactive') {
            active = "false";
        }

        userService.getCertAndUncertCounts(index, size, query, active, tamZone !== 'all' ? tamZone : '', domain, 'OB')
            .then(
                response => {
                    const results2 = response.data;
                    const obCounts = results2;
                    this.setState({ obCounts });
                },
                () => {
                    showError('Error loading user list');
                }
            ).finally(() => {
                decrementSpinner();
            });

    }






    fillMCCert = (index, size, query, activeStatus, tamZone, domain) => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;

        let active = null;
        if (activeStatus === 'active') {
            active = "true";
        } else if (activeStatus === 'inactive') {
            active = "false";
        }

        userService.getCertAndUncertCounts(index, size, query, active, tamZone !== 'all' ? tamZone : '', domain, 'MC')
            .then(
                response => {
                    const results3 = response.data;
                    const mcCounts = results3;
                    this.setState({ mcCounts });
                },
                () => {
                    showError('Error loading user list');
                }
            ).finally(() => {
                decrementSpinner();
            });

    }



    



    /*
    =================== handle bar click ==================================
    */
   
    handleBarChartClick = (clickedLabel, complete) => {
        const { pageIndex, pageSize, activeStatus, tamZone, domain } = this.getUrlParameters();
        const { match } = this.props;
        const { url } = match;
        const { completedItems, techIds } = this.state;
        const { history } = this.props;

        const params = new URLSearchParams(window.location.search);
        let query;

        if (complete) {
            const matchingTechIDs = completedItems
                .filter(item => item.id === clickedLabel)
                .map(item => item.techID);
            query = matchingTechIDs;
            if (matchingTechIDs.length > 0) {
                this.setState({ query }, () => {

                    params.delete('pageIndex');

                    alert(`updating search based on selected user group`);
                    this.getList(pageIndex, pageSize, query, activeStatus, tamZone, domain);
                    this.getBI(pageIndex, pageSize, query, activeStatus, tamZone, domain);

                });
            } else {
                alert(`no users found`);
            }
        } else {
            const nonMatchingTechIDs = techIds.filter(techId =>
                !completedItems.some(item => item.id === clickedLabel && item.techID === techId)
            );

            query = nonMatchingTechIDs;
            if (nonMatchingTechIDs.length > 0) {
                this.setState({ query }, () => {

                    params.delete('pageIndex');
                    alert(`updating search based on selected user group`);
                    this.getList(pageIndex, pageSize, nonMatchingTechIDs, activeStatus, tamZone, domain);
                    this.getBI(pageIndex, pageSize, nonMatchingTechIDs, activeStatus, tamZone, domain);

                });
            }
        }
        
  
    };





    

    /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    */


    render() {
        const { techIds,items, totalRecords, filtersOpen, tamZones, domains, uniqueQUALIDs, completedItems } = this.state;
        const { pageIndex, pageSize, query, activeStatus, tamZone, domain } = this.getUrlParameters();
        const { match, hasInternalAdminAccess, hasRegionalAdminAccess } = this.props;
        const { url } = match;

        //   const { totalUsers, graduationCount, certifiedCount, notCertified } = obCounts;
        const defaultActiveStatus = 'active';
        const defaultTamZone = 'all';

        const activeStatusOptions = [
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
            { value: 'both', label: 'Both' }
        ];

        const tamZoneOptions = toSelectOptionsStringArray(tamZones, {
            showDefault: true,
            defaultLabel: 'All',
            defaultValue: 'all'
        });

        const domainOptions = toSelectOptions(domains, {
            showDefault: true,
            defaultLabel: 'All',
            valueFieldName: 'id',
            labelFieldName: 'name'
        });

        const trailingColumns = [
            {
                label: 'Name', contentRender: (item) => {
                    const { firstName, lastName } = item;
                    return (
                        <>
                            {firstName} {lastName}
                        </>
                    );
                }
            },
            { fieldName: 'emailAddress', label: 'Email' },
            { label: 'Organization', formatFunc: (item) => { const { organization, accountNumber } = item; if (organization) { return `${organization.name} (${organization.id})`; } return accountNumber; } },
            { label: 'Job Position', formatFunc: (item) => { const jobPosition = item.jobPosition || {}; return jobPosition.description; } },
            { label: 'Edit', contentRender: (item) => (<ListEditButton title='Edit User' to={`${url}/edit/${item.id}`} />), className: 'text-center' }
        ];

        let columns = [
            { fieldName: 'techId', label: 'Tech ID' },
            {
                label: '', contentRender: (item) => {
                    const { isInB2C, isInSAP } = item;
                    return (
                        <>
                            {window.b2cManualInviteEnabled && !isInB2C ? (<i title='User Not in Brunswick B2C' className='fas fa-user-slash'></i>) : null}
                            {/* {!isInSAP ? (<i title='User Not in LMS' className='fas fa-exclamation'></i>) : null} */}
                        </>
                    );
                }
            }
        ];

        if (activeStatus === 'both') {
            columns = [
                ...columns,
                {
                    label: 'Active', contentRender: (item) => {
                        const { active } = item;
                        return (
                            <>
                                {active ? 'Active' : 'Inactive'}
                            </>
                        );
                    }
                },
                ...trailingColumns
            ]
        } else {
            columns = [
                ...columns,
                ...trailingColumns
            ]
        }


        /*
        =================== Get BI counts ==================================
        */


        const getCompletedItemsCount = () => {
            if (Array.isArray(completedItems)) {
                const filteredItems = completedItems.filter(item => item.id && item.id.includes("e"));
                return filteredItems.length;
            }
            return 0; // Default count 
        };

        /*
        =================== generate rows for BI page ==================================
        */const uniqueQUALIDRows = uniqueQUALIDs.map((qualID, index) => {
            const uniqueItemIds = this.state.uniqueItemIdsByCurric[qualID] || [];

            // Get counts from completedItemCountById
            const counts = uniqueItemIds.map(itemId => this.state.completedItemCountById[itemId] || 0);

            // Calculate incomplete counts
            const incompleteCounts = uniqueItemIds.map(itemId => {
                const completedCount = this.state.completedItemCountById[itemId] || 0;
                return Object.keys(techIds).length - completedCount;
            });

            const chartData = {
                labels: uniqueItemIds,
                values: counts,
                secondBarValues: incompleteCounts, // Rename incompleteCounts to secondBarValues
            };

            return (
                <div key={index} className="scrollable-container" style={{ paddingBottom: '200px' }}>
                    <Row key={index}>
                        <p> ________________________________________________________________________________________________</p>
                        <Col md={12}>
                            <p>{qualID === 'Mercruiser_Initial_Certificati' ? 'Mercruiser Initial Requirements' : qualID === 'Outboard_Initial_Certification' ? 'Outboard Initial Requirements' : qualID}</p>
                            <BarChartComponent data={chartData} onChartClick={this.handleBarChartClick} />
                        </Col>
                    </Row>
                </div>
            );
        });

        
        

        
        return (


            <UserListPageTabContainer
                hasInternalAdminAccess={hasInternalAdminAccess}
                hasRegionalAdminAccess={hasRegionalAdminAccess}
                


                editUi={(
            <Row>
                        <Col md={12}>
                            <div style={{ paddingLeft: '5%', paddingRight:'5%'}}>
                            <PagedList
                                pageTitle='Users'
                                addUrl={hasInternalAdminAccess || hasRegionalAdminAccess ? `${url}/add` : null}
                                addTooltip='Add User'
                                columns={columns}
                                items={items}
                                totalRecords={totalRecords}
                                pageIndex={pageIndex + 1}
                                pageSize={pageSize}
                                onNavigateToPageNumber={this.handleNavigateToPageNumber}
                                onPageSizeChanged={this.handlePageSizeChanged}
                                query={query}
                                onSearchClick={this.handleSearchClick}
                                queryPlaceholder='Search Users'
                                filterSubMenu={(
                                    <div className='list-filters-clear-buttons'>
                                        <Badge pill variant='secondary' onClick={() => { this.setState({ filtersOpen: !filtersOpen }); }}>
                                            Status: {(activeStatusOptions.filter(item => item.value == activeStatus)[0] || {}).label}
                                            &nbsp;
                                            {activeStatus !== defaultActiveStatus ? (
                                                <i className='fas fa-times-circle' onClick={(event) => this.handleFilterValueChanged('activeStatus', '', event)} />
                                            ) : null}
                                        </Badge>
                                        <Badge pill variant='secondary' onClick={() => { this.setState({ filtersOpen: !filtersOpen }); }}>
                                            TAM Zone: {(tamZoneOptions.filter(item => item.value == tamZone)[0] || {}).label}
                                            &nbsp;
                                            {tamZone !== defaultTamZone ? (
                                                <i className='fas fa-times-circle' onClick={(event) => this.handleFilterValueChanged('tamZone', defaultTamZone, event)} />
                                            ) : null}
                                        </Badge>
                                        <Badge pill variant='secondary' onClick={() => { this.setState({ filtersOpen: !filtersOpen }); }}>
                                            Domain: {(domainOptions.filter(item => item.value == domain)[0] || {}).label}
                                            &nbsp;
                                            {domain ? (
                                                <i className='fas fa-times-circle' onClick={(event) => this.handleFilterValueChanged('domain', '', event)} />
                                            ) : null}
                                        </Badge>
                                        {query || tamZone !== defaultTamZone || activeStatus !== defaultActiveStatus || domain ? (
                                            <Badge pill variant='primary' onClick={this.clearAllFilters}>
                                                Clear All
                                                &nbsp;
                                                <i className='fas fa-times-circle' />
                                            </Badge>
                                        ) : null}

                                        <Collapse in={filtersOpen}>
                                            <div>
                                                <Row>
                                                    <Col md={4}>
                                                        <FilterSelect
                                                            fieldName='activeStatus'
                                                            fieldValue={activeStatus}
                                                            label='Status'
                                                            onChange={this.handleFilterValueChanged}
                                                            options={activeStatusOptions}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <FilterSelect
                                                            fieldName='tamZone'
                                                            fieldValue={tamZone}
                                                            label='TAM Zone'
                                                            onChange={this.handleFilterValueChanged}
                                                            options={tamZoneOptions}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <FilterSelect
                                                            fieldName='domain'
                                                            fieldValue={domain}
                                                            label='Domain'
                                                            onChange={this.handleFilterValueChanged}
                                                            options={domainOptions}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Button onClick={() => { this.setState({ filtersOpen: false }); }}>
                                                            Close
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Collapse>
                                    </div>
                                )}
                                />
                            </div>
                </Col>
            </Row>
                )}
                />
        );
    }



    handleSearchClick = (q) => {
        const { pageIndex, pageSize, activeStatus, tamZone, domain } = this.getUrlParameters();
        let query;

        if (q.includes('~')) {
            // If the string has the delimiter "~", split it into an array
            query = q.split('~');
        } else {
            // If there is no delimiter, create an array with the original string as the only element
            query = [q];
        }

        this.setState({ query }, () => {
            this.handleFilterValueChanged('query', this.state.query);

            this.getList(pageIndex, pageSize, query, activeStatus, tamZone, domain);
            this.getBI(pageIndex, pageSize, query, activeStatus, tamZone, domain);
        });
    }


    handleNavigateToPageNumber = (pageIndex) => {
        this.handleFilterValueChanged('pageIndex', pageIndex - 1);
    }

    handlePageSizeChanged = (pageSize) => {
        this.handleFilterValueChanged('pageSize', pageSize);
    }

    clearAllFilters = () => {
        const { history, match } = this.props;
        const { url } = match;
        const params = new URLSearchParams(window.location.search);

        params.delete('pageIndex');
        params.delete('query');
        params.delete('activeStatus');
        params.delete('tamZone');
        params.delete('domain');

        history.push(`${url}?${params.toString()}`);
    }

    handleFilterValueChanged = (fieldName, value, event) => {
        const { match } = this.props;
        const { url } = match;

        if (event) {
            event.stopPropagation();
        }
        const { history } = this.props;
        const params = new URLSearchParams(window.location.search);

        if (fieldName !== 'query') { // query is managed through state to allow for larger query
            params.set(fieldName, value);
        }


        if (fieldName === 'pageSize' || fieldName === 'query' || fieldName === 'activeStatus') {
            params.delete('pageIndex');
        }

        if (value === '' || value == 0 ||
            (fieldName === 'pageIndex' && value == pageIndexDefault) ||
            (fieldName === 'pageSize' && value == pageSizeDefault)) {
            params.delete(fieldName);
        }

        if (fieldName === 'tamZone' && value === 'all') {
            params.delete(fieldName);
        }

        history.push(`${url}?${params.toString()}`);
    }
}

UserListPageComponent.propTypes = {
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func,
    showPrompt: PropTypes.func
};

const UserListPage = InfrastructureWrapper(withRouter(UserListPageComponent));
export default UserListPage;