import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navActions } from '../actions';

class PromptModalComponent extends Component {
    render() {
        const { prompt, dismissPrompt } = this.props;
        if (!prompt) {
            return null;
        }

        return (
            <Modal show={true} centered onHide={dismissPrompt} style={{ zIndex: prompt.zIndex }}>
                <Modal.Header>
                    <Modal.Title>{prompt.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{prompt.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={this.handleOkClick}>Ok</Button>
                    <Button variant='secondary' onClick={dismissPrompt}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleOkClick = () => {
        const { prompt, dismissPrompt } = this.props;
        if (!prompt) {
            return;
        }

        prompt.onAccept();
        dismissPrompt();
    }
}

PromptModalComponent.propTypes = {
    prompt: PropTypes.object,
    dismissPrompt: PropTypes.func,
    zIndex: PropTypes.number
};

const mapStateToProps = (state) => ({
    prompt: state.navReducer.prompt
});

const mapDispatchToProps = (dispatch) => ({
    dismissPrompt: () => dispatch(navActions.dismissPrompt())
});

const PromptModal = connect(mapStateToProps, mapDispatchToProps)(PromptModalComponent);
export default PromptModal;