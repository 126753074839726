import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Card, Carousel, Col, ProgressBar, Row } from 'react-bootstrap';
import { InfrastructureWrapper } from '../../components';
import { userService } from '../../services';
import { Accordion, Collapse, Button } from 'react-bootstrap';
import CertificationBadge from '../../components/CertificationBadge';
import CustomToggleButton from '../../components/CustomToggleButton';
import CertificationProgressBadge from '../../components/CertificationProgressBadge';

import { Calendar, globalizeLocalizer, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import "react-big-calendar/lib/css/react-big-calendar.css";


class ClassPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            certifications: [],
            certificationsIP: [],
            outboardCertDetails: [],
            mercruiserCertDetails: [],
            availableClasses: [],
            completedClasses: [],
            toggleButtonValue: '1', // Initialize toggleButtonValue
            events: [],
            allEvents: []
        };
    }

    handleSuccessFactorsClick = () => {
        window.location = 'https://mercuryuniversity.com';
    }

    componentDidMount() {
        this.getCertificationStatuses();
        this.getCertificationProgressStatuses();
        this.getAvailableClasses();
        this.loadUserCertificationProgress();
    }

    // ==============================================
    // ****** Get user certification progress *******
    // ==============================================

    loadUserCertificationProgress = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        this.setState({ isLoading: true })
        userService.getProfileCertificationDetails("Outboard_Initial_Certification")
            .then(
                response => {
                    const outboardCertDetails = response.data;
                    this.setState({ outboardCertDetails });
                },
                () => {
                    showError('Error loading certification details');
                }
            ).finally(() => {
                decrementSpinner();
            });
        userService.getProfileCertificationDetails("Mercruiser_Initial_Certificati")
            .then(
                response => {
                    const mercruiserCertDetails = response.data;
                    this.setState({ mercruiserCertDetails });
                },
                () => {
                    showError('Error loading certification details');
                }
            ).finally(() => {
                this.setState({ isLoading: false })
            });
    }


    // ==============================================
    // ************ SELECT CLASS ********************
    // ==============================================

    selectClass(event) {
        const { history } = this.props;
        history.push(`/classes/enroll?class=${event.classID}&item=${event.itemID}`);
    }



    // ==============================================
    // ************ Get Data Functions ***************
    // ==============================================




    getCertificationStatuses = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        incrementSpinner();
        userService.getProfileCertifications()
            .then(
                response => {
                    const certifications = response.data;
                    this.setState({ certifications });
                },
                () => {
                    showError('Error loading certification status');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }

    getCertificationProgressStatuses = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        incrementSpinner();
        userService.getProfileCertificationsInProgress()
            .then(
                response => {
                    const certificationsIP = response.data;
                    this.setState({ certificationsIP });
                },
                () => {
                    showError('Error loading certification status');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }



    getAvailableClasses = () => {
        const { incrementSpinner, decrementSpinner, showError } = this.props;
        incrementSpinner();
        userService.getAvailableClasses()
            .then(
                response => {
                    const availableClasses = response.data.availableClasses;

                    const events = availableClasses.map((classInfo) => {
                        let availableSeats
                        classInfo.totalSeats - classInfo.currentSeatCount > 0 ? availableSeats = classInfo.totalSeats - classInfo.currentSeatCount : availableSeats = 0

                        return {
                            start: new Date(classInfo.startDate),
                            end: new Date(classInfo.endDate),
                            title: `${classInfo.className} - Available seats: ${availableSeats}`,
                            itemID: classInfo.class_id,
                            classID: classInfo.scheduleID,
                            baseILT: classInfo.baseILT,
                        }
                    }
                    );
                    this.setState({ events });
                    this.setState({ allEvents: events });
                },
                () => {
                    showError('Error loading available classes');
                }
            ).finally(() => {
                decrementSpinner();
            });
    }


    // ==============================================
    // ************ Get Data Functions END **********
    // ==============================================



    render() {
        const { certifications, certificationsIP, mercruiserCertDetails, outboardCertDetails, events, isLoading, allEvents } = this.state;
        const { hasInternalAdminAccess, hasRegionalAdminAccess } = this.props;



        let outboardEventsFiltered = []

        if (isLoading === false && outboardCertDetails.incompleteInitialPool && events) {

            Object.values(outboardCertDetails?.incompleteInitialPool).map((item) => {
                outboardEventsFiltered.push(allEvents.filter((classInfo) => classInfo.baseILT === item.id))
            })

        }

        let mercruiserEventsFiltered = []

        if (isLoading === false && mercruiserCertDetails.incompleteInitialPool && events) {

            Object.values(mercruiserCertDetails?.incompleteInitialPool).map((item) => {
                mercruiserEventsFiltered.push(allEvents.filter((classInfo) => classInfo.baseILT === item.id))
            })

        }


        const outboardEvents = outboardEventsFiltered.filter(item => item.length > 0).flat()
        const mercruiserEvents = mercruiserEventsFiltered.filter(item => item.length > 0).flat()

        const localizer = momentLocalizer(moment);


        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <fieldset style={{ border: ' 1px solid #333', textAlign: 'center' }}>
                        <legend style={{ all: 'unset' }}>&nbsp;</legend>
                        <div style={{ display: 'flex', gap: '12px', padding: '0px 6px 6px 6px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <label style={{ marginBottom: '0px', fontSize: '13px' }} htmlFor='all'>All Classes</label>
                                <input type='radio' name='Event checkbox' value='All' id='all' defaultChecked onClick={() => this.setState({ events: allEvents })} />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset style={{ border: ' 1px solid #333', textAlign: 'center' }}>
                        <legend style={{ all: 'unset' }}>Remaining classes for</legend>
                        <div style={{ display: 'flex', gap: '12px', padding: '0px 6px 6px 6px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <label style={{ marginBottom: '0px', fontSize: '13px' }} htmlFor='outboard'>Outboard</label>
                                <input type='radio' name='Event checkbox' value='Outboard' id='outboard' onClick={() => this.setState({ events: outboardEvents })} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <label style={{ marginBottom: '0px' }} htmlFor='mercruiser'>MerCruiser</label>
                                <input type='radio' name='Event checkbox' value='MerCruiser' id='mercruiser' onClick={() => this.setState({ events: mercruiserEvents })} />
                            </div>
                        </div>
                    </fieldset>
                </div>



                <Row>

                    <Col xl={4} lg={12}  >
                        <div style={{ marginBottom: '15px' }}>
                            <Calendar
                                localizer={localizer}
                                defaultDate={new Date()}
                                defaultView="month"
                                views={["month", "day", "agenda"]}
                                events={this.state.events}
                                showAllEvents={false}
                                style={{ height: "80vh", width: "90vw" }}
                                onSelectEvent={event => this.selectClass(event)}
                            //handleSelectEvent={event => alert(event.title)}
                            />
                        </div>

                    </Col>








                </Row>
            </div>
        );
    }
}

ClassPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};
const ClassPage = InfrastructureWrapper(withRouter(ClassPageComponent));
export default ClassPage;