import { configurationConstants } from '../constants';

const initialState = {
    permissionsLoaded: false,
    hasSuperAdminAccess: false,
    hasInternalAdminAccess: false,
    hasSupervisorAccess: false,
    hasRegionalAdminAccess: false,
    hasTechnicianAccess : false,
    hasAnyRoleAccess: false,
    hasResetEmail: false,
    impersonateOn: false,
    activeUser: false,
    lockedUser: false,
    completeProfile: false,
    isMasterTech: false
};

export function configurationReducer(state = initialState, action) {
    switch (action.type) {
        case configurationConstants.GET_USER_PERMISSIONS:
            return {
                ...state,
                permissionsLoaded: true,
                hasSuperAdminAccess: action.hasSuperAdminAccess,
                hasInternalAdminAccess: action.hasInternalAdminAccess,
                hasSupervisorAccess: action.hasSupervisorAccess,
                hasRegionalAdminAccess: action.hasRegionalAdminAccess,
                hasTechnicianAccess: action.hasTechnicianAccess,
                hasAnyRoleAccess: action.hasAnyRoleAccess,
                hasResetEmail: action.hasResetEmail,
                impersonateOn: action.impersonateOn,
                activeUser: action.activeUser,
                lockedUser: action.lockedUser,
                completeProfile: action.completeProfile,
                isMasterTech: action.isMasterTech
            };
        default:
            return state;
    }
}