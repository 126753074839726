import React, { Component } from 'react';
import { connect } from 'react-redux';
import { configurationActions, navActions } from './actions';
import PropTypes from 'prop-types';
import { authProvider } from './helpers';
import { Navbar, Nav, Row, Col, Container, NavDropdown } from 'react-bootstrap';
import App from "./App";

class FooterComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { permissionsLoaded, getUserPermissions } = this.props;
        const { user } = this.props;

        if (user) {
            this.props.getMenu();

            if (!permissionsLoaded) {
                getUserPermissions();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { permissionsLoaded, getUserPermissions } = this.props;
        const { user } = this.props;

        if (user !== prevProps.user || (user && !permissionsLoaded)) {
            this.props.getMenu();
            getUserPermissions();
        }
    }

    render() {
        const { isTestEnvironment, impersonateOn, user, activeUser } = this.props;
        const menuItems = this.props.menuItems || [];


        let userMenuItems = [
            { text: 'Log on', onClick: this.handleLoginClick }
        ];

        if (user) {
            let displayName = '';
            const { name, idTokenClaims } = user;

            if (!name || name.toLowerCase() === 'unknown') {
                const { family_name, given_name, emails } = idTokenClaims;
                if (!!family_name && !!given_name) {
                    displayName = given_name + ' ' + family_name;
                }
                else if (!!given_name) {
                    displayName = given_name;
                }
                else {
                    displayName = emails[0];
                }
            } else {
                displayName = name;
            }

            if (activeUser) {
                userMenuItems = [
                    { text: displayName, to: '/users/profile' },
                    // { text: 'Change Password', onClick: this.handleChangePasswordClick },
                    { text: 'Log off', onClick: this.handleLogoutClick }
                ];
            }else {
                userMenuItems = [
                    { text: 'Log off', onClick: this.handleLogoutClick }
                ];
            }
        }

        return (
           

                <Navbar className="color-nav" fixed="bottom" variant='dark'>
                    <Container className="flex-column">
                        <Row>
                            <Col>
                                <p style={{ color: 'white' }}>
                                    @2024 Mercury Marine. All rights reserved. Mercury Marine is a division of Brunswick Corporation. The names of other companies, products, and services are the property of their respective owners
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                            <a href="https://www.brunswick.com/terms-of-use" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                                    <p style={{ color: 'white', whiteSpace: 'nowrap' }}>Terms Of Use</p>
                                </a>
                            </Col>
                            <Col md={2}>
                            <a href="https://www.brunswick.com/privacy-policy" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                                    <p style={{ color: 'white', whiteSpace: 'nowrap' }}>Privacy Policy</p>
                                </a>
                        </Col>
                        <Col md={2}>
                            <a href="https://privacyportal.onetrust.com/webform/e1ebc611-5769-4b5b-aa85-2ee85055a9e7/86cc5c86-854c-4e91-8078-726c6209af52" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                                <p style={{ color: 'white', whiteSpace: 'nowrap' }}>Privacy Rights</p>
                            </a>
                        </Col>
                            <Col md={2}>
                            <a href=" https://www.brunswick.com/cookie-policy" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                                    <p style={{ color: 'white', whiteSpace: 'nowrap' }}>Cookie Policy</p>
                                </a>
                            </Col>
                            <Col md={2}>
                            <a href="https://www.brunswick.com/accessibility" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                                    <p style={{ color: 'white', whiteSpace: 'nowrap' }}>Accessibility</p>
                                </a>
                            </Col>
                            <Col md={2}>
                            <a href="https://www.brunswick.com/modern-slavery-statement" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                                    <p style={{ color: 'white', whiteSpace: 'nowrap' }}>Modern Slavery Statement</p>
                                </a>
                        </Col>
                        </Row>
                    </Container>
                </Navbar>

  
        );
    }

    handleChangePasswordClick = () => {
    }

    handleLogoutClick = () => {
        authProvider.signOut();
    }

    handleLoginClick = () => {
        authProvider.signOn();
    }
}

FooterComponent.propTypes = {
    menuItems: PropTypes.array,
    getMenu: PropTypes.func,
    isTestEnvironment: PropTypes.bool,
    user: PropTypes.object
};

const mapStateToProps = (state) => ({
    permissionsLoaded: state.configurationReducer.permissionsLoaded,
    menuItems: state.navReducer.menuItems,
    isTestEnvironment: state.navReducer.isTestEnvironment,
    impersonateOn: state.configurationReducer.impersonateOn,
    activeUser: state.configurationReducer.activeUser
});

const mapDispatchToProps = (dispatch) => ({
    getMenu: () => dispatch(navActions.getMenu()),
    getUserPermissions: () => dispatch(configurationActions.getPermissions())
});

const Footer = connect(mapStateToProps, mapDispatchToProps)(FooterComponent);
export default Footer;