import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfrastructureWrapper } from '../components';
import { Alert } from 'react-bootstrap';
import { authProvider } from '../helpers';

class ExpiredInvitePageComponent extends Component {

    componentDidUpdate() {
        if (authProvider.hasLoaded() && !authProvider.isExpired()) {
            window.location.href = window.location.origin + '/';
        }
    }

    render() {

        return (
            <div>
                <div className='row'>
                    <div className='col-sm-8'>
                        <Alert variant='info'>
                            The invite link used has expired, if you have not already created a password please contact your adiminstrator.
                            <br />
                            If you have already already set up a password please sign in
                        </Alert>
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}

ExpiredInvitePageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const ExpiredInvitePage = withRouter(InfrastructureWrapper(ExpiredInvitePageComponent));
export default ExpiredInvitePage;