import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { authProvider } from '../helpers/authProvider';
import { storageHelper } from '../helpers/storageHelper';
import { Col, Row } from 'react-bootstrap';

class LoginPageComponent extends Component {
    constructor(props) {
        super(props);


        this.state = {
            user: null,
            isUserLoginClicked: false,
            isAdminLoginClicked: false,
            readyToLogin: localStorage.getItem('readyToLogin') === 'true',
        };
        const rtl = localStorage.getItem('readyToLogin');
        if (rtl) { authProvider.signOn(); }
    }

    componentDidMount() {
        this.getUser();
        const currentUrl = window.location.href.toLowerCase();
        this.setState({ currentUrl });

    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleLocalStorageChange);
    }

    getUser = () => {
        const user = authProvider.getUser();
        this.setState({ user });
    };

    login = () => {
        localStorage.setItem('readyToLogin', 'true');
        this.setState({ isUserLoginClicked: true });
        this.changeToUserMode();
    };

    adminLogin = () => {
        localStorage.setItem('readyToLogin', 'true');
        this.setState({ isAdminLoginClicked: true });
        this.changeToAdminMode();
    };

    logout = () => {
        authProvider.signOut();
    };

    changePassword = () => {
        // authProvider.changePassword();
    };

    changeToAdminMode = () => {
        storageHelper.setAdminMode();
        document.location.reload();
    };

    changeToUserMode = () => {
        storageHelper.setUserMode();
        document.location.reload();
    };

    render() {
        const { user, isUserLoginClicked, isAdminLoginClicked, readyToLogin, currentUrl } = this.state;
        const expired = authProvider.isExpired();
        const isAdmin = storageHelper.isAdminMode() || !window.b2cEnabled;
        const inStaging = currentUrl && currentUrl.toLowerCase().includes("staging");

        return (
            <div style={{ width: "80%" }} >
                    {!user || expired ? (
                        <>
                            <Row>
                                <Col xs={12} sm={12} lg={2} xl={1}>
                                    { !inStaging ?
                                        <Button
                                            variant="danger"
                                            onClick={this.login}
                                            style={{ width: "100%", marginTop: "1em" }}
                                            disabled={isUserLoginClicked || readyToLogin}
                                        >
                                            User Login
                                        </Button>
                                    :null}
                                </Col>
                                <Col xs={12} sm={12} lg={2} xl={2}>
                                    <Button
                                        variant="danger"
                                        onClick={this.adminLogin}
                                        style={{ width: "100%", marginTop: "1em" }}
                                        disabled={isAdminLoginClicked || readyToLogin}
                                    >
                                        Brunswick Employee Login
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Button onClick={this.logout} style={{ margin: '0.5em' }}>
                                Logout
                            </Button>
                            <Button onClick={this.changePassword} style={{ margin: '0.5em' }}>
                                Change Password
                            </Button>
                        </>
                    )}
            </div>
        );
    }
}

LoginPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const LoginPage = withRouter(LoginPageComponent);
export default LoginPage;
