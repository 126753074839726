import { configurationConstants } from '../constants';
import { configurationService } from '../services';
import { navActions } from './';

export const configurationActions = {
    getPermissions
};

let permissionsLoading = false;
function getPermissions() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            if (permissionsLoading) {
                return resolve();
            }
            else {
                permissionsLoading = true;
            }

            dispatch(navActions.incrementSpinner());

            configurationService.getPermissions().then(response => {
                const { data } = response;
                resolve(data);
                dispatch(((data) => {
                    return {
                        type: configurationConstants.GET_USER_PERMISSIONS,
                        ...data
                    };
                })(data));
            }, error => {
                reject(error);
            }).finally(() => {
                permissionsLoading = false;
                dispatch(navActions.decrementSpinner());
            });
        });
    };
}