import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const roleService = {
    getAll,
    getMyRoles
};

function getAll(targetUserId) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/role/all?targetUserId=${targetUserId}`, {
            headers
        });
    });
}

function getMyRoles() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/role/my`, {
            headers
        });
    });
}