import React, { Component } from "react";
import PropTypes from "prop-types";
import "../css/FormFileUpload.css";
import FormField from "./FormField";
import { Form, InputGroup, Button } from "react-bootstrap";

export default class FormFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { highlight: false, fileName: "" };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fieldValue && prevProps.fieldValue) {
      // If the fieldValue had a value and now doesn't it was cleared outside the context of this component
      this.handleRemoveClick();
    }
  }

  openFileDialog() {
    if (this.props.disabled) {
      return;
    }
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) {
      return;
    }
    const files = evt.target.files;
    // TODO verify file
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      if (array.length > 0) {
        // Limit to just one file
        var results = this.props.onFilesAdded(array[0]);

        if (typeof results === "undefined" || results) {
          this.setState({ fileName: array[0].name });
        }
      }
    }
  }

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) {
      return;
    }

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) {
      return;
    }

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      if (array.length > 0) {
        // Limit to just one file
        this.props.onFilesAdded(array[0]);
        this.setState({ fileName: array[0].name });
      }
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleRemoveClick = () => {
    this.setState({ fileName: "" });
    if (this.props.onFilesCleared) {
      this.props.onFilesCleared();
    }
  };

  render() {
    const { fieldName, label, validationMessage } = this.props;
    const { fileName } = this.state;

    return (
      <FormField
        fieldName={fieldName}
        label={label}
        validationMessage={validationMessage}
      >
        {!fileName ? this.renderDropZone() : null}
        {fileName ? (
          <InputGroup
            className={`mb-3 ${validationMessage ? "is-invalid" : ""}`}
            text={validationMessage}
          >
            <Form.Control
              as="input"
              value={fileName}
              isInvalid={validationMessage}
              readOnly
            />
            <InputGroup.Append>
              <Button
                variant="outline-secondary"
                onClick={this.handleRemoveClick}
              >
                Remove
              </Button>
            </InputGroup.Append>
          </InputGroup>
        ) : null}
      </FormField>
    );
  }

  renderDropZone() {
    const { validationMessage, hint, accept, disabled } = this.props;

    return (
      <div
        className={`FormFileUpload ${this.state.highlight ? "Highlight" : ""} 
                            form-control ${
                              validationMessage ? "is-invalid" : ""
                            }
                                        ${disabled ? "disabledBox" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          accept={accept}
          multiple
          onChange={this.onFilesAdded}
        />
        <i className="fas fa-cloud-upload-alt"></i>
        <span>Upload File {hint ? `(${hint})` : null}</span>
      </div>
    );
  }
}

FormFileUpload.propTypes = {
  disabled: PropTypes.bool,
  onFilesAdded: PropTypes.func,
  onFilesCleared: PropTypes.func,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  fieldValue: PropTypes.any,
  hint: PropTypes.string,
  accept: PropTypes.string
};
