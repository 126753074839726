import React, { Component } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormFileUpload, FormButtonOk, FormButtonCancel, InfrastructureWrapper } from '../../components';
import { countryService } from '../../services';

class CountryImportPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {},
            file: null,
            fileUploadPercent: 0,
            fileUploadInProgress: false,
            isSaving: false
        };
    }

    render() {
        const { model, fileUploadPercent, fileUploadInProgress, isSaving } = this.state;
        const { fileName } = model;

        const saveButtonEnabled = this.isSaveButtonEnabled();

        return (
            <Row>
                <Col md={12}>
                    <fieldset>
                        <legend>Import Countries</legend>
                        <Row>
                            <Col md={6}>
                                <FormFileUpload
                                    fieldName='fileName'
                                    fieldValue={fileName}
                                    label='Country Import File'
                                    hint='.csv'
                                    accept='.csv'
                                    onFilesAdded={this.handleFileSelected}
                                    onFilesCleared={this.handleFileCleared} />
                                {fileUploadInProgress ? (
                                    <ProgressBar now={fileUploadPercent} />
                                ) : null}
                                <br />
                                {!isSaving ? (
                                    <FormButtonOk
                                        title="Save"
                                        onClick={this.handleSaveClick}
                                        disabled={!saveButtonEnabled}>
                                        <i className="fas fa-check"></i> Import
                                    </FormButtonOk>
                                ) : null}
                                <FormButtonCancel title="Cancel" onClick={this.handleCancelClick}>
                                    <i className="fas fa-times"></i> Cancel
                                </FormButtonCancel>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        );
    }

    isSaveButtonEnabled = () => {
        const { model, file } = this.state;
        const { fileName } = model;
        if (!file || !fileName) {
            return false;
        }

        return true;
    }

    handleFileSelected = (file) => {
        const { name } = file;
        const model = { ...this.state.model };
        model.fileName = name;
        this.setState({ model, file: file });
    }

    handleFileCleared = () => {
        const model = { ...this.state.model };
        model.fileName = '';
        this.setState({ model, file: null });
    }

    handleCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleSaveClick = () => {
        const { file } = this.state;
        const { history, incrementSpinner, decrementSpinner, showError } = this.props;

        if (!this.isSaveButtonEnabled()) {
            return;
        }

        this.setState({ isSaving: true });

        incrementSpinner();
        countryService.uploadFile(file, (progress) => {
            const { total, loaded } = progress;
            const percent = (loaded / total) * 100;
            this.setState({
                fileUploadPercent: percent,
                fileUploadInProgress: true
            });
        }).then(() => {
            history.goBack();
        }, (error) => {
            showError('Error importing countries');
        }).finally(() => {
            decrementSpinner();
            this.setState({ isSaving: false });
        });
    }
}

CountryImportPageComponent.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func
};

const CountryImportPage = InfrastructureWrapper(withRouter(CountryImportPageComponent));
export default CountryImportPage;