import React, { Component } from 'react';
import { userService } from '../services';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { InfrastructureWrapper } from '.';

class CertificationProgressBadge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true, // Add a loading state
            accruedHours: 0,
            annualCompletedItems: 0,
            annualRequiredItems: 0,
            hoursRequired: 0,
            triAnnualItemsAchieved: 0,
            triAnnualItemsRequired: 0,
            certDetails: []
        };
    }

    componentDidMount() {
        this.loadModel();
        this.loadModel2();
    }

    loadModel = () => {
        const { incrementSpinner, decrementSpinner, showError, curriculumId } = this.props;

        incrementSpinner();
        userService
            .getProfileCertification(curriculumId)
            .then(
                (response) => {
                    const model = response.data;
                    this.setState({ ...model });
                },
                () => {
                    showError('Error loading model');
                }
            )
            .finally(() => {
                decrementSpinner();
                this.setState({ loading: false }); // Set loading state to false
            });
    };

    loadModel2 = () => {
        const { incrementSpinner, decrementSpinner, showError, curriculumId } = this.props;

        incrementSpinner();
        userService
            .getProfileCertificationDetails(curriculumId)
            .then(
                (response) => {
                    const certDetails = response.data;
                    this.setState({ certDetails });
                },
                () => {
                    showError('Error loading certification details');
                }
            )
            .finally(() => {
                decrementSpinner();
                this.setState({ loading2: false }); // Set loading state to false
            });
    };



    render() {
        const { accruedHours, annualCompletedItems, annualRequiredItems, hoursRequired,
            triAnnualItemsAchieved, triAnnualItemsRequired, certDetails, loading, loading2 } = this.state;

        const { category, curriculumId, isMaster, name } = this.props;

        if (hoursRequired == 0 && annualRequiredItems == 0 && triAnnualItemsRequired == 0) {
            return null;
        }

        let reqDistanceCount
        let reqInPersonCount
        let reqEskillCount
        let reqElectiveCount
        let totalRequiredItemCount

        let completedDistanceCount
        let completedInPersonCount
        let completedEskillCount
        let completedElectiveCount
        let totalCompletedItemCount
        let certificationProgress

       
        if (category === 'Mercruiser' && loading === false && loading2 === false) {
                //REQUIRED MERCRUISER ITEM COUNT
                reqDistanceCount = parseInt(Object.keys(certDetails?.requirementPoolStatus?.Mercruiser_Initial_Distance));
                reqInPersonCount = parseInt(Object.keys(certDetails?.requirementPoolStatus?.Mercruiser_Initial_InPerson));
                reqElectiveCount = parseInt(Object.keys(certDetails?.requirementPoolStatus?.Mercruiser_Initial_Electives));  
                reqEskillCount = certDetails?.completedInitial.length + certDetails?.staticItemReq.length;
                totalRequiredItemCount = reqDistanceCount + reqInPersonCount + reqEskillCount + reqElectiveCount
                //COMPLETED MERCRUISER ITEM COUNT
                completedDistanceCount = parseInt(Object.values(certDetails?.requirementPoolStatus?.Mercruiser_Initial_Distance));
                completedInPersonCount = parseInt(Object.values(certDetails?.requirementPoolStatus?.Mercruiser_Initial_InPerson));
                completedElectiveCount = parseInt(Object.values(certDetails?.requirementPoolStatus?.Mercruiser_Initial_Electives));
                completedEskillCount = certDetails?.completedInitial.length;
                totalCompletedItemCount = completedDistanceCount + completedInPersonCount + completedEskillCount + completedElectiveCount

        } else if (category === 'Outboard' && loading === false && loading2 === false) {
                //REQUIRED OUTBOARD ITEM COUNT
                reqDistanceCount = parseInt(Object.keys(certDetails?.requirementPoolStatus?.Outboard_Initial_Distance));
                reqInPersonCount = parseInt(Object.keys(certDetails?.requirementPoolStatus?.Outboard_Initial_InPerson));
                reqEskillCount = certDetails?.completedInitial.length + certDetails?.staticItemReq.length;
                totalRequiredItemCount = reqDistanceCount + reqInPersonCount + reqEskillCount 
                //COMPLETED OUTBOARD ITEM COUNT
                completedDistanceCount = parseInt(Object.values(certDetails?.requirementPoolStatus?.Outboard_Initial_Distance));
                completedInPersonCount = parseInt(Object.values(certDetails?.requirementPoolStatus?.Outboard_Initial_InPerson));
                completedEskillCount = certDetails?.completedInitial.length;
                totalCompletedItemCount = completedDistanceCount + completedInPersonCount + completedEskillCount
        }
      

        

        if (loading === false && loading2 === false) {
            certificationProgress = Math.round((totalCompletedItemCount / totalRequiredItemCount) * 100)
           }

        let itemProgress = 100;


        let annualProgress = 100;
        annualProgress = itemProgress

        annualProgress *= 100;


        let isInitial = false;
        if (name.indexOf('Initial') >= 0) {
            isInitial = false;
        } else { isInitial = true; }

        var titleProgress = '';
        if (curriculumId == 'Outboard_Initial_Certification') {
            titleProgress = "Outboard Certification";
        }
        if (curriculumId == 'Mercruiser_Initial_Certificati') {
            titleProgress = "Mercruiser Certification";
        }
        if (curriculumId == 'Marine_Sys_Tech') {
            titleProgress = "Marine Systems Technician";
        }
        if (curriculumId == 'Outboard_Master_Cert') {
            titleProgress = "Outboard Master Certification";
        }
        if (curriculumId == 'Mercruiser_Master_Cert') {
            titleProgress = "Mercruiser Master Certification";
        }




        const getVariant = () => {
            if (certificationProgress <= 50) {
                return "danger";
            }
            if (certificationProgress > 50 && certificationProgress < 100) {
                return "warning";
            }
            else {
                return "success";
            }
        };


        const obSource = isMaster ? "/images/Outboard_Master.png" : "/images/obcertified.png";
        const mcSource = isMaster ? "/images/MerCruiser_Master.png" : "/images/MerCruiser Certified.png";
        return (
            !loading && !loading2 ?
                <div>
                    {isInitial && !isMaster ?
                        <a href={`cert/${curriculumId}`}>
                            <p style={{ fontstyle: 'Industry', fontSize: '1.3em' }} > {titleProgress}  </p>
                        </a>
                        :
                        <p style={{ fontstyle: 'Industry', fontSize: '1.3em' }} > {titleProgress}  </p>}
                    {category == 'Outboard' && !isInitial ? <img alt='Mercury University outboard certification badge' style={{ paddingLeft: "100px", width: "250px", height: "150px", margin: '0.5em' }} src={obSource} /> : null}
                    {category == 'Mercruiser' && !isInitial ? <img alt='Mercury University Mercruiser certification badge' style={{ paddingLeft: "100px", width: "250px", height: "150px", margin: '0.5em' }} src={mcSource} /> : null}

                    {isInitial && titleProgress.indexOf("Master") < 0 ? <ProgressBar variant={getVariant()} now={certificationProgress} label={`${certificationProgress}% complete`} position="absolute" top="50" style={{ margin: '0.5em', height: '2em' }} /> : null}

                </div> :
                <div style={{ border: '5px solid #f3f3f3', borderTop: '5px solid #3498db', borderRadius: '50%', width: '20px', height: '20px', animation: 'spin 1s linear infinite' }}>
                    <style>
                        {`@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`}
                    </style>
                </div>



        )

    }

}

export default InfrastructureWrapper(CertificationProgressBadge);
