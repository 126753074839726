import * as msal from "@azure/msal-browser";
import moment from 'moment';

class BaseAuthProvider {
    constructor(config) {
        // User clicked forgot password
        if (window.location.hash.indexOf('error_description=AADB2C90118') > 1) {
            window.location.href = `${config.passwordAuthority}&redirect_uri=${encodeURIComponent(config.redirectUri)}`;
            return;
        }
        // User clicked cancel, to avoid an error loop we will just reset back to home
        else if(window.location.hash.indexOf('error_description=AADB2C90091') > 1)
        {
            window.location.href = window.location.origin + '/';
            return;
        }
        // User clicked on an expired invite link
        else if(window.location.hash.indexOf('error_description=AADB2C90208') > 1)
        {
            window.location.href = window.location.origin + '/ExpiredInvite';
            return;
        }
        // Some other error happened
        else 
        if (window.location.hash.indexOf('error_description') > 1) {
            //console.error(window.location);
            const hash2Obj = window.location.hash
            .replace('#', '')
            .split("&")
            .map(v => v.split("="))
            .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} );
            window.location.href = window.location.origin + '/error' + `#description=${hash2Obj.error_description}`;
            return;
        }

        const msalConfig = {
            auth: {
                clientId: config.clientId,
                authority: config.authority,
                validateAuthority: config.validateAuthority
                //redirectUri: config.redirectUri,
                //postLogoutRedirectUri: config.redirectUri
            },
            cache: {
                cacheLocation: config.cacheLocation,
            }
        };

        if(!!config.knownAuthorities) {
            msalConfig.auth.knownAuthorities = config.knownAuthorities;
        }

        this.msalApplication = new msal.PublicClientApplication(msalConfig);
        this.isLoaded = false;
        
        // This will force initialization if we are already authenticated
        this.getAccount();

        // Register Callbacks for Redirect flow
        this.msalApplication.handleRedirectPromise().then((tokenResponse) => {
            this.isLoaded = true;
            // If we are signed in check the session and setup an interval to keep checking
            if (this.getAccount() && !this.checkSessionInterval) {
                this.checkSessionInterval = setInterval(this.checkSession, 60000);
                this.checkSession();
            }

            // let accountObj = null;
            // if (tokenResponse !== null) {
            //     accountObj = tokenResponse.account;
            //     const id_token = tokenResponse.idToken;
            //     this.id_token = id_token;
            //     const access_token = tokenResponse.accessToken;
            //     console.log('token response', id_token, access_token);
            // } else {
            //     const currentAccounts = this.msalApplication.getAllAccounts();
            //     if (!currentAccounts || currentAccounts.length === 0) {
            //         // No user signed in
            //         return;
            //     } else if (currentAccounts.length > 1) {
            //         // More than one user signed in, find desired user with getAccountByUsername(username)
            //     } else {
            //         accountObj = currentAccounts[0];
            //     }
            // }

            // const username = accountObj.username;
            // console.log('username', username);

        }).catch((error) => {
            //handleError(error);
            console.log('handleError', error);
        });

        this.loginRequest = {
            scopes: config.scopes,
            forceRefresh: true,
            redirectUri: config.redirectUri
        };

        this.silentTokenRequest = {
            scopes: config.scopes,
            authority: config.authority,
            redirectUri: config.silentRedirectUri,
            forceRefresh: false
        };

        this.silentTokenRefreshRequest = {
            scopes: config.scopes,
            authority: config.authority,
            redirectUri: config.silentRedirectUri,
            forceRefresh: true
        }

        this.tokenRequest = {
            scopes: config.scopes,
            authority: config.authority
        };

        // TODO
        // this.msalApplication.handleRedirectCallback((error, response) => {
        //     if (error && error.errorCode !== 'acquiretoken_progress_error') {
        //         //console.log('handleRedirectCallback', error, response);
        //         //debugger
        //         window.location.replace('/');
        //     }
        // });
    }

    getAccount = () => {
        const currentAccounts = this.msalApplication.getAllAccounts();
        if (!currentAccounts || currentAccounts.length == 0) {
            return null;
        // } else if (currentAccounts.length > 1) {
        //     // TODO handle multiple accounts
        //     return currentAccounts[0];
        } else {
            this.msalApplication.setActiveAccount(currentAccounts[0]);
            return currentAccounts[0];
        }
    }

    checkSession = () => {
        if (this.isExpired()) {
            this.signOut();
        }
        // else {
        //     var exipringSoonTime = moment();
        //     var expireTimeMoment = moment(this.msalApplication.account.idToken.exp * 1000);
        //     console.log(expireTimeMoment.diff(exipringSoonTime, 'minutes') + ' minutes until session end');
        // }
    }

    signOn = () => {
        localStorage.removeItem('readyToLogin');
        this.msalApplication.loginRedirect(this.loginRequest);
    }

    signOut() {
        return this.msalApplication.logout();
    }

    getUser() {
        return this.getAccount();
    }

    getToken = () => {
        return this.msalApplication.acquireTokenSilent(this.silentTokenRequest).catch((error) => {
            // console.log("Acquire token silent failed", error);
            // debugger
            this.msalApplication.logout();
        }).finally(() => { this.gettingToken = false; });
    }

    reAuthenticate = () => {
        return this.msalApplication.acquireTokenSilent(this.silentTokenRefreshRequest).catch((error) => {
            //console.log("Acquire token silent failed", error);
            //debugger
            this.msalApplication.logout();
        });
    }

    isAlmostExpired = () => {
        const account = this.getAccount();
        if (!!account && !!account.idTokenClaims &&
            new Date(account.idTokenClaims.exp * 1000) > new Date()) {

            var exipringSoonTime = moment().add('10', 'm');
            var expireTimeMoment = moment(account.idTokenClaims.exp * 1000);
            //console.log(expireTimeMoment.diff(exipringSoonTime, 'minutes') + ' minutes till renew attempt');
            if (expireTimeMoment < exipringSoonTime) {
                return true
            }
        }

        return false;
    }

    isExpired = () => {
        const account = this.getAccount();
        if (!account || !account.idTokenClaims ||
            new Date(account.idTokenClaims.exp * 1000) < new Date()) {
            return true;
        }

        return false;
    }

    hasLoaded = () => {
        return this.isLoaded;
    }

    getHeaders = (headers, resolveWhenNotAuthenticated) => {
        headers = headers || {};

        if (resolveWhenNotAuthenticated && this.isExpired()) {
            return new Promise((resolve) => {
                resolve(headers);
            });
        }

        if (this.isExpired()) {
            this.signOn();
        }

        return new Promise((resolve) => {
            //var start = moment();
            this.getToken().then((token) => {
                //console.log('Get token time', moment().diff(start));
                headers = { ...headers, 'Authorization': 'Bearer ' + token.idToken };
                resolve(headers);
            });
        });
    }
}

class B2CAuthProvider extends BaseAuthProvider {
    constructor(config) {
        var scopes = config.scopes;
        if (!scopes || scopes.length === 0) {
            console.log('To obtain access tokens you must specify one or more scopes. See https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens');
        }

        super(config);

        this.silentTokenRefreshRequest = {
            scopes: [config.clientId],
            authority: config.authority,
            redirectUri: config.silentRedirectUri,
            forceRefresh: true
        }
    }
}

class ADAuthProvider extends BaseAuthProvider {
    constructor(config) {
        super(config);
    }
}

export { BaseAuthProvider, B2CAuthProvider, ADAuthProvider }