import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ListButton.css';

const ListButtonComponent = ({ history, to, title, onClick, children }) => {
    return (
        <button
            className='list-button'
            title={title}
            aria-label={title}
            onClick={!!onClick ? onClick : () => { history.push(to); }}>
            {children}
        </button>
    );
};

ListButtonComponent.propTypes = {
    history: PropTypes.object,
    to: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    content: PropTypes.any
};

const ListButton = withRouter(ListButtonComponent);
export default ListButton;