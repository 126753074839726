import React, { useEffect, useState } from 'react'
import './managerDashboard.css'
import './widget.css'
import Widget from './Widget'
import WidgetTitle from './WidgetTitle'
import WidgetContent from './WidgetContent'
import { userService } from '../../services/userService'
import { withRouter } from 'react-router-dom';
import { InfrastructureWrapper } from '../../components';


function ManagerDashboardComponent({ incrementSpinner, decrementSpinner, showError }) {
    const [upcomingClassList, setUpcomingClassList] = useState([])
    const [dataReceived, setDataReceived] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            incrementSpinner()
            const res = userService.getTeamClasses()
            const classes = await Promise.resolve(res).catch(e => showError('Failed to load classes'))
            setUpcomingClassList(classes.data.upcomingClasses)
            setDataReceived(prev => !prev);
            decrementSpinner()
        }
        fetchData()
    }, [])

    return <>
        <div className="manager-dashboard-page-container">
        {!dataReceived ? null :
            <Widget height={24} width={48} >
                <WidgetTitle >Upcoming Classes</WidgetTitle>
                <div className="widget-content-container">
                    <WidgetContent data={upcomingClassList} />
                </div>
            </Widget>}
        </div>
       
    </>
}

const ManagerDashboard = InfrastructureWrapper(withRouter(ManagerDashboardComponent));
export default ManagerDashboard;