import React from 'react';
import PropTypes from 'prop-types';
import './FormSwitchCheckbox.css';
import FormField from './FormField';

const FormSwitchCheckbox = ({ fieldName, fieldValue, label, subLabel, onChange, isBool, trueText, falseText, width, wideMode }) => {
    let isTrue,
        oppositeValue;

    if (width == null || width < 5.5) {
        width = 5.5;
    }

    if (!trueText) {
        trueText = 'Yes';
    }

    if (!falseText) {
        falseText = 'No';
    }

    if (isBool) {
        isTrue = fieldValue === true;
        oppositeValue = fieldValue !== true;
    } else {
        isTrue = fieldValue == 1;
        oppositeValue = fieldValue == 1 ? 0 : 1;
    }

    return (
        <FormField
            fieldName={fieldName}
            label={label}
        >
            <div style={{ marginTop: '-1.3em' }}>
                <input
                    className='react-switch-checkbox'
                    id={'react-switch-new-' + fieldName}
                    type='checkbox'
                    checked={isTrue}
                    onChange={() => { if (!!onChange) { onChange(fieldName, oppositeValue); } }}
                />
                <label
                    className='react-switch-label'
                    htmlFor={'react-switch-new-' + fieldName}
                    style={{ width: `${width}rem` }}
                    tabIndex={0}
                    onKeyDown={(event) => {
                        // Space (32) or enter (13)
                        if (event.keyCode === 32 || event.keyCode === 13) {
                            if (!!onChange) {
                                onChange(fieldName, oppositeValue);
                            }
                        }
                    }}
                >
                    <span className='react-switch-button' />
                    <span className='react-switch-text'
                        style={{ width: `${width - 2.5}rem` }}>{isTrue ? trueText : falseText}</span>
                </label>
            </div>
        </FormField >
    );
};

FormSwitchCheckbox.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    onChange: PropTypes.func,
    isBool: PropTypes.bool
};

export default FormSwitchCheckbox;