import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navActions } from '../actions';

class MessageModalComponent extends Component {
    render() {
        const { message, dismissMessage } = this.props;
        if (!message) {
            return null;
        }

        return (
            <Modal show={true} centered onHide={dismissMessage}>
                <Modal.Header>
                    <Modal.Title>{message.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={dismissMessage}>Ok</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

MessageModalComponent.propTypes = {
    message: PropTypes.object,
    dismissMessage: PropTypes.func
};

const mapStateToProps = (state) => ({
    message: state.navReducer.message
});

const mapDispatchToProps = (dispatch) => ({
    dismissMessage: () => dispatch(navActions.dismissMessage())
});

const MessageModal = connect(mapStateToProps, mapDispatchToProps)(MessageModalComponent);
export default MessageModal;