import React from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';


const data02 = [
];


const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifySelf: 'center',
   
}

const centeredStyle = {
    textAlign: 'left', fontSize: '20px',
};


const RADIAN = Math.PI / 180;

const COLORS = ['#fba257', '#4874bf', '#f44242'];

/*const renderCustomizedLabel = ({
    x, y, name
}) => {
    return (
        <text x={x} y={y} fill="black" textAnchor="end" dominantBaseline="central">
            {name}
        </text>
    );
};

*/




function PieChartC({ graduate, certified, notCertified, certName }) {
    const data01 = [
        { name: 'Grad', value: graduate },
        { name: 'Maintain', value: certified },
        { name: 'Incomplete', value: notCertified }
    ];


    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN) * 2.91;
        const y = cy + radius * Math.sin(-midAngle * RADIAN) * 2.91;

        return (

            <text x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="middle">
                {data01[index].value ? `${data01[index].value})` : null}
                </text>
        );
    };
    return (
        <div style={style }>
            <p style={centeredStyle}><strong>{certName}</strong></p>
            
            <PieChart width={400} height={400} margin={{top: -60, bottom: 70} }>
                <Pie
                    data={data01}

                    dataKey="value"
                    cx={200}
                    cy={200}
                    outerRadius={120}
                    fill="#8884d8"
                    label={renderCustomizedLabel }
                        
                > {
                        data01.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                }</Pie>
               
                <Pie
                        data={data02}
                        dataKey="value"
                        cx={200}
                        cy={200}
                        innerRadius={70}
                        outerRadius={90}
                        fill="#82ca9d"
                        
                >{
                        data02.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                }</Pie>
                <Legend wrapperStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} />
                    <Tooltip />
                </PieChart>
        </div>
    );
}

export default PieChartC;
