import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserListPage, UserAddEditPage } from './';
import { configurationActions } from '../../actions';
import { authProvider } from '../../helpers';
import UserAddRolePage from './UserAddRolePage';
import UserEditProfilePage from './UserEditProfilePage';

class UserPageComponent extends Component {
    componentDidMount() {
        const { permissionsLoaded, getUserPermissions } = this.props;

        const user = authProvider.getUser();
        if (user) {
            if (!permissionsLoaded) {
                getUserPermissions();
            }
        }
    }

    render() {
        const { match, hasInternalAdminAccess, hasSupervisorAccess, hasRegionalAdminAccess, hasTechnicianAccess, permissionsLoaded } = this.props;
        const { url } = match;

        if (!permissionsLoaded) {
            return null;
        }

        return (
            <>
                <Route path={`${url}/profile`} render={() => {
                    if (hasTechnicianAccess || hasInternalAdminAccess || hasSupervisorAccess || hasRegionalAdminAccess)
                        return <UserEditProfilePage />;
                    else {
                        return <Redirect to='/' />;
                    }
                }} />

                <Route exact path={`${url}`} render={() => {
                    if (hasInternalAdminAccess || hasSupervisorAccess || hasRegionalAdminAccess)
                        return <UserListPage />;
                    return <Redirect to='/' />;
                }} />

                <Route exact path={`${url}/edit/:id`} render={() => {
                    if (hasInternalAdminAccess || hasSupervisorAccess || hasRegionalAdminAccess)
                        return <UserAddEditPage />;
                    return <Redirect to='/' />;
                }} />

                <Route exact path={`${url}/edit/:id/role/add`} render={() => {
                    if (hasInternalAdminAccess || hasRegionalAdminAccess)
                        return <UserAddRolePage />;
                    return <Redirect to='/' />;
                }} />

                <Route path={`${url}/add`} render={() => {
                    if (hasInternalAdminAccess || hasSupervisorAccess || hasRegionalAdminAccess)
                        return <UserAddEditPage />;
                    return <Redirect to='/' />;
                }} />
            </>
        );
    }
}

UserPageComponent.propTypes = {
    match: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    permissionsLoaded: state.configurationReducer.permissionsLoaded,
    hasInternalAdminAccess: state.configurationReducer.hasInternalAdminAccess,
    hasSupervisorAccess: state.configurationReducer.hasSupervisorAccess,
    hasTechnicianAccess: state.configurationReducer.hasTechnicianAccess,
    hasRegionalAdminAccess: state.configurationReducer.hasRegionalAdminAccess
});

const mapDispatchToProps = (dispatch) => ({
    getUserPermissions: () => dispatch(configurationActions.getPermissions())
});

const UserPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(UserPageComponent));
export default UserPage;