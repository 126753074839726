import React, { Component } from 'react';
import { Row, Col, Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormTextInput, FormButtonCancel, InfrastructureWrapper, FormTextArea } from '../../components';
import { emailTemplateService } from '../../services';

class EmailTemplateAddEditPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {}
        };
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (match.params.id && match.params.id !== prevProps.match.params.id) {
            this.loadModel();
        }
    }

    componentDidMount() {
        this.loadModel();
    }

    loadModel = () => {
        const { match, incrementSpinner, decrementSpinner, showError } = this.props;
        const { params } = match;

        if (params.id) {
            incrementSpinner();
            emailTemplateService.getById(params.id)
                .then(
                    response => {
                        const model = response.data;
                        const { supervisorId } = model;
                        if (!supervisorId) {
                            model.hasNoSupervisor = true;
                        }
                        this.setState({ model });
                    },
                    () => {
                        showError('Error loading model');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }

        if (this.isAddMode()) {
            emailTemplateService.getNew()
                .then(
                    response => {
                        const model = response.data;
                        this.setState({ model });
                    },
                    () => {
                        showError('Error loading model');
                    }
                ).finally(() => {
                    decrementSpinner();
                });
        }
    }

    render() {
        const isAddMode = this.isAddMode();
        const { match } = this.props;
        const { model } = this.state;
        const { id, fromAddress, fromName, htmlContent, plaintextContent, subject, parentId, parentInsertKey } = model;

        const saveButtonEnabled = this.isSaveButtonEnabled();

        return (
            <Row>
                <Col md={12}>
                    <fieldset>
                        <legend>{`${!match.params.id ? 'Add' : 'Edit'} Email Template`}</legend>
                        <Row>
                            <Col md={4}>
                                <FormTextInput
                                    fieldName='id'
                                    fieldValue={id}
                                    label='Id'
                                    onChange={this.handleValueChanged}
                                    readonly={!isAddMode}
                                    required />
                                <FormTextInput
                                    fieldName='subject'
                                    fieldValue={subject}
                                    label='Subject'
                                    onChange={this.handleValueChanged}
                                    required />
                            </Col>
                            <Col md={4}>
                                <FormTextInput
                                    fieldName='fromName'
                                    fieldValue={fromName}
                                    label='From Name'
                                    onChange={this.handleValueChanged} />
                                <FormTextInput
                                    fieldName='fromAddress'
                                    fieldValue={fromAddress}
                                    label='From Address'
                                    onChange={this.handleValueChanged} />
                            </Col>
                            <Col md={4}>
                                <FormTextInput
                                    fieldName='parentId'
                                    fieldValue={parentId}
                                    label='Parent Id'
                                    onChange={this.handleValueChanged} />
                                <FormTextInput
                                    fieldName='parentInsertKey'
                                    fieldValue={parentInsertKey}
                                    label='Parent Insert Key'
                                    onChange={this.handleValueChanged} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormTextArea
                                    fieldName='plaintextContent'
                                    fieldValue={plaintextContent}
                                    label='Plaintext'
                                    onChange={this.handleValueChanged} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormTextArea
                                    fieldName='htmlContent'
                                    fieldValue={htmlContent}
                                    label='Html'
                                    onChange={this.handleValueChanged}
                                    rows={15} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Dropdown as={ButtonGroup} style={{ marginRight: '1em' }}>
                                    <Button
                                        variant="primary"
                                        title="Save"
                                        onClick={this.handleSaveClick}
                                        disabled={!saveButtonEnabled}>
                                        <i className="fas fa-check"></i> Save
                                    </Button>

                                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.handleSaveAndSendClick}>Save and Send Test Email</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <FormButtonCancel title="Cancel" onClick={this.handleCancelClick}>
                                    <i className="fas fa-times"></i> Cancel
                                </FormButtonCancel>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        );
    }

    isSaveButtonEnabled = () => {
        const { model } = this.state;
        const { id, subject } = model;

        if (!id || !subject) {
            return false;
        }

        return true;
    }

    handleValueChanged = (fieldName, value) => {
        const model = { ...this.state.model };
        model[fieldName] = value;
        this.setState({ model });
    }

    handleCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleSaveClick = () => {
        const { history, showError } = this.props;

        if (!this.isSaveButtonEnabled()) {
            return;
        }

        this.handleSaveBase()
            .then(() => {
                history.goBack();
            }, (error) => {
                showError('Error saving template');
            });
    }

    handleSaveAndSendClick = () => {
        const { model } = this.state;
        const { incrementSpinner, decrementSpinner, showMessage, showError } = this.props;

        if (!this.isSaveButtonEnabled()) {
            return;
        }

        this.handleSaveBase()
            .then(() => {
                incrementSpinner();
                emailTemplateService.sendTestEmail(model.id)
                    .then(() => {
                        showMessage('Test Email Sent', 'Test email sent to the email address in your profile');
                    }, (error) => {
                        showError('Error sending test email');
                    })
                    .finally(() => {
                        decrementSpinner();
                    });
            }, (error) => {
                showError('Error saving template');
            });
    }

    handleSaveBase = () => {
        const { model } = this.state;
        const { match, incrementSpinner, decrementSpinner } = this.props;

        return new Promise((resolve, reject) => {
            incrementSpinner();
            if (!match.params.id) {
                emailTemplateService.add(model)
                    .then(() => {
                        resolve();
                    }, (error) => {
                        reject(error);
                    })
                    .finally(() => {
                        decrementSpinner();
                    });
            } else {
                emailTemplateService.update(model)
                    .then(() => {
                        resolve();
                    }, (error) => {
                        reject(error);
                    })
                    .finally(() => {
                        decrementSpinner();
                    });
            }
        });
    }

    isAddMode = () => {
        const { match } = this.props;
        const { url } = match;

        return url === '/emailtemplates/add';
    }
}

EmailTemplateAddEditPageComponent.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
    incrementSpinner: PropTypes.func,
    decrementSpinner: PropTypes.func,
    showError: PropTypes.func,
    hasInternalAdminAccess: PropTypes.bool
};

const EmailTemplateAddEditPage = InfrastructureWrapper(withRouter(EmailTemplateAddEditPageComponent));
export default EmailTemplateAddEditPage;