import axios from 'axios';
import { apiHostUrl, getHeaders } from '../helpers';

export const accountCodeService = {
    getAll,
    getById,
    getList,
    uploadFile
};

function getAll() {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/accountCode/all`, {
            headers
        });
    });
}

function getById(id) {
    return getHeaders().then((headers) => {
        return axios.get(`${apiHostUrl}/api/v1/accountCode/${id}`, {
            headers
        });
    });
}

function getList(pageIndex, pageSize, query) {
    if (!pageSize) {
        pageSize = 10;
    }

    let apiUrl = `${apiHostUrl}/api/v1/accountCode/list?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (query && query.length > 0) {
        apiUrl += `&query=${query}`;
    }

    return getHeaders().then((headers) => {
        return axios.get(apiUrl, {
            headers
        });
    });
}

function uploadFile(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    return getHeaders({ 'Content-Type': 'multipart/form-data' }).then((headers) => {
        return axios.post(`${apiHostUrl}/api/v1/accountCode/import`, formData, {
            onUploadProgress,
            headers
        });
    });
}