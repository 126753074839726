import React, { Component } from 'react';
import { Form, Button, InputGroup, Modal, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormField from './FormField';
import MiniSearchList from './MiniSearchList';
import InfrastructureWrapper from './InfrastructureWrapper';

class FormSearchInputMultiComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayValue: '',
            showSearch: false
        }
    }

    componentDidMount() {
        this.loadSelectedDisplay();
    }

    componentDidUpdate(prevProps) {
        const { fieldValue, valueLookupFunc } = this.props;

        if (fieldValue !== prevProps.fieldValue || valueLookupFunc !== prevProps.valueLookupFunc) {
            this.loadSelectedDisplay();
        }

        if (!fieldValue && fieldValue !== prevProps.fieldValue && this.state.displayValue !== '') {
            this.setState({ displayValue: '' });
        }
    }

    loadSelectedDisplay = () => {
        const { incrementSpinner, decrementSpinner, showError, label,
            fieldValue, valueLookupFunc, valueLookupIdName, valueLookupDisplayName, valueLookupDisplayFunc } = this.props;
        if (!!fieldValue && !!valueLookupFunc) {
            incrementSpinner();
            valueLookupFunc(fieldValue)
                .then(response => {
                    const { data } = response;
                    const displayValue = !valueLookupDisplayFunc ? `${data[valueLookupDisplayName]} (${data[valueLookupIdName]})` : valueLookupDisplayFunc(data);
                    if (displayValue !== this.state.displayValue) {
                        this.setState({ displayValue });
                    }
                }, error => {
                    showError(`Error loading ${label}`)
                })
                .finally(() => {
                    decrementSpinner();
                });
        }
    }

    render() {
        const { displayValue } = this.state;
        const { fieldName, fieldValue, label, subLabel, required, validationMessage, readonly, showClear, onChange } = this.props;

        return (
            <>
                {this.renderModal()}
                <FormField
                    fieldName={fieldName}
                    label={label}
                    subLabel={subLabel}
                    validationMessage={validationMessage}
                >
                    <InputGroup className="mb-2 mr-sm-2">
                        <Form.Control
                            placeholder={label}
                            as='input'
                            value={displayValue}
                            style={{ background: '#FFF' }}
                            className='input-group-append'
                            isInvalid={(required && (fieldValue === null || fieldValue === undefined || fieldValue === '')) || validationMessage}
                            readOnly
                        />
                        {showClear ? (
                            <InputGroup.Append>
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        if (onChange) {
                                            onChange(fieldName, '');
                                        }
                                    }}
                                >
                                    <i className='fas fa-times'></i>
                                </Button>
                            </InputGroup.Append>
                        ) : null}
                        {!readonly ? (<InputGroup.Append>
                            <Button
                                variant='secondary'
                                onClick={this.handleSearchClick}
                            >
                                <i className='fas fa-search'></i>
                            </Button>
                        </InputGroup.Append>) : null}
                    </InputGroup>
                </FormField >
            </>
        );
    }

    handleSearchClick = () => {
        this.setState({ showSearch: true });
    }

    handleCloseSearch = () => {
        this.setState({ showSearch: false });
    }

    renderModal = () => {
        const { showSearch } = this.state;
        const { fieldName, onChange, label, itemListFunc, onSelectItem, onRemove,
            valueLookupIdName, valueLookupDisplayName, valueLookupDisplayFunc, valueLookupHideId, columns,
            isItemSelectableFunc } = this.props;
        return (
            <Modal show={showSearch} centered onHide={this.handleCloseSearch} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{label} Search</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div style={{ margin: '1em', fontSize: '0.75em' }}>
                        <MiniSearchList
                            itemListFunc={itemListFunc}
                            label={label}
                            valueLookupIdName={valueLookupIdName}
                            valueLookupHideId={valueLookupHideId}
                            valueLookupDisplayName={valueLookupDisplayName}
                            valueLookupDisplayFunc={valueLookupDisplayFunc}
                            isItemSelectableFunc={isItemSelectableFunc}
                            onSelectItem={(item) => {
                                if (onSelectItem) {
                                    onSelectItem(item);
                                }
                            }}
                            onSelectValue={(fieldValue) => {
                                if (onChange) {
                                    onChange(fieldName, fieldValue);
                                }
                            }}
                            onRemoveValue={(fieldValue) => {
                                if(onRemove) {
                                    onRemove(fieldValue);
                                }
                            }}
                            columns={columns}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseSearch}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

FormSearchInputMultiComponent.propTypes = {
    fieldName: PropTypes.string,
    fieldValue: PropTypes.any,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    validationMessage: PropTypes.string,
    autocomplete: PropTypes.string
};

const FormSearchInputMulti = InfrastructureWrapper(FormSearchInputMultiComponent);
export default FormSearchInputMulti;