import React from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { FormTextInput } from "../../components";

export default function ResetEmailModal({ show, active, emailAddress, emailValidationMessage, handleValueChanged, handleSaveClick, handleCancelClick }) {
    return (
        <Modal show={show} centered>
            <Modal.Header>
                <Modal.Title>Reset Email Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Alert variant='warning'>
                            Warning: This will change the the user's email address and disassociate this learning account from the B2C/SSO user account.
                            If the user is not active you can clear out this field and save without an email.
                        </Alert>
                        <FormTextInput
                            fieldName='emailAddress'
                            fieldValue={emailAddress}
                            label='Email'
                            onChange={handleValueChanged}
                            validationMessage={emailValidationMessage}
                            required={active}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={handleSaveClick} disabled={!!emailValidationMessage}>Save</Button>
                <Button variant='secondary' onClick={handleCancelClick}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}